{
  "owaspTop10-2021": {
    "a1": {
      "title": "Broken Access Control",
      "description": "Restrictions on what authenticated users are allowed to do are often not properly enforced. Attackers can exploit these flaws to access unauthorized functionality and/or data, such as access other users' accounts, view sensitive files, modify other users' data, change access rights, etc."
    },
    "a2": {
      "title": "Cryptographic Failures",
      "description": "Many web applications and APIs do not properly protect sensitive data, such as financial, healthcare, and PII. Attackers may steal or modify such weakly protected data to conduct credit card fraud, identity theft, or other crimes. Sensitive data may be compromised without extra protection, such as encryption at rest or in transit, and requires special precautions when exchanged with the browser."
    },
    "a3": {
      "title": "Injection",
      "description": "Injection flaws, such as SQL, NoSQL, OS, and LDAP injection, occur when untrusted data is sent to an interpreter as part of a command or query. The attacker's hostile data can trick the interpreter into executing unintended commands or accessing data without proper authorization."
    },
    "a4": {
      "title": "Insecure Design",
      "description": "Insecure design is a broad category representing different weaknesses, expressed as “missing or ineffective control design.” An insecure design cannot be fixed by a perfect implementation as by definition, needed security controls were never created to defend against specific attacks."
    },
    "a5": {
      "title": "Security Misconfiguration",
      "description": "Security misconfiguration is the most commonly seen issue. This is commonly a result of insecure default configurations, incomplete or ad hoc configurations, open cloud storage, misconfigured HTTP headers, and verbose error messages containing sensitive information. Not only must all operating systems, frameworks, libraries, and applications be securely configured, but they must be patched and upgraded in a timely fashion."
    },
    "a6": {
      "title": "Vulnerable and Outdated Components",
      "description": "Components, such as libraries, frameworks, and other software modules, run with the same privileges as the application. If a vulnerable component is exploited, such an attack can facilitate serious data loss or server takeover. Applications and APIs using components with known vulnerabilities may undermine application defenses and enable various attacks and impacts."
    },
    "a7": {
      "title": "Identification and Authentication Failures",
      "description": "Application functions related to authentication and session management are often implemented incorrectly, allowing attackers to compromise passwords, keys, or session tokens, or to exploit other implementation flaws to assume other users' identities temporarily or permanently."
    },
    "a8": {
      "title": "Software and Data Integrity Failures",
      "description": "Software and data integrity failures relate to code and infrastructure that does not protect against integrity violations. An example of this is where an application relies upon plugins, libraries, or modules from untrusted sources, repositories, and content delivery networks (CDNs)."
    },
    "a9": {
      "title": "Security Logging and Monitoring Failures",
      "description": "Insufficient logging and monitoring, coupled with missing or ineffective integration with incident response, allows attackers to further attack systems, maintain persistence, pivot to more systems, and tamper, extract, or destroy data. Most breach studies show time to detect a breach is over 200 days, typically detected by external parties rather than internal processes or monitoring."
    },
    "a10": {
      "title": "Server-Side Request Forgery (SSRF)",
      "description": "SSRF flaws occur whenever a web application is fetching a remote resource without validating the user-supplied URL. It allows an attacker to coerce the application to send a crafted request to an unexpected destination, even when protected by a firewall, VPN, or another type of network access control list (ACL)."
    }
  },
  "owaspTop10": {
    "a1": {
      "title": "Injection",
      "description": "Injection flaws, such as SQL, NoSQL, OS, and LDAP injection, occur when untrusted data is sent to an interpreter as part of a command or query. The attacker's hostile data can trick the interpreter into executing unintended commands or accessing data without proper authorization."
    },
    "a2": {
      "title": "Broken Authentication",
      "description": "Application functions related to authentication and session management are often implemented incorrectly, allowing attackers to compromise passwords, keys, or session tokens, or to exploit other implementation flaws to assume other users' identities temporarily or permanently."
    },
    "a3": {
      "title": "Sensitive Data Exposure",
      "description": "Many web applications and APIs do not properly protect sensitive data, such as financial, healthcare, and PII. Attackers may steal or modify such weakly protected data to conduct credit card fraud, identity theft, or other crimes. Sensitive data may be compromised without extra protection, such as encryption at rest or in transit, and requires special precautions when exchanged with the browser."
    },
    "a4": {
      "title": "XML External Entities (XXE)",
      "description": "Many older or poorly configured XML processors evaluate external entity references within XML documents. External entities can be used to disclose internal files using the file URI handler, internal file shares, internal port scanning, remote code execution, and denial of service attacks."
    },
    "a5": {
      "title": "Broken Access Control",
      "description": "Restrictions on what authenticated users are allowed to do are often not properly enforced. Attackers can exploit these flaws to access unauthorized functionality and/or data, such as access other users' accounts, view sensitive files, modify other users' data, change access rights, etc."
    },
    "a6": {
      "title": "Security Misconfiguration",
      "description": "Security misconfiguration is the most commonly seen issue. This is commonly a result of insecure default configurations, incomplete or ad hoc configurations, open cloud storage, misconfigured HTTP headers, and verbose error messages containing sensitive information. Not only must all operating systems, frameworks, libraries, and applications be securely configured, but they must be patched and upgraded in a timely fashion."
    },
    "a7": {
      "title": "Cross-Site Scripting (XSS)",
      "description": "XSS flaws occur whenever an application includes untrusted data in a new web page without proper validation or escaping, or updates an existing web page with user-supplied data using a browser API that can create HTML or JavaScript. XSS allows attackers to execute scripts in the victim's browser which can hijack user sessions, deface web sites, or redirect the user to malicious sites."
    },
    "a8": {
      "title": "Insecure Deserialization",
      "description": "Insecure deserialization often leads to remote code execution. Even if deserialization flaws do not result in remote code execution, they can be used to perform attacks, including replay attacks, injection attacks, and privilege escalation attacks."
    },
    "a9": {
      "title": "Using Components with Known Vulnerabilities",
      "description": "Components, such as libraries, frameworks, and other software modules, run with the same privileges as the application. If a vulnerable component is exploited, such an attack can facilitate serious data loss or server takeover. Applications and APIs using components with known vulnerabilities may undermine application defenses and enable various attacks and impacts."
    },
    "a10": {
      "title": "Insufficient Logging & Monitoring",
      "description": "Insufficient logging and monitoring, coupled with missing or ineffective integration with incident response, allows attackers to further attack systems, maintain persistence, pivot to more systems, and tamper, extract, or destroy data. Most breach studies show time to detect a breach is over 200 days, typically detected by external parties rather than internal processes or monitoring."
    }
  },
  "cwe": {
    "5": {
      "title": "J2EE Misconfiguration: Data Transmission Without Encryption",
      "description": "Information sent over a network can be compromised while in transit. An attacker may be able to read or modify the contents if the data are sent in plaintext or are weakly encrypted."
    },
    "6": {
      "title": "J2EE Misconfiguration: Insufficient Session-ID Length",
      "description": "The J2EE application is configured to use an insufficient session ID length."
    },
    "7": {
      "title": "J2EE Misconfiguration: Missing Custom Error Page",
      "description": "The default error page of a web application should not display sensitive information about the product."
    },
    "8": {
      "title": "J2EE Misconfiguration: Entity Bean Declared Remote",
      "description": "When an application exposes a remote interface for an entity bean, it might also expose methods that get or set the bean's data. These methods could be leveraged to read sensitive information, or to change data in ways that violate the application's expectations, potentially leading to other vulnerabilities."
    },
    "9": {
      "title": "J2EE Misconfiguration: Weak Access Permissions for EJB Methods",
      "description": "If elevated access rights are assigned to EJB methods, then an attacker can take advantage of the permissions to exploit the product."
    },
    "11": {
      "title": "ASP.NET Misconfiguration: Creating Debug Binary",
      "description": "Debugging messages help attackers learn about the system and plan a form of attack."
    },
    "12": {
      "title": "ASP.NET Misconfiguration: Missing Custom Error Page",
      "description": "An ASP .NET application must enable custom error pages in order to prevent attackers from mining information from the framework's built-in responses."
    },
    "13": {
      "title": "ASP.NET Misconfiguration: Password in Configuration File",
      "description": "Storing a plaintext password in a configuration file allows anyone who can read the file access to the password-protected resource making them an easy target for attackers."
    },
    "14": {
      "title": "Compiler Removal of Code to Clear Buffers",
      "description": "Sensitive memory is cleared according to the source code, but compiler optimizations leave the memory untouched when it is not read from again, aka \"dead store removal.\""
    },
    "15": {
      "title": "External Control of System or Configuration Setting",
      "description": "One or more system settings or configuration elements can be externally controlled by a user."
    },
    "20": {
      "title": "Improper Input Validation",
      "description": "The product receives input or data, but it does\n        not validate or incorrectly validates that the input has the\n        properties that are required to process the data safely and\n        correctly."
    },
    "22": {
      "title": "Improper Limitation of a Pathname to a Restricted Directory ('Path Traversal')",
      "description": "The product uses external input to construct a pathname that is intended to identify a file or directory that is located underneath a restricted parent directory, but the product does not properly neutralize special elements within the pathname that can cause the pathname to resolve to a location that is outside of the restricted directory."
    },
    "23": {
      "title": "Relative Path Traversal",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize sequences such as \"..\" that can resolve to a location that is outside of that directory."
    },
    "24": {
      "title": "Path Traversal: '../filedir'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize \"../\" sequences that can resolve to a location that is outside of that directory."
    },
    "25": {
      "title": "Path Traversal: '/../filedir'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize \"/../\" sequences that can resolve to a location that is outside of that directory."
    },
    "26": {
      "title": "Path Traversal: '/dir/../filename'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize \"/dir/../filename\" sequences that can resolve to a location that is outside of that directory."
    },
    "27": {
      "title": "Path Traversal: 'dir/../../filename'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize multiple internal \"../\" sequences that can resolve to a location that is outside of that directory."
    },
    "28": {
      "title": "Path Traversal: '..\\filedir'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize \"..\\\" sequences that can resolve to a location that is outside of that directory."
    },
    "29": {
      "title": "Path Traversal: '\\..\\filename'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize '\\..\\filename' (leading backslash dot dot) sequences that can resolve to a location that is outside of that directory."
    },
    "30": {
      "title": "Path Traversal: '\\dir\\..\\filename'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize '\\dir\\..\\filename' (leading backslash dot dot) sequences that can resolve to a location that is outside of that directory."
    },
    "31": {
      "title": "Path Traversal: 'dir\\..\\..\\filename'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize 'dir\\..\\..\\filename' (multiple internal backslash dot dot) sequences that can resolve to a location that is outside of that directory."
    },
    "32": {
      "title": "Path Traversal: '...' (Triple Dot)",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize '...' (triple dot) sequences that can resolve to a location that is outside of that directory."
    },
    "33": {
      "title": "Path Traversal: '....' (Multiple Dot)",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize '....' (multiple dot) sequences that can resolve to a location that is outside of that directory."
    },
    "34": {
      "title": "Path Traversal: '....//'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize '....//' (doubled dot dot slash) sequences that can resolve to a location that is outside of that directory."
    },
    "35": {
      "title": "Path Traversal: '.../...//'",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize '.../...//' (doubled triple dot slash) sequences that can resolve to a location that is outside of that directory."
    },
    "36": {
      "title": "Absolute Path Traversal",
      "description": "The product uses external input to construct a pathname that should be within a restricted directory, but it does not properly neutralize absolute path sequences such as \"/abs/path\" that can resolve to a location that is outside of that directory."
    },
    "37": {
      "title": "Path Traversal: '/absolute/pathname/here'",
      "description": "The product accepts input in the form of a slash absolute path ('/absolute/pathname/here') without appropriate validation, which can allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "38": {
      "title": "Path Traversal: '\\absolute\\pathname\\here'",
      "description": "The product accepts input in the form of a backslash absolute path ('\\absolute\\pathname\\here') without appropriate validation, which can allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "39": {
      "title": "Path Traversal: 'C:dirname'",
      "description": "The product accepts input that contains a drive letter or Windows volume letter ('C:dirname') that potentially redirects access to an unintended location or arbitrary file."
    },
    "40": {
      "title": "Path Traversal: '\\\\UNC\\share\\name\\' (Windows UNC Share)",
      "description": "The product accepts input that identifies a Windows UNC share ('\\\\UNC\\share\\name') that potentially redirects access to an unintended location or arbitrary file."
    },
    "41": {
      "title": "Improper Resolution of Path Equivalence",
      "description": "The product is vulnerable to file system contents disclosure through path equivalence. Path equivalence involves the use of special characters in file and directory names. The associated manipulations are intended to generate multiple names for the same object."
    },
    "42": {
      "title": "Path Equivalence: 'filename.' (Trailing Dot)",
      "description": "The product accepts path input in the form of trailing dot ('filedir.') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "43": {
      "title": "Path Equivalence: 'filename....' (Multiple Trailing Dot)",
      "description": "The product accepts path input in the form of multiple trailing dot ('filedir....') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "44": {
      "title": "Path Equivalence: 'file.name' (Internal Dot)",
      "description": "The product accepts path input in the form of internal dot ('file.ordir') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "45": {
      "title": "Path Equivalence: 'file...name' (Multiple Internal Dot)",
      "description": "The product accepts path input in the form of multiple internal dot ('file...dir') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "46": {
      "title": "Path Equivalence: 'filename ' (Trailing Space)",
      "description": "The product accepts path input in the form of trailing space ('filedir ') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "47": {
      "title": "Path Equivalence: ' filename' (Leading Space)",
      "description": "The product accepts path input in the form of leading space (' filedir') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "48": {
      "title": "Path Equivalence: 'file name' (Internal Whitespace)",
      "description": "The product accepts path input in the form of internal space ('file(SPACE)name') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "49": {
      "title": "Path Equivalence: 'filename/' (Trailing Slash)",
      "description": "The product accepts path input in the form of trailing slash ('filedir/') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "50": {
      "title": "Path Equivalence: '//multiple/leading/slash'",
      "description": "The product accepts path input in the form of multiple leading slash ('//multiple/leading/slash') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "51": {
      "title": "Path Equivalence: '/multiple//internal/slash'",
      "description": "The product accepts path input in the form of multiple internal slash ('/multiple//internal/slash/') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "52": {
      "title": "Path Equivalence: '/multiple/trailing/slash//'",
      "description": "The product accepts path input in the form of multiple trailing slash ('/multiple/trailing/slash//') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "53": {
      "title": "Path Equivalence: '\\multiple\\\\internal\\backslash'",
      "description": "The product accepts path input in the form of multiple internal backslash ('\\multiple\\trailing\\\\slash') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "54": {
      "title": "Path Equivalence: 'filedir\\' (Trailing Backslash)",
      "description": "The product accepts path input in the form of trailing backslash ('filedir\\') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "55": {
      "title": "Path Equivalence: '/./' (Single Dot Directory)",
      "description": "The product accepts path input in the form of single dot directory exploit ('/./') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "56": {
      "title": "Path Equivalence: 'filedir*' (Wildcard)",
      "description": "The product accepts path input in the form of asterisk wildcard ('filedir*') without appropriate validation, which can lead to ambiguous path resolution and allow an attacker to traverse the file system to unintended locations or access arbitrary files."
    },
    "57": {
      "title": "Path Equivalence: 'fakedir/../realdir/filename'",
      "description": "The product contains protection mechanisms to restrict access to 'realdir/filename', but it constructs pathnames using external input in the form of 'fakedir/../realdir/filename' that are not handled by those mechanisms. This allows attackers to perform unauthorized actions against the targeted file."
    },
    "58": {
      "title": "Path Equivalence: Windows 8.3 Filename",
      "description": "The product contains a protection mechanism that restricts access to a long filename on a Windows operating system, but it does not properly restrict access to the equivalent short \"8.3\" filename."
    },
    "59": {
      "title": "Improper Link Resolution Before File Access ('Link Following')",
      "description": "The product attempts to access a file based on the filename, but it does not properly prevent that filename from identifying a link or shortcut that resolves to an unintended resource."
    },
    "61": {
      "title": "UNIX Symbolic Link (Symlink) Following",
      "description": "The product, when opening a file or directory, does not sufficiently account for when the file is a symbolic link that resolves to a target outside of the intended control sphere. This could allow an attacker to cause the product to operate on unauthorized files."
    },
    "62": {
      "title": "UNIX Hard Link",
      "description": "The product, when opening a file or directory, does not sufficiently account for when the name is associated with a hard link to a target that is outside of the intended control sphere. This could allow an attacker to cause the product to operate on unauthorized files."
    },
    "64": {
      "title": "Windows Shortcut Following (.LNK)",
      "description": "The product, when opening a file or directory, does not sufficiently handle when the file is a Windows shortcut (.LNK) whose target is outside of the intended control sphere. This could allow an attacker to cause the product to operate on unauthorized files."
    },
    "65": {
      "title": "Windows Hard Link",
      "description": "The product, when opening a file or directory, does not sufficiently handle when the name is associated with a hard link to a target that is outside of the intended control sphere. This could allow an attacker to cause the product to operate on unauthorized files."
    },
    "66": {
      "title": "Improper Handling of File Names that Identify Virtual Resources",
      "description": "The product does not handle or incorrectly handles a file name that identifies a \"virtual\" resource that is not directly specified within the directory that is associated with the file name, causing the product to perform file-based operations on a resource that is not a file."
    },
    "67": {
      "title": "Improper Handling of Windows Device Names",
      "description": "The product constructs pathnames from user input, but it does not handle or incorrectly handles a pathname containing a Windows device name such as AUX or CON. This typically leads to denial of service or an information exposure when the application attempts to process the pathname as a regular file."
    },
    "69": {
      "title": "Improper Handling of Windows ::DATA Alternate Data Stream",
      "description": "The product does not properly prevent access to, or detect usage of, alternate data streams (ADS)."
    },
    "71": {
      "title": "DEPRECATED: Apple '.DS_Store'",
      "description": "This entry has been deprecated as it represents a specific observed example of a UNIX Hard Link weakness type rather than its own individual weakness type. Please refer to CWE-62."
    },
    "72": {
      "title": "Improper Handling of Apple HFS+ Alternate Data Stream Path",
      "description": "The product does not properly handle special paths that may identify the data or resource fork of a file on the HFS+ file system."
    },
    "73": {
      "title": "External Control of File Name or Path",
      "description": "The product allows user input to control or influence paths or file names that are used in filesystem operations."
    },
    "74": {
      "title": "Improper Neutralization of Special Elements in Output Used by a Downstream Component ('Injection')",
      "description": "The product constructs all or part of a command, data structure, or record using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify how it is parsed or interpreted when it is sent to a downstream component."
    },
    "75": {
      "title": "Failure to Sanitize Special Elements into a Different Plane (Special Element Injection)",
      "description": "The product does not adequately filter user-controlled input for special elements with control implications."
    },
    "76": {
      "title": "Improper Neutralization of Equivalent Special Elements",
      "description": "The product correctly neutralizes certain special elements, but it improperly neutralizes equivalent special elements."
    },
    "77": {
      "title": "Improper Neutralization of Special Elements used in a Command ('Command Injection')",
      "description": "The product constructs all or part of a command using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify the intended command when it is sent to a downstream component."
    },
    "78": {
      "title": "Improper Neutralization of Special Elements used in an OS Command ('OS Command Injection')",
      "description": "The product constructs all or part of an OS command using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify the intended OS command when it is sent to a downstream component."
    },
    "79": {
      "title": "Improper Neutralization of Input During Web Page Generation ('Cross-site Scripting')",
      "description": "The product does not neutralize or incorrectly neutralizes user-controllable input before it is placed in output that is used as a web page that is served to other users."
    },
    "80": {
      "title": "Improper Neutralization of Script-Related HTML Tags in a Web Page (Basic XSS)",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special characters such as \"<\", \">\", and \"&\" that could be interpreted as web-scripting elements when they are sent to a downstream component that processes web pages."
    },
    "81": {
      "title": "Improper Neutralization of Script in an Error Message Web Page",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special characters that could be interpreted as web-scripting elements when they are sent to an error page."
    },
    "82": {
      "title": "Improper Neutralization of Script in Attributes of IMG Tags in a Web Page",
      "description": "The web application does not neutralize or incorrectly neutralizes scripting elements within attributes of HTML IMG tags, such as the src attribute."
    },
    "83": {
      "title": "Improper Neutralization of Script in Attributes in a Web Page",
      "description": "The product does not neutralize or incorrectly neutralizes \"javascript:\" or other URIs from dangerous attributes within tags, such as onmouseover, onload, onerror, or style."
    },
    "84": {
      "title": "Improper Neutralization of Encoded URI Schemes in a Web Page",
      "description": "The web application improperly neutralizes user-controlled input for executable script disguised with URI encodings."
    },
    "85": {
      "title": "Doubled Character XSS Manipulations",
      "description": "The web application does not filter user-controlled input for executable script disguised using doubling of the involved characters."
    },
    "86": {
      "title": "Improper Neutralization of Invalid Characters in Identifiers in Web Pages",
      "description": "The product does not neutralize or incorrectly neutralizes invalid characters or byte sequences in the middle of tag names, URI schemes, and other identifiers."
    },
    "87": {
      "title": "Improper Neutralization of Alternate XSS Syntax",
      "description": "The product does not neutralize or incorrectly neutralizes user-controlled input for alternate script syntax."
    },
    "88": {
      "title": "Improper Neutralization of Argument Delimiters in a Command ('Argument Injection')",
      "description": "The product constructs a string for a command to executed by a separate component\nin another control sphere, but it does not properly delimit the\nintended arguments, options, or switches within that command string."
    },
    "89": {
      "title": "Improper Neutralization of Special Elements used in an SQL Command ('SQL Injection')",
      "description": "The product constructs all or part of an SQL command using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify the intended SQL command when it is sent to a downstream component."
    },
    "90": {
      "title": "Improper Neutralization of Special Elements used in an LDAP Query ('LDAP Injection')",
      "description": "The product constructs all or part of an LDAP query using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify the intended LDAP query when it is sent to a downstream component."
    },
    "91": {
      "title": "XML Injection (aka Blind XPath Injection)",
      "description": "The product does not properly neutralize special elements that are used in XML, allowing attackers to modify the syntax, content, or commands of the XML before it is processed by an end system."
    },
    "92": {
      "title": "DEPRECATED: Improper Sanitization of Custom Special Characters",
      "description": "This entry has been deprecated. It originally came from PLOVER, which sometimes defined \"other\" and \"miscellaneous\" categories in order to satisfy exhaustiveness requirements for taxonomies. Within the context of CWE, the use of a more abstract entry is preferred in mapping situations. CWE-75 is a more appropriate mapping."
    },
    "93": {
      "title": "Improper Neutralization of CRLF Sequences ('CRLF Injection')",
      "description": "The product uses CRLF (carriage return line feeds) as a special element, e.g. to separate lines or records, but it does not neutralize or incorrectly neutralizes CRLF sequences from inputs."
    },
    "94": {
      "title": "Improper Control of Generation of Code ('Code Injection')",
      "description": "The product constructs all or part of a code segment using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify the syntax or behavior of the intended code segment."
    },
    "95": {
      "title": "Improper Neutralization of Directives in Dynamically Evaluated Code ('Eval Injection')",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes code syntax before using the input in a dynamic evaluation call (e.g. \"eval\")."
    },
    "96": {
      "title": "Improper Neutralization of Directives in Statically Saved Code ('Static Code Injection')",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes code syntax before inserting the input into an executable resource, such as a library, configuration file, or template."
    },
    "97": {
      "title": "Improper Neutralization of Server-Side Includes (SSI) Within a Web Page",
      "description": "The product generates a web page, but does not neutralize or incorrectly neutralizes user-controllable input that could be interpreted as a server-side include (SSI) directive."
    },
    "98": {
      "title": "Improper Control of Filename for Include/Require Statement in PHP Program ('PHP Remote File Inclusion')",
      "description": "The PHP application receives input from an upstream component, but it does not restrict or incorrectly restricts the input before its usage in \"require,\" \"include,\" or similar functions."
    },
    "99": {
      "title": "Improper Control of Resource Identifiers ('Resource Injection')",
      "description": "The product receives input from an upstream component, but it does not restrict or incorrectly restricts the input before it is used as an identifier for a resource that may be outside the intended sphere of control."
    },
    "102": {
      "title": "Struts: Duplicate Validation Forms",
      "description": "The product uses multiple validation forms with the same name, which might cause the Struts Validator to validate a form that the programmer does not expect."
    },
    "103": {
      "title": "Struts: Incomplete validate() Method Definition",
      "description": "The product has a validator form that either does not define a validate() method, or defines a validate() method but does not call super.validate()."
    },
    "104": {
      "title": "Struts: Form Bean Does Not Extend Validation Class",
      "description": "If a form bean does not extend an ActionForm subclass of the Validator framework, it can expose the application to other weaknesses related to insufficient input validation."
    },
    "105": {
      "title": "Struts: Form Field Without Validator",
      "description": "The product has a form field that is not validated by a corresponding validation form, which can introduce other weaknesses related to insufficient input validation."
    },
    "106": {
      "title": "Struts: Plug-in Framework not in Use",
      "description": "When an application does not use an input validation framework such as the Struts Validator, there is a greater risk of introducing weaknesses related to insufficient input validation."
    },
    "107": {
      "title": "Struts: Unused Validation Form",
      "description": "An unused validation form indicates that validation logic is not up-to-date."
    },
    "108": {
      "title": "Struts: Unvalidated Action Form",
      "description": "Every Action Form must have a corresponding validation form."
    },
    "109": {
      "title": "Struts: Validator Turned Off",
      "description": "Automatic filtering via a Struts bean has been turned off, which disables the Struts Validator and custom validation logic. This exposes the application to other weaknesses related to insufficient input validation."
    },
    "110": {
      "title": "Struts: Validator Without Form Field",
      "description": "Validation fields that do not appear in forms they are associated with indicate that the validation logic is out of date."
    },
    "111": {
      "title": "Direct Use of Unsafe JNI",
      "description": "When a Java application uses the Java Native Interface (JNI) to call code written in another programming language, it can expose the application to weaknesses in that code, even if those weaknesses cannot occur in Java."
    },
    "112": {
      "title": "Missing XML Validation",
      "description": "The product accepts XML from an untrusted source but does not validate the XML against the proper schema."
    },
    "113": {
      "title": "Improper Neutralization of CRLF Sequences in HTTP Headers ('HTTP Request/Response Splitting')",
      "description": "The product receives data from an HTTP agent/component (e.g., web server, proxy, browser, etc.), but it does not neutralize or incorrectly neutralizes CR and LF characters before the data is included in outgoing HTTP headers."
    },
    "114": {
      "title": "Process Control",
      "description": "Executing commands or loading libraries from an untrusted source or in an untrusted environment can cause an application to execute malicious commands (and payloads) on behalf of an attacker."
    },
    "115": {
      "title": "Misinterpretation of Input",
      "description": "The product misinterprets an input, whether from an attacker or another product, in a security-relevant fashion."
    },
    "116": {
      "title": "Improper Encoding or Escaping of Output",
      "description": "The product prepares a structured message for communication with another component, but encoding or escaping of the data is either missing or done incorrectly. As a result, the intended structure of the message is not preserved."
    },
    "117": {
      "title": "Improper Output Neutralization for Logs",
      "description": "The product does not neutralize or incorrectly neutralizes output that is written to logs."
    },
    "118": {
      "title": "Incorrect Access of Indexable Resource ('Range Error')",
      "description": "The product does not restrict or incorrectly restricts operations within the boundaries of a resource that is accessed using an index or pointer, such as memory or files."
    },
    "119": {
      "title": "Improper Restriction of Operations within the Bounds of a Memory Buffer",
      "description": "The product performs operations on a memory buffer, but it can read from or write to a memory location that is outside of the intended boundary of the buffer."
    },
    "120": {
      "title": "Buffer Copy without Checking Size of Input ('Classic Buffer Overflow')",
      "description": "The product copies an input buffer to an output buffer without verifying that the size of the input buffer is less than the size of the output buffer, leading to a buffer overflow."
    },
    "121": {
      "title": "Stack-based Buffer Overflow",
      "description": "A stack-based buffer overflow condition is a condition where the buffer being overwritten is allocated on the stack (i.e., is a local variable or, rarely, a parameter to a function)."
    },
    "122": {
      "title": "Heap-based Buffer Overflow",
      "description": "A heap overflow condition is a buffer overflow, where the buffer that can be overwritten is allocated in the heap portion of memory, generally meaning that the buffer was allocated using a routine such as malloc()."
    },
    "123": {
      "title": "Write-what-where Condition",
      "description": "Any condition where the attacker has the ability to write an arbitrary value to an arbitrary location, often as the result of a buffer overflow."
    },
    "124": {
      "title": "Buffer Underwrite ('Buffer Underflow')",
      "description": "The product writes to a buffer using an index or pointer that references a memory location prior to the beginning of the buffer."
    },
    "125": {
      "title": "Out-of-bounds Read",
      "description": "The product reads data past the end, or before the beginning, of the intended buffer."
    },
    "126": {
      "title": "Buffer Over-read",
      "description": "The product reads from a buffer using buffer access mechanisms such as indexes or pointers that reference memory locations after the targeted buffer."
    },
    "127": {
      "title": "Buffer Under-read",
      "description": "The product reads from a buffer using buffer access mechanisms such as indexes or pointers that reference memory locations prior to the targeted buffer."
    },
    "128": {
      "title": "Wrap-around Error",
      "description": "Wrap around errors occur whenever a value is incremented past the maximum value for its type and therefore \"wraps around\" to a very small, negative, or undefined value."
    },
    "129": {
      "title": "Improper Validation of Array Index",
      "description": "The product uses untrusted input when calculating or using an array index, but the product does not validate or incorrectly validates the index to ensure the index references a valid position within the array."
    },
    "130": {
      "title": "Improper Handling of Length Parameter Inconsistency",
      "description": "The product parses a formatted message or structure, but it does not handle or incorrectly handles a length field that is inconsistent with the actual length of the associated data."
    },
    "131": {
      "title": "Incorrect Calculation of Buffer Size",
      "description": "The product does not correctly calculate the size to be used when allocating a buffer, which could lead to a buffer overflow."
    },
    "132": {
      "title": "DEPRECATED: Miscalculated Null Termination",
      "description": "This entry has been deprecated because it was a duplicate of CWE-170. All content has been transferred to CWE-170."
    },
    "134": {
      "title": "Use of Externally-Controlled Format String",
      "description": "The product uses a function that accepts a format string as an argument, but the format string originates from an external source."
    },
    "135": {
      "title": "Incorrect Calculation of Multi-Byte String Length",
      "description": "The product does not correctly calculate the length of strings that can contain wide or multi-byte characters."
    },
    "138": {
      "title": "Improper Neutralization of Special Elements",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as control elements or syntactic markers when they are sent to a downstream component."
    },
    "140": {
      "title": "Improper Neutralization of Delimiters",
      "description": "The product does not neutralize or incorrectly neutralizes delimiters."
    },
    "141": {
      "title": "Improper Neutralization of Parameter/Argument Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as parameter or argument delimiters when they are sent to a downstream component."
    },
    "142": {
      "title": "Improper Neutralization of Value Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as value delimiters when they are sent to a downstream component."
    },
    "143": {
      "title": "Improper Neutralization of Record Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as record delimiters when they are sent to a downstream component."
    },
    "144": {
      "title": "Improper Neutralization of Line Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as line delimiters when they are sent to a downstream component."
    },
    "145": {
      "title": "Improper Neutralization of Section Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as section delimiters when they are sent to a downstream component."
    },
    "146": {
      "title": "Improper Neutralization of Expression/Command Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as expression or command delimiters when they are sent to a downstream component."
    },
    "147": {
      "title": "Improper Neutralization of Input Terminators",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as input terminators when they are sent to a downstream component."
    },
    "148": {
      "title": "Improper Neutralization of Input Leaders",
      "description": "The product does not properly handle when a leading character or sequence (\"leader\") is missing or malformed, or if multiple leaders are used when only one should be allowed."
    },
    "149": {
      "title": "Improper Neutralization of Quoting Syntax",
      "description": "Quotes injected into a product can be used to compromise a system. As data are parsed, an injected/absent/duplicate/malformed use of quotes may cause the process to take unexpected actions."
    },
    "150": {
      "title": "Improper Neutralization of Escape, Meta, or Control Sequences",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as escape, meta, or control character sequences when they are sent to a downstream component."
    },
    "151": {
      "title": "Improper Neutralization of Comment Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as comment delimiters when they are sent to a downstream component."
    },
    "152": {
      "title": "Improper Neutralization of Macro Symbols",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as macro symbols when they are sent to a downstream component."
    },
    "153": {
      "title": "Improper Neutralization of Substitution Characters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as substitution characters when they are sent to a downstream component."
    },
    "154": {
      "title": "Improper Neutralization of Variable Name Delimiters",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as variable name delimiters when they are sent to a downstream component."
    },
    "155": {
      "title": "Improper Neutralization of Wildcards or Matching Symbols",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as wildcards or matching symbols when they are sent to a downstream component."
    },
    "156": {
      "title": "Improper Neutralization of Whitespace",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as whitespace when they are sent to a downstream component."
    },
    "157": {
      "title": "Failure to Sanitize Paired Delimiters",
      "description": "The product does not properly handle the characters that are used to mark the beginning and ending of a group of entities, such as parentheses, brackets, and braces."
    },
    "158": {
      "title": "Improper Neutralization of Null Byte or NUL Character",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes NUL characters or null bytes when they are sent to a downstream component."
    },
    "159": {
      "title": "Improper Handling of Invalid Use of Special Elements",
      "description": "The product does not properly filter, remove, quote, or otherwise manage the invalid use of special elements in user-controlled input, which could cause adverse effect on its behavior and integrity."
    },
    "160": {
      "title": "Improper Neutralization of Leading Special Elements",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes leading special elements that could be interpreted in unexpected ways when they are sent to a downstream component."
    },
    "161": {
      "title": "Improper Neutralization of Multiple Leading Special Elements",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes multiple leading special elements that could be interpreted in unexpected ways when they are sent to a downstream component."
    },
    "162": {
      "title": "Improper Neutralization of Trailing Special Elements",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes trailing special elements that could be interpreted in unexpected ways when they are sent to a downstream component."
    },
    "163": {
      "title": "Improper Neutralization of Multiple Trailing Special Elements",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes multiple trailing special elements that could be interpreted in unexpected ways when they are sent to a downstream component."
    },
    "164": {
      "title": "Improper Neutralization of Internal Special Elements",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes internal special elements that could be interpreted in unexpected ways when they are sent to a downstream component."
    },
    "165": {
      "title": "Improper Neutralization of Multiple Internal Special Elements",
      "description": "The product receives input from an upstream component, but it does not neutralize or incorrectly neutralizes multiple internal special elements that could be interpreted in unexpected ways when they are sent to a downstream component."
    },
    "166": {
      "title": "Improper Handling of Missing Special Element",
      "description": "The product receives input from an upstream component, but it does not handle or incorrectly handles when an expected special element is missing."
    },
    "167": {
      "title": "Improper Handling of Additional Special Element",
      "description": "The product receives input from an upstream component, but it does not handle or incorrectly handles when an additional unexpected special element is provided."
    },
    "168": {
      "title": "Improper Handling of Inconsistent Special Elements",
      "description": "The product does not properly handle input in which an inconsistency exists between two or more special characters or reserved words."
    },
    "170": {
      "title": "Improper Null Termination",
      "description": "The product does not terminate or incorrectly terminates a string or array with a null character or equivalent terminator."
    },
    "172": {
      "title": "Encoding Error",
      "description": "The product does not properly encode or decode the data, resulting in unexpected values."
    },
    "173": {
      "title": "Improper Handling of Alternate Encoding",
      "description": "The product does not properly handle when an input uses an alternate encoding that is valid for the control sphere to which the input is being sent."
    },
    "174": {
      "title": "Double Decoding of the Same Data",
      "description": "The product decodes the same input twice, which can limit the effectiveness of any protection mechanism that occurs in between the decoding operations."
    },
    "175": {
      "title": "Improper Handling of Mixed Encoding",
      "description": "The product does not properly handle when the same input uses several different (mixed) encodings."
    },
    "176": {
      "title": "Improper Handling of Unicode Encoding",
      "description": "The product does not properly handle when an input contains Unicode encoding."
    },
    "177": {
      "title": "Improper Handling of URL Encoding (Hex Encoding)",
      "description": "The product does not properly handle when all or part of an input has been URL encoded."
    },
    "178": {
      "title": "Improper Handling of Case Sensitivity",
      "description": "The product does not properly account for differences in case sensitivity when accessing or determining the properties of a resource, leading to inconsistent results."
    },
    "179": {
      "title": "Incorrect Behavior Order: Early Validation",
      "description": "The product validates input before applying protection mechanisms that modify the input, which could allow an attacker to bypass the validation via dangerous inputs that only arise after the modification."
    },
    "180": {
      "title": "Incorrect Behavior Order: Validate Before Canonicalize",
      "description": "The product validates input before it is canonicalized, which prevents the product from detecting data that becomes invalid after the canonicalization step."
    },
    "181": {
      "title": "Incorrect Behavior Order: Validate Before Filter",
      "description": "The product validates data before it has been filtered, which prevents the product from detecting data that becomes invalid after the filtering step."
    },
    "182": {
      "title": "Collapse of Data into Unsafe Value",
      "description": "The product filters data in a way that causes it to be reduced or \"collapsed\" into an unsafe value that violates an expected security property."
    },
    "183": {
      "title": "Permissive List of Allowed Inputs",
      "description": "The product implements a protection mechanism that relies on a list of inputs (or properties of inputs) that are explicitly allowed by policy because the inputs are assumed to be safe, but the list is too permissive - that is, it allows an input that is unsafe, leading to resultant weaknesses."
    },
    "184": {
      "title": "Incomplete List of Disallowed Inputs",
      "description": "The product implements a protection mechanism that relies on a list of inputs (or properties of inputs) that are not allowed by policy or otherwise require other action to neutralize before additional processing takes place, but the list is incomplete, leading to resultant weaknesses."
    },
    "185": {
      "title": "Incorrect Regular Expression",
      "description": "The product specifies a regular expression in a way that causes data to be improperly matched or compared."
    },
    "186": {
      "title": "Overly Restrictive Regular Expression",
      "description": "A regular expression is overly restrictive, which prevents dangerous values from being detected."
    },
    "187": {
      "title": "Partial String Comparison",
      "description": "The product performs a comparison that only examines a portion of a factor before determining whether there is a match, such as a substring, leading to resultant weaknesses."
    },
    "188": {
      "title": "Reliance on Data/Memory Layout",
      "description": "The product makes invalid assumptions about how protocol data or memory is organized at a lower level, resulting in unintended program behavior."
    },
    "190": {
      "title": "Integer Overflow or Wraparound",
      "description": "The product performs a calculation that can produce an integer overflow or wraparound, when the logic assumes that the resulting value will always be larger than the original value. This can introduce other weaknesses when the calculation is used for resource management or execution control."
    },
    "191": {
      "title": "Integer Underflow (Wrap or Wraparound)",
      "description": "The product subtracts one value from another, such that the result is less than the minimum allowable integer value, which produces a value that is not equal to the correct result."
    },
    "192": {
      "title": "Integer Coercion Error",
      "description": "Integer coercion refers to a set of flaws pertaining to the type casting, extension, or truncation of primitive data types."
    },
    "193": {
      "title": "Off-by-one Error",
      "description": "A product calculates or uses an incorrect maximum or minimum value that is 1 more, or 1 less, than the correct value."
    },
    "194": {
      "title": "Unexpected Sign Extension",
      "description": "The product performs an operation on a number that causes it to be sign extended when it is transformed into a larger data type. When the original number is negative, this can produce unexpected values that lead to resultant weaknesses."
    },
    "195": {
      "title": "Signed to Unsigned Conversion Error",
      "description": "The product uses a signed primitive and performs a cast to an unsigned primitive, which can produce an unexpected value if the value of the signed primitive can not be represented using an unsigned primitive."
    },
    "196": {
      "title": "Unsigned to Signed Conversion Error",
      "description": "The product uses an unsigned primitive and performs a cast to a signed primitive, which can produce an unexpected value if the value of the unsigned primitive can not be represented using a signed primitive."
    },
    "197": {
      "title": "Numeric Truncation Error",
      "description": "Truncation errors occur when a primitive is cast to a primitive of a smaller size and data is lost in the conversion."
    },
    "198": {
      "title": "Use of Incorrect Byte Ordering",
      "description": "The product receives input from an upstream component, but it does not account for byte ordering (e.g. big-endian and little-endian) when processing the input, causing an incorrect number or value to be used."
    },
    "200": {
      "title": "Exposure of Sensitive Information to an Unauthorized Actor",
      "description": "The product exposes sensitive information to an actor that is not explicitly authorized to have access to that information."
    },
    "201": {
      "title": "Insertion of Sensitive Information Into Sent Data",
      "description": "The code transmits data to another actor, but a portion of the data includes sensitive information that should not be accessible to that actor."
    },
    "202": {
      "title": "Exposure of Sensitive Information Through Data Queries",
      "description": "When trying to keep information confidential, an attacker can often infer some of the information by using statistics."
    },
    "203": {
      "title": "Observable Discrepancy",
      "description": "The product behaves differently or sends different responses under different circumstances in a way that is observable to an unauthorized actor, which exposes security-relevant information about the state of the product, such as whether a particular operation was successful or not."
    },
    "204": {
      "title": "Observable Response Discrepancy",
      "description": "The product provides different responses to incoming requests in a way that reveals internal state information to an unauthorized actor outside of the intended control sphere."
    },
    "205": {
      "title": "Observable Behavioral Discrepancy",
      "description": "The product's behaviors indicate important differences that may be observed by unauthorized actors in a way that reveals (1) its internal state or decision process, or (2) differences from other products with equivalent functionality."
    },
    "206": {
      "title": "Observable Internal Behavioral Discrepancy",
      "description": "The product performs multiple behaviors that are combined to produce a single result, but the individual behaviors are observable separately in a way that allows attackers to reveal internal state or internal decision points."
    },
    "207": {
      "title": "Observable Behavioral Discrepancy With Equivalent Products",
      "description": "The product operates in an environment in which its existence or specific identity should not be known, but it behaves differently than other products with equivalent functionality, in a way that is observable to an attacker."
    },
    "208": {
      "title": "Observable Timing Discrepancy",
      "description": "Two separate operations in a product require different amounts of time to complete, in a way that is observable to an actor and reveals security-relevant information about the state of the product, such as whether a particular operation was successful or not."
    },
    "209": {
      "title": "Generation of Error Message Containing Sensitive Information",
      "description": "The product generates an error message that includes sensitive information about its environment, users, or associated data."
    },
    "210": {
      "title": "Self-generated Error Message Containing Sensitive Information",
      "description": "The product identifies an error condition and creates its own diagnostic or error messages that contain sensitive information."
    },
    "211": {
      "title": "Externally-Generated Error Message Containing Sensitive Information",
      "description": "The product performs an operation that triggers an external diagnostic or error message that is not directly generated or controlled by the product, such as an error generated by the programming language interpreter that a software application uses. The error can contain sensitive system information."
    },
    "212": {
      "title": "Improper Removal of Sensitive Information Before Storage or Transfer",
      "description": "The product stores, transfers, or shares a resource that contains sensitive information, but it does not properly remove that information before the product makes the resource available to unauthorized actors."
    },
    "213": {
      "title": "Exposure of Sensitive Information Due to Incompatible Policies",
      "description": "The product's intended functionality exposes information to certain actors in accordance with the developer's security policy, but this information is regarded as sensitive according to the intended security policies of other stakeholders such as the product's administrator, users, or others whose information is being processed."
    },
    "214": {
      "title": "Invocation of Process Using Visible Sensitive Information",
      "description": "A process is invoked with sensitive command-line arguments, environment variables, or other elements that can be seen by other processes on the operating system."
    },
    "215": {
      "title": "Insertion of Sensitive Information Into Debugging Code",
      "description": "The product inserts sensitive information into debugging code, which could expose this information if the debugging code is not disabled in production."
    },
    "216": {
      "title": "DEPRECATED: Containment Errors (Container Errors)",
      "description": "This entry has been deprecated, as it was not effective as a weakness and was structured more like a category. In addition, the name is inappropriate, since the \"container\" term is widely understood by developers in different ways than originally intended by PLOVER, the original source for this entry."
    },
    "217": {
      "title": "DEPRECATED: Failure to Protect Stored Data from Modification",
      "description": "This entry has been deprecated because it incorporated and confused multiple weaknesses. The issues formerly covered in this entry can be found at CWE-766 and CWE-767."
    },
    "218": {
      "title": "DEPRECATED: Failure to provide confidentiality for stored data",
      "description": "This weakness has been deprecated because it was a duplicate of CWE-493. All content has been transferred to CWE-493."
    },
    "219": {
      "title": "Storage of File with Sensitive Data Under Web Root",
      "description": "The product stores sensitive data under the web document root with insufficient access control, which might make it accessible to untrusted parties."
    },
    "220": {
      "title": "Storage of File With Sensitive Data Under FTP Root",
      "description": "The product stores sensitive data under the FTP server root with insufficient access control, which might make it accessible to untrusted parties."
    },
    "221": {
      "title": "Information Loss or Omission",
      "description": "The product does not record, or improperly records, security-relevant information that leads to an incorrect decision or hampers later analysis."
    },
    "222": {
      "title": "Truncation of Security-relevant Information",
      "description": "The product truncates the display, recording, or processing of security-relevant information in a way that can obscure the source or nature of an attack."
    },
    "223": {
      "title": "Omission of Security-relevant Information",
      "description": "The product does not record or display information that would be important for identifying the source or nature of an attack, or determining if an action is safe."
    },
    "224": {
      "title": "Obscured Security-relevant Information by Alternate Name",
      "description": "The product records security-relevant information according to an alternate name of the affected entity, instead of the canonical name."
    },
    "225": {
      "title": "DEPRECATED: General Information Management Problems",
      "description": "This weakness can be found at CWE-199."
    },
    "226": {
      "title": "Sensitive Information in Resource Not Removed Before Reuse",
      "description": "The product releases a resource such as memory or a file so that it can be made available for reuse, but it does not clear or \"zeroize\" the information contained in the resource before the product performs a critical state transition or makes the resource available for reuse by other entities."
    },
    "228": {
      "title": "Improper Handling of Syntactically Invalid Structure",
      "description": "The product does not handle or incorrectly handles input that is not syntactically well-formed with respect to the associated specification."
    },
    "229": {
      "title": "Improper Handling of Values",
      "description": "The product does not properly handle when the expected number of values for parameters, fields, or arguments is not provided in input, or if those values are undefined."
    },
    "230": {
      "title": "Improper Handling of Missing Values",
      "description": "The product does not handle or incorrectly handles when a parameter, field, or argument name is specified, but the associated value is missing, i.e. it is empty, blank, or null."
    },
    "231": {
      "title": "Improper Handling of Extra Values",
      "description": "The product does not handle or incorrectly handles when more values are provided than expected."
    },
    "232": {
      "title": "Improper Handling of Undefined Values",
      "description": "The product does not handle or incorrectly handles when a value is not defined or supported for the associated parameter, field, or argument name."
    },
    "233": {
      "title": "Improper Handling of Parameters",
      "description": "The product does not properly handle when the expected number of parameters, fields, or arguments is not provided in input, or if those parameters are undefined."
    },
    "234": {
      "title": "Failure to Handle Missing Parameter",
      "description": "If too few arguments are sent to a function, the function will still pop the expected number of arguments from the stack. Potentially, a variable number of arguments could be exhausted in a function as well."
    },
    "235": {
      "title": "Improper Handling of Extra Parameters",
      "description": "The product does not handle or incorrectly handles when the number of parameters, fields, or arguments with the same name exceeds the expected amount."
    },
    "236": {
      "title": "Improper Handling of Undefined Parameters",
      "description": "The product does not handle or incorrectly handles when a particular parameter, field, or argument name is not defined or supported by the product."
    },
    "237": {
      "title": "Improper Handling of Structural Elements",
      "description": "The product does not handle or incorrectly handles inputs that are related to complex structures."
    },
    "238": {
      "title": "Improper Handling of Incomplete Structural Elements",
      "description": "The product does not handle or incorrectly handles when a particular structural element is not completely specified."
    },
    "239": {
      "title": "Failure to Handle Incomplete Element",
      "description": "The product does not properly handle when a particular element is not completely specified."
    },
    "240": {
      "title": "Improper Handling of Inconsistent Structural Elements",
      "description": "The product does not handle or incorrectly handles when two or more structural elements should be consistent, but are not."
    },
    "241": {
      "title": "Improper Handling of Unexpected Data Type",
      "description": "The product does not handle or incorrectly handles when a particular element is not the expected type, e.g. it expects a digit (0-9) but is provided with a letter (A-Z)."
    },
    "242": {
      "title": "Use of Inherently Dangerous Function",
      "description": "The product calls a function that can never be guaranteed to work safely."
    },
    "243": {
      "title": "Creation of chroot Jail Without Changing Working Directory",
      "description": "The product uses the chroot() system call to create a jail, but does not change the working directory afterward. This does not prevent access to files outside of the jail."
    },
    "244": {
      "title": "Improper Clearing of Heap Memory Before Release ('Heap Inspection')",
      "description": "Using realloc() to resize buffers that store sensitive information can leave the sensitive information exposed to attack, because it is not removed from memory."
    },
    "245": {
      "title": "J2EE Bad Practices: Direct Management of Connections",
      "description": "The J2EE application directly manages connections, instead of using the container's connection management facilities."
    },
    "246": {
      "title": "J2EE Bad Practices: Direct Use of Sockets",
      "description": "The J2EE application directly uses sockets instead of using framework method calls."
    },
    "247": {
      "title": "DEPRECATED: Reliance on DNS Lookups in a Security Decision",
      "description": "This entry has been deprecated because it was a duplicate of CWE-350. All content has been transferred to CWE-350."
    },
    "248": {
      "title": "Uncaught Exception",
      "description": "An exception is thrown from a function, but it is not caught."
    },
    "249": {
      "title": "DEPRECATED: Often Misused: Path Manipulation",
      "description": "This entry has been deprecated because of name\n\tconfusion and an accidental combination of multiple\n\tweaknesses. Most of its content has been transferred to\n\tCWE-785."
    },
    "250": {
      "title": "Execution with Unnecessary Privileges",
      "description": "The product performs an operation at a privilege level that is higher than the minimum level required, which creates new weaknesses or amplifies the consequences of other weaknesses."
    },
    "252": {
      "title": "Unchecked Return Value",
      "description": "The product does not check the return value from a method or function, which can prevent it from detecting unexpected states and conditions."
    },
    "253": {
      "title": "Incorrect Check of Function Return Value",
      "description": "The product incorrectly checks a return value from a function, which prevents it from detecting errors or exceptional conditions."
    },
    "256": {
      "title": "Plaintext Storage of a Password",
      "description": "Storing a password in plaintext may result in a system compromise."
    },
    "257": {
      "title": "Storing Passwords in a Recoverable Format",
      "description": "The storage of passwords in a recoverable format makes them subject to password reuse attacks by malicious users. In fact, it should be noted that recoverable encrypted passwords provide no significant benefit over plaintext passwords since they are subject not only to reuse by malicious attackers but also by malicious insiders. If a system administrator can recover a password directly, or use a brute force search on the available information, the administrator can use the password on other accounts."
    },
    "258": {
      "title": "Empty Password in Configuration File",
      "description": "Using an empty string as a password is insecure."
    },
    "259": {
      "title": "Use of Hard-coded Password",
      "description": "The product contains a hard-coded password, which it uses for its own inbound authentication or for outbound communication to external components."
    },
    "260": {
      "title": "Password in Configuration File",
      "description": "The product stores a password in a configuration file that might be accessible to actors who do not know the password."
    },
    "261": {
      "title": "Weak Encoding for Password",
      "description": "Obscuring a password with a trivial encoding does not protect the password."
    },
    "262": {
      "title": "Not Using Password Aging",
      "description": "The product does not have a mechanism in place for managing password aging."
    },
    "263": {
      "title": "Password Aging with Long Expiration",
      "description": "The product supports password aging, but the expiration period is too long."
    },
    "266": {
      "title": "Incorrect Privilege Assignment",
      "description": "A product incorrectly assigns a privilege to a particular actor, creating an unintended sphere of control for that actor."
    },
    "267": {
      "title": "Privilege Defined With Unsafe Actions",
      "description": "A particular privilege, role, capability, or right can be used to perform unsafe actions that were not intended, even when it is assigned to the correct entity."
    },
    "268": {
      "title": "Privilege Chaining",
      "description": "Two distinct privileges, roles, capabilities, or rights can be combined in a way that allows an entity to perform unsafe actions that would not be allowed without that combination."
    },
    "269": {
      "title": "Improper Privilege Management",
      "description": "The product does not properly assign, modify, track, or check privileges for an actor, creating an unintended sphere of control for that actor."
    },
    "270": {
      "title": "Privilege Context Switching Error",
      "description": "The product does not properly manage privileges while it is switching between different contexts that have different privileges or spheres of control."
    },
    "271": {
      "title": "Privilege Dropping / Lowering Errors",
      "description": "The product does not drop privileges before passing control of a resource to an actor that does not have those privileges."
    },
    "272": {
      "title": "Least Privilege Violation",
      "description": "The elevated privilege level required to perform operations such as chroot() should be dropped immediately after the operation is performed."
    },
    "273": {
      "title": "Improper Check for Dropped Privileges",
      "description": "The product attempts to drop privileges but does not check or incorrectly checks to see if the drop succeeded."
    },
    "274": {
      "title": "Improper Handling of Insufficient Privileges",
      "description": "The product does not handle or incorrectly handles when it has insufficient privileges to perform an operation, leading to resultant weaknesses."
    },
    "276": {
      "title": "Incorrect Default Permissions",
      "description": "During installation, installed file permissions are set to allow anyone to modify those files."
    },
    "277": {
      "title": "Insecure Inherited Permissions",
      "description": "A product defines a set of insecure permissions that are inherited by objects that are created by the program."
    },
    "278": {
      "title": "Insecure Preserved Inherited Permissions",
      "description": "A product inherits a set of insecure permissions for an object, e.g. when copying from an archive file, without user awareness or involvement."
    },
    "279": {
      "title": "Incorrect Execution-Assigned Permissions",
      "description": "While it is executing, the product sets the permissions of an object in a way that violates the intended permissions that have been specified by the user."
    },
    "280": {
      "title": "Improper Handling of Insufficient Permissions or Privileges ",
      "description": "The product does not handle or incorrectly handles when it has insufficient privileges to access resources or functionality as specified by their permissions. This may cause it to follow unexpected code paths that may leave the product in an invalid state."
    },
    "281": {
      "title": "Improper Preservation of Permissions",
      "description": "The product does not preserve permissions or incorrectly preserves permissions when copying, restoring, or sharing objects, which can cause them to have less restrictive permissions than intended."
    },
    "282": {
      "title": "Improper Ownership Management",
      "description": "The product assigns the wrong ownership, or does not properly verify the ownership, of an object or resource."
    },
    "283": {
      "title": "Unverified Ownership",
      "description": "The product does not properly verify that a critical resource is owned by the proper entity."
    },
    "284": {
      "title": "Improper Access Control",
      "description": "The product does not restrict or incorrectly restricts access to a resource from an unauthorized actor."
    },
    "285": {
      "title": "Improper Authorization",
      "description": "The product does not perform or incorrectly performs an authorization check when an actor attempts to access a resource or perform an action."
    },
    "286": {
      "title": "Incorrect User Management",
      "description": "The product does not properly manage a user within its environment."
    },
    "287": {
      "title": "Improper Authentication",
      "description": "When an actor claims to have a given identity, the product does not prove or insufficiently proves that the claim is correct."
    },
    "288": {
      "title": "Authentication Bypass Using an Alternate Path or Channel",
      "description": "A product requires authentication, but the product has an alternate path or channel that does not require authentication."
    },
    "289": {
      "title": "Authentication Bypass by Alternate Name",
      "description": "The product performs authentication based on the name of a resource being accessed, or the name of the actor performing the access, but it does not properly check all possible names for that resource or actor."
    },
    "290": {
      "title": "Authentication Bypass by Spoofing",
      "description": "This attack-focused weakness is caused by incorrectly implemented authentication schemes that are subject to spoofing attacks."
    },
    "291": {
      "title": "Reliance on IP Address for Authentication",
      "description": "The product uses an IP address for authentication."
    },
    "292": {
      "title": "DEPRECATED: Trusting Self-reported DNS Name",
      "description": "This entry has been deprecated because it was a duplicate of CWE-350. All content has been transferred to CWE-350."
    },
    "293": {
      "title": "Using Referer Field for Authentication",
      "description": "The referer field in HTTP requests can be easily modified and, as such, is not a valid means of message integrity checking."
    },
    "294": {
      "title": "Authentication Bypass by Capture-replay",
      "description": "A capture-replay flaw exists when the design of the product makes it possible for a malicious user to sniff network traffic and bypass authentication by replaying it to the server in question to the same effect as the original message (or with minor changes)."
    },
    "295": {
      "title": "Improper Certificate Validation",
      "description": "The product does not validate, or incorrectly validates, a certificate."
    },
    "296": {
      "title": "Improper Following of a Certificate's Chain of Trust",
      "description": "The product does not follow, or incorrectly follows, the chain of trust for a certificate back to a trusted root certificate, resulting in incorrect trust of any resource that is associated with that certificate."
    },
    "297": {
      "title": "Improper Validation of Certificate with Host Mismatch",
      "description": "The product communicates with a host that provides a certificate, but the product does not properly ensure that the certificate is actually associated with that host."
    },
    "298": {
      "title": "Improper Validation of Certificate Expiration",
      "description": "A certificate expiration is not validated or is incorrectly validated, so trust may be assigned to certificates that have been abandoned due to age."
    },
    "299": {
      "title": "Improper Check for Certificate Revocation",
      "description": "The product does not check or incorrectly checks the revocation status of a certificate, which may cause it to use a certificate that has been compromised."
    },
    "300": {
      "title": "Channel Accessible by Non-Endpoint",
      "description": "The product does not adequately verify the identity of actors at both ends of a communication channel, or does not adequately ensure the integrity of the channel, in a way that allows the channel to be accessed or influenced by an actor that is not an endpoint."
    },
    "301": {
      "title": "Reflection Attack in an Authentication Protocol",
      "description": "Simple authentication protocols are subject to reflection attacks if a malicious user can use the target machine to impersonate a trusted user."
    },
    "302": {
      "title": "Authentication Bypass by Assumed-Immutable Data",
      "description": "The authentication scheme or implementation uses key data elements that are assumed to be immutable, but can be controlled or modified by the attacker."
    },
    "303": {
      "title": "Incorrect Implementation of Authentication Algorithm",
      "description": "The requirements for the product dictate the use of an established authentication algorithm, but the implementation of the algorithm is incorrect."
    },
    "304": {
      "title": "Missing Critical Step in Authentication",
      "description": "The product implements an authentication technique, but it skips a step that weakens the technique."
    },
    "305": {
      "title": "Authentication Bypass by Primary Weakness",
      "description": "The authentication algorithm is sound, but the implemented mechanism can be bypassed as the result of a separate weakness that is primary to the authentication error."
    },
    "306": {
      "title": "Missing Authentication for Critical Function",
      "description": "The product does not perform any authentication for functionality that requires a provable user identity or consumes a significant amount of resources."
    },
    "307": {
      "title": "Improper Restriction of Excessive Authentication Attempts",
      "description": "The product does not implement sufficient measures to prevent multiple failed authentication attempts within a short time frame, making it more susceptible to brute force attacks."
    },
    "308": {
      "title": "Use of Single-factor Authentication",
      "description": "The use of single-factor authentication can lead to unnecessary risk of compromise when compared with the benefits of a dual-factor authentication scheme."
    },
    "309": {
      "title": "Use of Password System for Primary Authentication",
      "description": "The use of password systems as the primary means of authentication may be subject to several flaws or shortcomings, each reducing the effectiveness of the mechanism."
    },
    "311": {
      "title": "Missing Encryption of Sensitive Data",
      "description": "The product does not encrypt sensitive or critical information before storage or transmission."
    },
    "312": {
      "title": "Cleartext Storage of Sensitive Information",
      "description": "The product stores sensitive information in cleartext within a resource that might be accessible to another control sphere."
    },
    "313": {
      "title": "Cleartext Storage in a File or on Disk",
      "description": "The product stores sensitive information in cleartext in a file, or on disk."
    },
    "314": {
      "title": "Cleartext Storage in the Registry",
      "description": "The product stores sensitive information in cleartext in the registry."
    },
    "315": {
      "title": "Cleartext Storage of Sensitive Information in a Cookie",
      "description": "The product stores sensitive information in cleartext in a cookie."
    },
    "316": {
      "title": "Cleartext Storage of Sensitive Information in Memory",
      "description": "The product stores sensitive information in cleartext in memory."
    },
    "317": {
      "title": "Cleartext Storage of Sensitive Information in GUI",
      "description": "The product stores sensitive information in cleartext within the GUI."
    },
    "318": {
      "title": "Cleartext Storage of Sensitive Information in Executable",
      "description": "The product stores sensitive information in cleartext in an executable."
    },
    "319": {
      "title": "Cleartext Transmission of Sensitive Information",
      "description": "The product transmits sensitive or security-critical data in cleartext in a communication channel that can be sniffed by unauthorized actors."
    },
    "321": {
      "title": "Use of Hard-coded Cryptographic Key",
      "description": "The use of a hard-coded cryptographic key significantly increases the possibility that encrypted data may be recovered."
    },
    "322": {
      "title": "Key Exchange without Entity Authentication",
      "description": "The product performs a key exchange with an actor without verifying the identity of that actor."
    },
    "323": {
      "title": "Reusing a Nonce, Key Pair in Encryption",
      "description": "Nonces should be used for the present occasion and only once."
    },
    "324": {
      "title": "Use of a Key Past its Expiration Date",
      "description": "The product uses a cryptographic key or password past its expiration date, which diminishes its safety significantly by increasing the timing window for cracking attacks against that key."
    },
    "325": {
      "title": "Missing Cryptographic Step",
      "description": "The product does not implement a required step in a cryptographic algorithm, resulting in weaker encryption than advertised by the algorithm."
    },
    "326": {
      "title": "Inadequate Encryption Strength",
      "description": "The product stores or transmits sensitive data using an encryption scheme that is theoretically sound, but is not strong enough for the level of protection required."
    },
    "327": {
      "title": "Use of a Broken or Risky Cryptographic Algorithm",
      "description": "The product uses a broken or risky cryptographic algorithm or protocol."
    },
    "328": {
      "title": "Use of Weak Hash",
      "description": "The product uses an algorithm that produces a digest (output value) that does not meet security expectations for a hash function that allows an adversary to reasonably determine the original input (preimage attack), find another input that can produce the same hash (2nd preimage attack), or find multiple inputs that evaluate to the same hash (birthday attack)."
    },
    "329": {
      "title": "Generation of Predictable IV with CBC Mode",
      "description": "The product generates and uses a predictable initialization Vector (IV) with Cipher Block Chaining (CBC) Mode, which causes algorithms to be susceptible to dictionary attacks when they are encrypted under the same key."
    },
    "330": {
      "title": "Use of Insufficiently Random Values",
      "description": "The product uses insufficiently random numbers or values in a security context that depends on unpredictable numbers."
    },
    "331": {
      "title": "Insufficient Entropy",
      "description": "The product uses an algorithm or scheme that produces insufficient entropy, leaving patterns or clusters of values that are more likely to occur than others."
    },
    "332": {
      "title": "Insufficient Entropy in PRNG",
      "description": "The lack of entropy available for, or used by, a Pseudo-Random Number Generator (PRNG) can be a stability and security threat."
    },
    "333": {
      "title": "Improper Handling of Insufficient Entropy in TRNG",
      "description": "True random number generators (TRNG) generally have a limited source of entropy and therefore can fail or block."
    },
    "334": {
      "title": "Small Space of Random Values",
      "description": "The number of possible random values is smaller than needed by the product, making it more susceptible to brute force attacks."
    },
    "335": {
      "title": "Incorrect Usage of Seeds in Pseudo-Random Number Generator (PRNG)",
      "description": "The product uses a Pseudo-Random Number Generator (PRNG) but does not correctly manage seeds."
    },
    "336": {
      "title": "Same Seed in Pseudo-Random Number Generator (PRNG)",
      "description": "A Pseudo-Random Number Generator (PRNG) uses the same seed each time the product is initialized."
    },
    "337": {
      "title": "Predictable Seed in Pseudo-Random Number Generator (PRNG)",
      "description": "A Pseudo-Random Number Generator (PRNG) is initialized from a predictable seed, such as the process ID or system time."
    },
    "338": {
      "title": "Use of Cryptographically Weak Pseudo-Random Number Generator (PRNG)",
      "description": "The product uses a Pseudo-Random Number Generator (PRNG) in a security context, but the PRNG's algorithm is not cryptographically strong."
    },
    "339": {
      "title": "Small Seed Space in PRNG",
      "description": "A Pseudo-Random Number Generator (PRNG) uses a relatively small seed space, which makes it more susceptible to brute force attacks."
    },
    "340": {
      "title": "Generation of Predictable Numbers or Identifiers",
      "description": "The product uses a scheme that generates numbers or identifiers that are more predictable than required."
    },
    "341": {
      "title": "Predictable from Observable State",
      "description": "A number or object is predictable based on observations that the attacker can make about the state of the system or network, such as time, process ID, etc."
    },
    "342": {
      "title": "Predictable Exact Value from Previous Values",
      "description": "An exact value or random number can be precisely predicted by observing previous values."
    },
    "343": {
      "title": "Predictable Value Range from Previous Values",
      "description": "The product's random number generator produces a series of values which, when observed, can be used to infer a relatively small range of possibilities for the next value that could be generated."
    },
    "344": {
      "title": "Use of Invariant Value in Dynamically Changing Context",
      "description": "The product uses a constant value, name, or reference, but this value can (or should) vary across different environments."
    },
    "345": {
      "title": "Insufficient Verification of Data Authenticity",
      "description": "The product does not sufficiently verify the origin or authenticity of data, in a way that causes it to accept invalid data."
    },
    "346": {
      "title": "Origin Validation Error",
      "description": "The product does not properly verify that the source of data or communication is valid."
    },
    "347": {
      "title": "Improper Verification of Cryptographic Signature",
      "description": "The product does not verify, or incorrectly verifies, the cryptographic signature for data."
    },
    "348": {
      "title": "Use of Less Trusted Source",
      "description": "The product has two different sources of the same data or information, but it uses the source that has less support for verification, is less trusted, or is less resistant to attack."
    },
    "349": {
      "title": "Acceptance of Extraneous Untrusted Data With Trusted Data",
      "description": "The product, when processing trusted data, accepts any untrusted data that is also included with the trusted data, treating the untrusted data as if it were trusted."
    },
    "350": {
      "title": "Reliance on Reverse DNS Resolution for a Security-Critical Action",
      "description": "The product performs reverse DNS resolution on an IP address to obtain the hostname and make a security decision, but it does not properly ensure that the IP address is truly associated with the hostname."
    },
    "351": {
      "title": "Insufficient Type Distinction",
      "description": "The product does not properly distinguish between different types of elements in a way that leads to insecure behavior."
    },
    "352": {
      "title": "Cross-Site Request Forgery (CSRF)",
      "description": "The web application does not, or can not, sufficiently verify whether a well-formed, valid, consistent request was intentionally provided by the user who submitted the request."
    },
    "353": {
      "title": "Missing Support for Integrity Check",
      "description": "The product uses a transmission protocol that does not include a mechanism for verifying the integrity of the data during transmission, such as a checksum."
    },
    "354": {
      "title": "Improper Validation of Integrity Check Value",
      "description": "The product does not validate or incorrectly validates the integrity check values or \"checksums\" of a message. This may prevent it from detecting if the data has been modified or corrupted in transmission."
    },
    "356": {
      "title": "Product UI does not Warn User of Unsafe Actions",
      "description": "The product's user interface does not warn the user before undertaking an unsafe action on behalf of that user. This makes it easier for attackers to trick users into inflicting damage to their system."
    },
    "357": {
      "title": "Insufficient UI Warning of Dangerous Operations",
      "description": "The user interface provides a warning to a user regarding dangerous or sensitive operations, but the warning is not noticeable enough to warrant attention."
    },
    "358": {
      "title": "Improperly Implemented Security Check for Standard",
      "description": "The product does not implement or incorrectly implements one or more security-relevant checks as specified by the design of a standardized algorithm, protocol, or technique."
    },
    "359": {
      "title": "Exposure of Private Personal Information to an Unauthorized Actor",
      "description": "The product does not properly prevent a person's private, personal information from being accessed by actors who either (1) are not explicitly authorized to access the information or (2) do not have the implicit consent of the person about whom the information is collected."
    },
    "360": {
      "title": "Trust of System Event Data",
      "description": "Security based on event locations are insecure and can be spoofed."
    },
    "362": {
      "title": "Concurrent Execution using Shared Resource with Improper Synchronization ('Race Condition')",
      "description": "The product contains a code sequence that can run concurrently with other code, and the code sequence requires temporary, exclusive access to a shared resource, but a timing window exists in which the shared resource can be modified by another code sequence that is operating concurrently."
    },
    "363": {
      "title": "Race Condition Enabling Link Following",
      "description": "The product checks the status of a file or directory before accessing it, which produces a race condition in which the file can be replaced with a link before the access is performed, causing the product to access the wrong file."
    },
    "364": {
      "title": "Signal Handler Race Condition",
      "description": "The product uses a signal handler that introduces a race condition."
    },
    "365": {
      "title": "DEPRECATED: Race Condition in Switch",
      "description": "This entry has been deprecated. There are no documented cases in which a switch's control expression is evaluated more than once."
    },
    "366": {
      "title": "Race Condition within a Thread",
      "description": "If two threads of execution use a resource simultaneously, there exists the possibility that resources may be used while invalid, in turn making the state of execution undefined."
    },
    "367": {
      "title": "Time-of-check Time-of-use (TOCTOU) Race Condition",
      "description": "The product checks the state of a resource before using that resource, but the resource's state can change between the check and the use in a way that invalidates the results of the check. This can cause the product to perform invalid actions when the resource is in an unexpected state."
    },
    "368": {
      "title": "Context Switching Race Condition",
      "description": "A product performs a series of non-atomic actions to switch between contexts that cross privilege or other security boundaries, but a race condition allows an attacker to modify or misrepresent the product's behavior during the switch."
    },
    "369": {
      "title": "Divide By Zero",
      "description": "The product divides a value by zero."
    },
    "370": {
      "title": "Missing Check for Certificate Revocation after Initial Check",
      "description": "The product does not check the revocation status of a certificate after its initial revocation check, which can cause the product to perform privileged actions even after the certificate is revoked at a later time."
    },
    "372": {
      "title": "Incomplete Internal State Distinction",
      "description": "The product does not properly determine which state it is in, causing it to assume it is in state X when in fact it is in state Y, causing it to perform incorrect operations in a security-relevant manner."
    },
    "373": {
      "title": "DEPRECATED: State Synchronization Error",
      "description": "This entry was deprecated because it overlapped the same concepts as race condition (CWE-362) and Improper Synchronization (CWE-662)."
    },
    "374": {
      "title": "Passing Mutable Objects to an Untrusted Method",
      "description": "The product sends non-cloned mutable data as an argument to a method or function."
    },
    "375": {
      "title": "Returning a Mutable Object to an Untrusted Caller",
      "description": "Sending non-cloned mutable data as a return value may result in that data being altered or deleted by the calling function."
    },
    "377": {
      "title": "Insecure Temporary File",
      "description": "Creating and using insecure temporary files can leave application and system data vulnerable to attack."
    },
    "378": {
      "title": "Creation of Temporary File With Insecure Permissions",
      "description": "Opening temporary files without appropriate measures or controls can leave the file, its contents and any function that it impacts vulnerable to attack."
    },
    "379": {
      "title": "Creation of Temporary File in Directory with Insecure Permissions",
      "description": "The product creates a temporary file in a directory whose permissions allow unintended actors to determine the file's existence or otherwise access that file."
    },
    "382": {
      "title": "J2EE Bad Practices: Use of System.exit()",
      "description": "A J2EE application uses System.exit(), which also shuts down its container."
    },
    "383": {
      "title": "J2EE Bad Practices: Direct Use of Threads",
      "description": "Thread management in a Web application is forbidden in some circumstances and is always highly error prone."
    },
    "384": {
      "title": "Session Fixation",
      "description": "Authenticating a user, or otherwise establishing a new user session, without invalidating any existing session identifier gives an attacker the opportunity to steal authenticated sessions."
    },
    "385": {
      "title": "Covert Timing Channel",
      "description": "Covert timing channels convey information by modulating some aspect of system behavior over time, so that the program receiving the information can observe system behavior and infer protected information."
    },
    "386": {
      "title": "Symbolic Name not Mapping to Correct Object",
      "description": "A constant symbolic reference to an object is used, even though the reference can resolve to a different object over time."
    },
    "390": {
      "title": "Detection of Error Condition Without Action",
      "description": "The product detects a specific error, but takes no actions to handle the error."
    },
    "391": {
      "title": "Unchecked Error Condition",
      "description": "[PLANNED FOR DEPRECATION. SEE MAINTENANCE NOTES AND CONSIDER CWE-252, CWE-248, OR CWE-1069.] Ignoring exceptions and other error conditions may allow an attacker to induce unexpected behavior unnoticed."
    },
    "392": {
      "title": "Missing Report of Error Condition",
      "description": "The product encounters an error but does not provide a status code or return value to indicate that an error has occurred."
    },
    "393": {
      "title": "Return of Wrong Status Code",
      "description": "A function or operation returns an incorrect return value or status code that does not indicate an error, but causes the product to modify its behavior based on the incorrect result."
    },
    "394": {
      "title": "Unexpected Status Code or Return Value",
      "description": "The product does not properly check when a function or operation returns a value that is legitimate for the function, but is not expected by the product."
    },
    "395": {
      "title": "Use of NullPointerException Catch to Detect NULL Pointer Dereference",
      "description": "Catching NullPointerException should not be used as an alternative to programmatic checks to prevent dereferencing a null pointer."
    },
    "396": {
      "title": "Declaration of Catch for Generic Exception",
      "description": "Catching overly broad exceptions promotes complex error handling code that is more likely to contain security vulnerabilities."
    },
    "397": {
      "title": "Declaration of Throws for Generic Exception",
      "description": "Throwing overly broad exceptions promotes complex error handling code that is more likely to contain security vulnerabilities."
    },
    "400": {
      "title": "Uncontrolled Resource Consumption",
      "description": "The product does not properly control the allocation and maintenance of a limited resource, thereby enabling an actor to influence the amount of resources consumed, eventually leading to the exhaustion of available resources."
    },
    "401": {
      "title": "Missing Release of Memory after Effective Lifetime",
      "description": "The product does not sufficiently track and release allocated memory after it has been used, which slowly consumes remaining memory."
    },
    "402": {
      "title": "Transmission of Private Resources into a New Sphere ('Resource Leak')",
      "description": "The product makes resources available to untrusted parties when those resources are only intended to be accessed by the product."
    },
    "403": {
      "title": "Exposure of File Descriptor to Unintended Control Sphere ('File Descriptor Leak')",
      "description": "A process does not close sensitive file descriptors before invoking a child process, which allows the child to perform unauthorized I/O operations using those descriptors."
    },
    "404": {
      "title": "Improper Resource Shutdown or Release",
      "description": "The product does not release or incorrectly releases a resource before it is made available for re-use."
    },
    "405": {
      "title": "Asymmetric Resource Consumption (Amplification)",
      "description": "The product does not properly control situations in which an adversary can cause the product to consume or produce excessive resources without requiring the adversary to invest equivalent work or otherwise prove authorization, i.e., the adversary's influence is \"asymmetric.\""
    },
    "406": {
      "title": "Insufficient Control of Network Message Volume (Network Amplification)",
      "description": "The product does not sufficiently monitor or control transmitted network traffic volume, so that an actor can cause the product to transmit more traffic than should be allowed for that actor."
    },
    "407": {
      "title": "Inefficient Algorithmic Complexity",
      "description": "An algorithm in a product has an inefficient worst-case computational complexity that may be detrimental to system performance and can be triggered by an attacker, typically using crafted manipulations that ensure that the worst case is being reached."
    },
    "408": {
      "title": "Incorrect Behavior Order: Early Amplification",
      "description": "The product allows an entity to perform a legitimate but expensive operation before authentication or authorization has taken place."
    },
    "409": {
      "title": "Improper Handling of Highly Compressed Data (Data Amplification)",
      "description": "The product does not handle or incorrectly handles a compressed input with a very high compression ratio that produces a large output."
    },
    "410": {
      "title": "Insufficient Resource Pool",
      "description": "The product's resource pool is not large enough to handle peak demand, which allows an attacker to prevent others from accessing the resource by using a (relatively) large number of requests for resources."
    },
    "412": {
      "title": "Unrestricted Externally Accessible Lock",
      "description": "The product properly checks for the existence of a lock, but the lock can be externally controlled or influenced by an actor that is outside of the intended sphere of control."
    },
    "413": {
      "title": "Improper Resource Locking",
      "description": "The product does not lock or does not correctly lock a resource when the product must have exclusive access to the resource."
    },
    "414": {
      "title": "Missing Lock Check",
      "description": "A product does not check to see if a lock is present before performing sensitive operations on a resource."
    },
    "415": {
      "title": "Double Free",
      "description": "The product calls free() twice on the same memory address, potentially leading to modification of unexpected memory locations."
    },
    "416": {
      "title": "Use After Free",
      "description": "Referencing memory after it has been freed can cause a program to crash, use unexpected values, or execute code."
    },
    "419": {
      "title": "Unprotected Primary Channel",
      "description": "The product uses a primary channel for administration or restricted functionality, but it does not properly protect the channel."
    },
    "420": {
      "title": "Unprotected Alternate Channel",
      "description": "The product protects a primary channel, but it does not use the same level of protection for an alternate channel."
    },
    "421": {
      "title": "Race Condition During Access to Alternate Channel",
      "description": "The product opens an alternate channel to communicate with an authorized user, but the channel is accessible to other actors."
    },
    "422": {
      "title": "Unprotected Windows Messaging Channel ('Shatter')",
      "description": "The product does not properly verify the source of a message in the Windows Messaging System while running at elevated privileges, creating an alternate channel through which an attacker can directly send a message to the product."
    },
    "423": {
      "title": "DEPRECATED: Proxied Trusted Channel",
      "description": "This entry has been deprecated because it was a duplicate of CWE-441. All content has been transferred to CWE-441."
    },
    "424": {
      "title": "Improper Protection of Alternate Path",
      "description": "The product does not sufficiently protect all possible paths that a user can take to access restricted functionality or resources."
    },
    "425": {
      "title": "Direct Request ('Forced Browsing')",
      "description": "The web application does not adequately enforce appropriate authorization on all restricted URLs, scripts, or files."
    },
    "426": {
      "title": "Untrusted Search Path",
      "description": "The product searches for critical resources using an externally-supplied search path that can point to resources that are not under the product's direct control."
    },
    "427": {
      "title": "Uncontrolled Search Path Element",
      "description": "The product uses a fixed or controlled search path to find resources, but one or more locations in that path can be under the control of unintended actors."
    },
    "428": {
      "title": "Unquoted Search Path or Element",
      "description": "The product uses a search path that contains an unquoted element, in which the element contains whitespace or other separators. This can cause the product to access resources in a parent path."
    },
    "430": {
      "title": "Deployment of Wrong Handler",
      "description": "The wrong \"handler\" is assigned to process an object."
    },
    "431": {
      "title": "Missing Handler",
      "description": "A handler is not available or implemented."
    },
    "432": {
      "title": "Dangerous Signal Handler not Disabled During Sensitive Operations",
      "description": "The product uses a signal handler that shares state with other signal handlers, but it does not properly mask or prevent those signal handlers from being invoked while the original signal handler is still running."
    },
    "433": {
      "title": "Unparsed Raw Web Content Delivery",
      "description": "The product stores raw content or supporting code under the web document root with an extension that is not specifically handled by the server."
    },
    "434": {
      "title": "Unrestricted Upload of File with Dangerous Type",
      "description": "The product allows the attacker to upload or transfer files of dangerous types that can be automatically processed within the product's environment."
    },
    "435": {
      "title": "Improper Interaction Between Multiple Correctly-Behaving Entities",
      "description": "An interaction error occurs when two entities have correct behavior when running independently of each other, but when they are integrated as components in a larger system or process, they introduce incorrect behaviors that may cause resultant weaknesses."
    },
    "436": {
      "title": "Interpretation Conflict",
      "description": "Product A handles inputs or steps differently than Product B, which causes A to perform incorrect actions based on its perception of B's state."
    },
    "437": {
      "title": "Incomplete Model of Endpoint Features",
      "description": "A product acts as an intermediary or monitor between two or more endpoints, but it does not have a complete model of an endpoint's features, behaviors, or state, potentially causing the product to perform incorrect actions based on this incomplete model."
    },
    "439": {
      "title": "Behavioral Change in New Version or Environment",
      "description": "A's behavior or functionality changes with a new version of A, or a new environment, which is not known (or manageable) by B."
    },
    "440": {
      "title": "Expected Behavior Violation",
      "description": "A feature, API, or function does not perform according to its specification."
    },
    "441": {
      "title": "Unintended Proxy or Intermediary ('Confused Deputy')",
      "description": "The product receives a request, message, or directive from an upstream component, but the product does not sufficiently preserve the original source of the request before forwarding the request to an external actor that is outside of the product's control sphere. This causes the product to appear to be the source of the request, leading it to act as a proxy or other intermediary between the upstream component and the external actor."
    },
    "443": {
      "title": "DEPRECATED: HTTP response splitting",
      "description": "This weakness can be found at CWE-113."
    },
    "444": {
      "title": "Inconsistent Interpretation of HTTP Requests ('HTTP Request/Response Smuggling')",
      "description": "The product acts as an intermediary HTTP agent\n         (such as a proxy or firewall) in the data flow between two\n         entities such as a client and server, but it does not\n         interpret malformed HTTP requests or responses in ways that\n         are consistent with how the messages will be processed by\n         those entities that are at the ultimate destination."
    },
    "446": {
      "title": "UI Discrepancy for Security Feature",
      "description": "The user interface does not correctly enable or configure a security feature, but the interface provides feedback that causes the user to believe that the feature is in a secure state."
    },
    "447": {
      "title": "Unimplemented or Unsupported Feature in UI",
      "description": "A UI function for a security feature appears to be supported and gives feedback to the user that suggests that it is supported, but the underlying functionality is not implemented."
    },
    "448": {
      "title": "Obsolete Feature in UI",
      "description": "A UI function is obsolete and the product does not warn the user."
    },
    "449": {
      "title": "The UI Performs the Wrong Action",
      "description": "The UI performs the wrong action with respect to the user's request."
    },
    "450": {
      "title": "Multiple Interpretations of UI Input",
      "description": "The UI has multiple interpretations of user input but does not prompt the user when it selects the less secure interpretation."
    },
    "451": {
      "title": "User Interface (UI) Misrepresentation of Critical Information",
      "description": "The user interface (UI) does not properly represent critical information to the user, allowing the information - or its source - to be obscured or spoofed. This is often a component in phishing attacks."
    },
    "453": {
      "title": "Insecure Default Variable Initialization",
      "description": "The product, by default, initializes an internal variable with an insecure or less secure value than is possible."
    },
    "454": {
      "title": "External Initialization of Trusted Variables or Data Stores",
      "description": "The product initializes critical internal variables or data stores using inputs that can be modified by untrusted actors."
    },
    "455": {
      "title": "Non-exit on Failed Initialization",
      "description": "The product does not exit or otherwise modify its operation when security-relevant errors occur during initialization, such as when a configuration file has a format error or a hardware security module (HSM) cannot be activated, which can cause the product to execute in a less secure fashion than intended by the administrator."
    },
    "456": {
      "title": "Missing Initialization of a Variable",
      "description": "The product does not initialize critical variables, which causes the execution environment to use unexpected values."
    },
    "457": {
      "title": "Use of Uninitialized Variable",
      "description": "The code uses a variable that has not been initialized, leading to unpredictable or unintended results."
    },
    "458": {
      "title": "DEPRECATED: Incorrect Initialization",
      "description": "This weakness has been deprecated because its name and description did not match. The description duplicated CWE-454, while the name suggested a more abstract initialization problem. Please refer to CWE-665 for the more abstract problem."
    },
    "459": {
      "title": "Incomplete Cleanup",
      "description": "The product does not properly \"clean up\" and remove temporary or supporting resources after they have been used."
    },
    "460": {
      "title": "Improper Cleanup on Thrown Exception",
      "description": "The product does not clean up its state or incorrectly cleans up its state when an exception is thrown, leading to unexpected state or control flow."
    },
    "462": {
      "title": "Duplicate Key in Associative List (Alist)",
      "description": "Duplicate keys in associative lists can lead to non-unique keys being mistaken for an error."
    },
    "463": {
      "title": "Deletion of Data Structure Sentinel",
      "description": "The accidental deletion of a data-structure sentinel can cause serious programming logic problems."
    },
    "464": {
      "title": "Addition of Data Structure Sentinel",
      "description": "The accidental addition of a data-structure sentinel can cause serious programming logic problems."
    },
    "466": {
      "title": "Return of Pointer Value Outside of Expected Range",
      "description": "A function can return a pointer to memory that is outside of the buffer that the pointer is expected to reference."
    },
    "467": {
      "title": "Use of sizeof() on a Pointer Type",
      "description": "The code calls sizeof() on a malloced pointer type, which always returns the wordsize/8. This can produce an unexpected result if the programmer intended to determine how much memory has been allocated."
    },
    "468": {
      "title": "Incorrect Pointer Scaling",
      "description": "In C and C++, one may often accidentally refer to the wrong memory due to the semantics of when math operations are implicitly scaled."
    },
    "469": {
      "title": "Use of Pointer Subtraction to Determine Size",
      "description": "The product subtracts one pointer from another in order to determine size, but this calculation can be incorrect if the pointers do not exist in the same memory chunk."
    },
    "470": {
      "title": "Use of Externally-Controlled Input to Select Classes or Code ('Unsafe Reflection')",
      "description": "The product uses external input with reflection to select which classes or code to use, but it does not sufficiently prevent the input from selecting improper classes or code."
    },
    "471": {
      "title": "Modification of Assumed-Immutable Data (MAID)",
      "description": "The product does not properly protect an assumed-immutable element from being modified by an attacker."
    },
    "472": {
      "title": "External Control of Assumed-Immutable Web Parameter",
      "description": "The web application does not sufficiently verify inputs that are assumed to be immutable but are actually externally controllable, such as hidden form fields."
    },
    "473": {
      "title": "PHP External Variable Modification",
      "description": "A PHP application does not properly protect against the modification of variables from external sources, such as query parameters or cookies. This can expose the application to numerous weaknesses that would not exist otherwise."
    },
    "474": {
      "title": "Use of Function with Inconsistent Implementations",
      "description": "The code uses a function that has inconsistent implementations across operating systems and versions."
    },
    "475": {
      "title": "Undefined Behavior for Input to API",
      "description": "The behavior of this function is undefined unless its control parameter is set to a specific value."
    },
    "476": {
      "title": "NULL Pointer Dereference",
      "description": "A NULL pointer dereference occurs when the application dereferences a pointer that it expects to be valid, but is NULL, typically causing a crash or exit."
    },
    "477": {
      "title": "Use of Obsolete Function",
      "description": "The code uses deprecated or obsolete functions, which suggests that the code has not been actively reviewed or maintained."
    },
    "478": {
      "title": "Missing Default Case in Multiple Condition Expression",
      "description": "The code does not have a default case in an expression with multiple conditions, such as a switch statement."
    },
    "479": {
      "title": "Signal Handler Use of a Non-reentrant Function",
      "description": "The product defines a signal handler that calls a non-reentrant function."
    },
    "480": {
      "title": "Use of Incorrect Operator",
      "description": "The product accidentally uses the wrong operator, which changes the logic in security-relevant ways."
    },
    "481": {
      "title": "Assigning instead of Comparing",
      "description": "The code uses an operator for assignment when the intention was to perform a comparison."
    },
    "482": {
      "title": "Comparing instead of Assigning",
      "description": "The code uses an operator for comparison when the intention was to perform an assignment."
    },
    "483": {
      "title": "Incorrect Block Delimitation",
      "description": "The code does not explicitly delimit a block that is intended to contain 2 or more statements, creating a logic error."
    },
    "484": {
      "title": "Omitted Break Statement in Switch",
      "description": "The product omits a break statement within a switch or similar construct, causing code associated with multiple conditions to execute. This can cause problems when the programmer only intended to execute code associated with one condition."
    },
    "486": {
      "title": "Comparison of Classes by Name",
      "description": "The product compares classes by name, which can cause it to use the wrong class when multiple classes can have the same name."
    },
    "487": {
      "title": "Reliance on Package-level Scope",
      "description": "Java packages are not inherently closed; therefore, relying on them for code security is not a good practice."
    },
    "488": {
      "title": "Exposure of Data Element to Wrong Session",
      "description": "The product does not sufficiently enforce boundaries between the states of different sessions, causing data to be provided to, or used by, the wrong session."
    },
    "489": {
      "title": "Active Debug Code",
      "description": "The product is deployed to unauthorized actors with debugging code still enabled or active, which can create unintended entry points or expose sensitive information."
    },
    "491": {
      "title": "Public cloneable() Method Without Final ('Object Hijack')",
      "description": "A class has a cloneable() method that is not declared final, which allows an object to be created without calling the constructor. This can cause the object to be in an unexpected state."
    },
    "492": {
      "title": "Use of Inner Class Containing Sensitive Data",
      "description": "Inner classes are translated into classes that are accessible at package scope and may expose code that the programmer intended to keep private to attackers."
    },
    "493": {
      "title": "Critical Public Variable Without Final Modifier",
      "description": "The product has a critical public variable that is not final, which allows the variable to be modified to contain unexpected values."
    },
    "494": {
      "title": "Download of Code Without Integrity Check",
      "description": "The product downloads source code or an executable from a remote location and executes the code without sufficiently verifying the origin and integrity of the code."
    },
    "495": {
      "title": "Private Data Structure Returned From A Public Method",
      "description": "The product has a method that is declared public, but returns a reference to a private data structure, which could then be modified in unexpected ways."
    },
    "496": {
      "title": "Public Data Assigned to Private Array-Typed Field",
      "description": "Assigning public data to a private array is equivalent to giving public access to the array."
    },
    "497": {
      "title": "Exposure of Sensitive System Information to an Unauthorized Control Sphere",
      "description": "The product does not properly prevent sensitive system-level information from being accessed by unauthorized actors who do not have the same level of access to the underlying system as the product does."
    },
    "498": {
      "title": "Cloneable Class Containing Sensitive Information",
      "description": "The code contains a class with sensitive data, but the class is cloneable. The data can then be accessed by cloning the class."
    },
    "499": {
      "title": "Serializable Class Containing Sensitive Data",
      "description": "The code contains a class with sensitive data, but the class does not explicitly deny serialization. The data can be accessed by serializing the class through another class."
    },
    "500": {
      "title": "Public Static Field Not Marked Final",
      "description": "An object contains a public static field that is not marked final, which might allow it to be modified in unexpected ways."
    },
    "501": {
      "title": "Trust Boundary Violation",
      "description": "The product mixes trusted and untrusted data in the same data structure or structured message."
    },
    "502": {
      "title": "Deserialization of Untrusted Data",
      "description": "The product deserializes untrusted data without sufficiently verifying that the resulting data will be valid."
    },
    "506": {
      "title": "Embedded Malicious Code",
      "description": "The product contains code that appears to be malicious in nature."
    },
    "507": {
      "title": "Trojan Horse",
      "description": "The product appears to contain benign or useful functionality, but it also contains code that is hidden from normal operation that violates the intended security policy of the user or the system administrator."
    },
    "508": {
      "title": "Non-Replicating Malicious Code",
      "description": "Non-replicating malicious code only resides on the target system or product that is attacked; it does not attempt to spread to other systems."
    },
    "509": {
      "title": "Replicating Malicious Code (Virus or Worm)",
      "description": "Replicating malicious code, including viruses and worms, will attempt to attack other systems once it has successfully compromised the target system or the product."
    },
    "510": {
      "title": "Trapdoor",
      "description": "A trapdoor is a hidden piece of code that responds to a special input, allowing its user access to resources without passing through the normal security enforcement mechanism."
    },
    "511": {
      "title": "Logic/Time Bomb",
      "description": "The product contains code that is designed to disrupt the legitimate operation of the product (or its environment) when a certain time passes, or when a certain logical condition is met."
    },
    "512": {
      "title": "Spyware",
      "description": "The product collects personally identifiable information about a human user or the user's activities, but the product accesses this information using other resources besides itself, and it does not require that user's explicit approval or direct input into the product."
    },
    "514": {
      "title": "Covert Channel",
      "description": "A covert channel is a path that can be used to transfer information in a way not intended by the system's designers."
    },
    "515": {
      "title": "Covert Storage Channel",
      "description": "A covert storage channel transfers information through the setting of bits by one program and the reading of those bits by another. What distinguishes this case from that of ordinary operation is that the bits are used to convey encoded information."
    },
    "516": {
      "title": "DEPRECATED: Covert Timing Channel",
      "description": "This weakness can be found at CWE-385."
    },
    "520": {
      "title": ".NET Misconfiguration: Use of Impersonation",
      "description": "Allowing a .NET application to run at potentially escalated levels of access to the underlying operating and file systems can be dangerous and result in various forms of attacks."
    },
    "521": {
      "title": "Weak Password Requirements",
      "description": "The product does not require that users should have strong passwords, which makes it easier for attackers to compromise user accounts."
    },
    "522": {
      "title": "Insufficiently Protected Credentials",
      "description": "The product transmits or stores authentication credentials, but it uses an insecure method that is susceptible to unauthorized interception and/or retrieval."
    },
    "523": {
      "title": "Unprotected Transport of Credentials",
      "description": "Login pages do not use adequate measures to protect the user name and password while they are in transit from the client to the server."
    },
    "524": {
      "title": "Use of Cache Containing Sensitive Information",
      "description": "The code uses a cache that contains sensitive information, but the cache can be read by an actor outside of the intended control sphere."
    },
    "525": {
      "title": "Use of Web Browser Cache Containing Sensitive Information",
      "description": "The web application does not use an appropriate caching policy that specifies the extent to which each web page and associated form fields should be cached."
    },
    "526": {
      "title": "Cleartext Storage of Sensitive Information in an Environment Variable",
      "description": "The product uses an environment variable to store unencrypted sensitive information."
    },
    "527": {
      "title": "Exposure of Version-Control Repository to an Unauthorized Control Sphere",
      "description": "The product stores a CVS, git, or other repository in a directory, archive, or other resource that is stored, transferred, or otherwise made accessible to unauthorized actors."
    },
    "528": {
      "title": "Exposure of Core Dump File to an Unauthorized Control Sphere",
      "description": "The product generates a core dump file in a directory, archive, or other resource that is stored, transferred, or otherwise made accessible to unauthorized actors."
    },
    "529": {
      "title": "Exposure of Access Control List Files to an Unauthorized Control Sphere",
      "description": "The product stores access control list files in a directory or other container that is accessible to actors outside of the intended control sphere."
    },
    "530": {
      "title": "Exposure of Backup File to an Unauthorized Control Sphere",
      "description": "A backup file is stored in a directory or archive that is made accessible to unauthorized actors."
    },
    "531": {
      "title": "Inclusion of Sensitive Information in Test Code",
      "description": "Accessible test applications can pose a variety of security risks. Since developers or administrators rarely consider that someone besides themselves would even know about the existence of these applications, it is common for them to contain sensitive information or functions."
    },
    "532": {
      "title": "Insertion of Sensitive Information into Log File",
      "description": "Information written to log files can be of a sensitive nature and give valuable guidance to an attacker or expose sensitive user information."
    },
    "533": {
      "title": "DEPRECATED: Information Exposure Through Server Log Files",
      "description": "This entry has been deprecated because its abstraction was too low-level.  See CWE-532."
    },
    "534": {
      "title": "DEPRECATED: Information Exposure Through Debug Log Files",
      "description": "This entry has been deprecated because its abstraction was too low-level.  See CWE-532."
    },
    "535": {
      "title": "Exposure of Information Through Shell Error Message",
      "description": "A command shell error message indicates that there exists an unhandled exception in the web application code. In many cases, an attacker can leverage the conditions that cause these errors in order to gain unauthorized access to the system."
    },
    "536": {
      "title": "Servlet Runtime Error Message Containing Sensitive Information",
      "description": "A servlet error message indicates that there exists an unhandled exception in your web application code and may provide useful information to an attacker."
    },
    "537": {
      "title": "Java Runtime Error Message Containing Sensitive Information",
      "description": "In many cases, an attacker can leverage the conditions that cause unhandled exception errors in order to gain unauthorized access to the system."
    },
    "538": {
      "title": "Insertion of Sensitive Information into Externally-Accessible File or Directory",
      "description": "The product places sensitive information into files or directories that are accessible to actors who are allowed to have access to the files, but not to the sensitive information."
    },
    "539": {
      "title": "Use of Persistent Cookies Containing Sensitive Information",
      "description": "The web application uses persistent cookies, but the cookies contain sensitive information."
    },
    "540": {
      "title": "Inclusion of Sensitive Information in Source Code",
      "description": "Source code on a web server or repository often contains sensitive information and should generally not be accessible to users."
    },
    "541": {
      "title": "Inclusion of Sensitive Information in an Include File",
      "description": "If an include file source is accessible, the file can contain usernames and passwords, as well as sensitive information pertaining to the application and system."
    },
    "542": {
      "title": "DEPRECATED: Information Exposure Through Cleanup Log Files",
      "description": "This entry has been deprecated because its abstraction was too low-level.  See CWE-532."
    },
    "543": {
      "title": "Use of Singleton Pattern Without Synchronization in a Multithreaded Context",
      "description": "The product uses the singleton pattern when creating a resource within a multithreaded environment."
    },
    "544": {
      "title": "Missing Standardized Error Handling Mechanism",
      "description": "The product does not use a standardized method for handling errors throughout the code, which might introduce inconsistent error handling and resultant weaknesses."
    },
    "545": {
      "title": "DEPRECATED: Use of Dynamic Class Loading",
      "description": "This weakness has been deprecated because it partially overlaps CWE-470, it describes legitimate programmer behavior, and other portions will need to be integrated into other entries."
    },
    "546": {
      "title": "Suspicious Comment",
      "description": "The code contains comments that suggest the presence of bugs, incomplete functionality, or weaknesses."
    },
    "547": {
      "title": "Use of Hard-coded, Security-relevant Constants",
      "description": "The product uses hard-coded constants instead of symbolic names for security-critical values, which increases the likelihood of mistakes during code maintenance or security policy change."
    },
    "548": {
      "title": "Exposure of Information Through Directory Listing",
      "description": "A directory listing is inappropriately exposed, yielding potentially sensitive information to attackers."
    },
    "549": {
      "title": "Missing Password Field Masking",
      "description": "The product does not mask passwords during entry, increasing the potential for attackers to observe and capture passwords."
    },
    "550": {
      "title": "Server-generated Error Message Containing Sensitive Information",
      "description": "Certain conditions, such as network failure, will cause a server error message to be displayed."
    },
    "551": {
      "title": "Incorrect Behavior Order: Authorization Before Parsing and Canonicalization",
      "description": "If a web server does not fully parse requested URLs before it examines them for authorization, it may be possible for an attacker to bypass authorization protection."
    },
    "552": {
      "title": "Files or Directories Accessible to External Parties",
      "description": "The product makes files or directories accessible to unauthorized actors, even though they should not be."
    },
    "553": {
      "title": "Command Shell in Externally Accessible Directory",
      "description": "A possible shell file exists in /cgi-bin/ or other accessible directories. This is extremely dangerous and can be used by an attacker to execute commands on the web server."
    },
    "554": {
      "title": "ASP.NET Misconfiguration: Not Using Input Validation Framework",
      "description": "The ASP.NET application does not use an input validation framework."
    },
    "555": {
      "title": "J2EE Misconfiguration: Plaintext Password in Configuration File",
      "description": "The J2EE application stores a plaintext password in a configuration file."
    },
    "556": {
      "title": "ASP.NET Misconfiguration: Use of Identity Impersonation",
      "description": "Configuring an ASP.NET application to run with impersonated credentials may give the application unnecessary privileges."
    },
    "558": {
      "title": "Use of getlogin() in Multithreaded Application",
      "description": "The product uses the getlogin() function in a multithreaded context, potentially causing it to return incorrect values."
    },
    "560": {
      "title": "Use of umask() with chmod-style Argument",
      "description": "The product calls umask() with an incorrect argument that is specified as if it is an argument to chmod()."
    },
    "561": {
      "title": "Dead Code",
      "description": "The product contains dead code, which can never be executed."
    },
    "562": {
      "title": "Return of Stack Variable Address",
      "description": "A function returns the address of a stack variable, which will cause unintended program behavior, typically in the form of a crash."
    },
    "563": {
      "title": "Assignment to Variable without Use",
      "description": "The variable's value is assigned but never used, making it a dead store."
    },
    "564": {
      "title": "SQL Injection: Hibernate",
      "description": "Using Hibernate to execute a dynamic SQL statement built with user-controlled input can allow an attacker to modify the statement's meaning or to execute arbitrary SQL commands."
    },
    "565": {
      "title": "Reliance on Cookies without Validation and Integrity Checking",
      "description": "The product relies on the existence or values of cookies when performing security-critical operations, but it does not properly ensure that the setting is valid for the associated user."
    },
    "566": {
      "title": "Authorization Bypass Through User-Controlled SQL Primary Key",
      "description": "The product uses a database table that includes records that should not be accessible to an actor, but it executes a SQL statement with a primary key that can be controlled by that actor."
    },
    "567": {
      "title": "Unsynchronized Access to Shared Data in a Multithreaded Context",
      "description": "The product does not properly synchronize shared data, such as static variables across threads, which can lead to undefined behavior and unpredictable data changes."
    },
    "568": {
      "title": "finalize() Method Without super.finalize()",
      "description": "The product contains a finalize() method that does not call super.finalize()."
    },
    "570": {
      "title": "Expression is Always False",
      "description": "The product contains an expression that will always evaluate to false."
    },
    "571": {
      "title": "Expression is Always True",
      "description": "The product contains an expression that will always evaluate to true."
    },
    "572": {
      "title": "Call to Thread run() instead of start()",
      "description": "The product calls a thread's run() method instead of calling start(), which causes the code to run in the thread of the caller instead of the callee."
    },
    "573": {
      "title": "Improper Following of Specification by Caller",
      "description": "The product does not follow or incorrectly follows the specifications as required by the implementation language, environment, framework, protocol, or platform."
    },
    "574": {
      "title": "EJB Bad Practices: Use of Synchronization Primitives",
      "description": "The product violates the Enterprise JavaBeans (EJB) specification by using thread synchronization primitives."
    },
    "575": {
      "title": "EJB Bad Practices: Use of AWT Swing",
      "description": "The product violates the Enterprise JavaBeans (EJB) specification by using AWT/Swing."
    },
    "576": {
      "title": "EJB Bad Practices: Use of Java I/O",
      "description": "The product violates the Enterprise JavaBeans (EJB) specification by using the java.io package."
    },
    "577": {
      "title": "EJB Bad Practices: Use of Sockets",
      "description": "The product violates the Enterprise JavaBeans (EJB) specification by using sockets."
    },
    "578": {
      "title": "EJB Bad Practices: Use of Class Loader",
      "description": "The product violates the Enterprise JavaBeans (EJB) specification by using the class loader."
    },
    "579": {
      "title": "J2EE Bad Practices: Non-serializable Object Stored in Session",
      "description": "The product stores a non-serializable object as an HttpSession attribute, which can hurt reliability."
    },
    "580": {
      "title": "clone() Method Without super.clone()",
      "description": "The product contains a clone() method that does not call super.clone() to obtain the new object."
    },
    "581": {
      "title": "Object Model Violation: Just One of Equals and Hashcode Defined",
      "description": "The product does not maintain equal hashcodes for equal objects."
    },
    "582": {
      "title": "Array Declared Public, Final, and Static",
      "description": "The product declares an array public, final, and static, which is not sufficient to prevent the array's contents from being modified."
    },
    "583": {
      "title": "finalize() Method Declared Public",
      "description": "The product violates secure coding principles for mobile code by declaring a finalize() method public."
    },
    "584": {
      "title": "Return Inside Finally Block",
      "description": "The code has a return statement inside a finally block, which will cause any thrown exception in the try block to be discarded."
    },
    "585": {
      "title": "Empty Synchronized Block",
      "description": "The product contains an empty synchronized block."
    },
    "586": {
      "title": "Explicit Call to Finalize()",
      "description": "The product makes an explicit call to the finalize() method from outside the finalizer."
    },
    "587": {
      "title": "Assignment of a Fixed Address to a Pointer",
      "description": "The product sets a pointer to a specific address other than NULL or 0."
    },
    "588": {
      "title": "Attempt to Access Child of a Non-structure Pointer",
      "description": "Casting a non-structure type to a structure type and accessing a field can lead to memory access errors or data corruption."
    },
    "589": {
      "title": "Call to Non-ubiquitous API",
      "description": "The product uses an API function that does not exist on all versions of the target platform. This could cause portability problems or inconsistencies that allow denial of service or other consequences."
    },
    "590": {
      "title": "Free of Memory not on the Heap",
      "description": "The product calls free() on a pointer to memory that was not allocated using associated heap allocation functions such as malloc(), calloc(), or realloc()."
    },
    "591": {
      "title": "Sensitive Data Storage in Improperly Locked Memory",
      "description": "The product stores sensitive data in memory that is not locked, or that has been incorrectly locked, which might cause the memory to be written to swap files on disk by the virtual memory manager. This can make the data more accessible to external actors."
    },
    "592": {
      "title": "DEPRECATED: Authentication Bypass Issues",
      "description": "This weakness has been deprecated because it covered redundant concepts already described in CWE-287."
    },
    "593": {
      "title": "Authentication Bypass: OpenSSL CTX Object Modified after SSL Objects are Created",
      "description": "The product modifies the SSL context after connection creation has begun."
    },
    "594": {
      "title": "J2EE Framework: Saving Unserializable Objects to Disk",
      "description": "When the J2EE container attempts to write unserializable objects to disk there is no guarantee that the process will complete successfully."
    },
    "595": {
      "title": "Comparison of Object References Instead of Object Contents",
      "description": "The product compares object references instead of the contents of the objects themselves, preventing it from detecting equivalent objects."
    },
    "596": {
      "title": "DEPRECATED: Incorrect Semantic Object Comparison",
      "description": "This weakness has been deprecated.  It was poorly described and difficult to distinguish from other entries.  It was also inappropriate to assign a separate ID solely because of domain-specific considerations.  Its closest equivalent is CWE-1023."
    },
    "597": {
      "title": "Use of Wrong Operator in String Comparison",
      "description": "The product uses the wrong operator when comparing a string, such as using \"==\" when the .equals() method should be used instead."
    },
    "598": {
      "title": "Use of GET Request Method With Sensitive Query Strings",
      "description": "The web application uses the HTTP GET method to process a request and includes sensitive information in the query string of that request."
    },
    "599": {
      "title": "Missing Validation of OpenSSL Certificate",
      "description": "The product uses OpenSSL and trusts or uses a certificate without using the SSL_get_verify_result() function to ensure that the certificate satisfies all necessary security requirements."
    },
    "600": {
      "title": "Uncaught Exception in Servlet ",
      "description": "The Servlet does not catch all exceptions, which may reveal sensitive debugging information."
    },
    "601": {
      "title": "URL Redirection to Untrusted Site ('Open Redirect')",
      "description": "A web application accepts a user-controlled input that specifies a link to an external site, and uses that link in a Redirect. This simplifies phishing attacks."
    },
    "602": {
      "title": "Client-Side Enforcement of Server-Side Security",
      "description": "The product is composed of a server that relies on the client to implement a mechanism that is intended to protect the server."
    },
    "603": {
      "title": "Use of Client-Side Authentication",
      "description": "A client/server product performs authentication within client code but not in server code, allowing server-side authentication to be bypassed via a modified client that omits the authentication check."
    },
    "605": {
      "title": "Multiple Binds to the Same Port",
      "description": "When multiple sockets are allowed to bind to the same port, other services on that port may be stolen or spoofed."
    },
    "606": {
      "title": "Unchecked Input for Loop Condition",
      "description": "The product does not properly check inputs that are used for loop conditions, potentially leading to a denial of service or other consequences because of excessive looping."
    },
    "607": {
      "title": "Public Static Final Field References Mutable Object",
      "description": "A public or protected static final field references a mutable object, which allows the object to be changed by malicious code, or accidentally from another package."
    },
    "608": {
      "title": "Struts: Non-private Field in ActionForm Class",
      "description": "An ActionForm class contains a field that has not been declared private, which can be accessed without using a setter or getter."
    },
    "609": {
      "title": "Double-Checked Locking",
      "description": "The product uses double-checked locking to access a resource without the overhead of explicit synchronization, but the locking is insufficient."
    },
    "610": {
      "title": "Externally Controlled Reference to a Resource in Another Sphere",
      "description": "The product uses an externally controlled name or reference that resolves to a resource that is outside of the intended control sphere."
    },
    "611": {
      "title": "Improper Restriction of XML External Entity Reference",
      "description": "The product processes an XML document that can contain XML entities with URIs that resolve to documents outside of the intended sphere of control, causing the product to embed incorrect documents into its output."
    },
    "612": {
      "title": "Improper Authorization of Index Containing Sensitive Information",
      "description": "The product creates a search index of private or sensitive documents, but it does not properly limit index access to actors who are authorized to see the original information."
    },
    "613": {
      "title": "Insufficient Session Expiration",
      "description": "According to WASC, \"Insufficient Session Expiration is when a web site permits an attacker to reuse old session credentials or session IDs for authorization.\""
    },
    "614": {
      "title": "Sensitive Cookie in HTTPS Session Without 'Secure' Attribute",
      "description": "The Secure attribute for sensitive cookies in HTTPS sessions is not set, which could cause the user agent to send those cookies in plaintext over an HTTP session."
    },
    "615": {
      "title": "Inclusion of Sensitive Information in Source Code Comments",
      "description": "While adding general comments is very useful, some programmers tend to leave important data, such as: filenames related to the web application, old links or links which were not meant to be browsed by users, old code fragments, etc."
    },
    "616": {
      "title": "Incomplete Identification of Uploaded File Variables (PHP)",
      "description": "The PHP application uses an old method for processing uploaded files by referencing the four global variables that are set for each file (e.g. $varname, $varname_size, $varname_name, $varname_type). These variables could be overwritten by attackers, causing the application to process unauthorized files."
    },
    "617": {
      "title": "Reachable Assertion",
      "description": "The product contains an assert() or similar statement that can be triggered by an attacker, which leads to an application exit or other behavior that is more severe than necessary."
    },
    "618": {
      "title": "Exposed Unsafe ActiveX Method",
      "description": "An ActiveX control is intended for use in a web browser, but it exposes dangerous methods that perform actions that are outside of the browser's security model (e.g. the zone or domain)."
    },
    "619": {
      "title": "Dangling Database Cursor ('Cursor Injection')",
      "description": "If a database cursor is not closed properly, then it could become accessible to other users while retaining the same privileges that were originally assigned, leaving the cursor \"dangling.\""
    },
    "620": {
      "title": "Unverified Password Change",
      "description": "When setting a new password for a user, the product does not require knowledge of the original password, or using another form of authentication."
    },
    "621": {
      "title": "Variable Extraction Error",
      "description": "The product uses external input to determine the names of variables into which information is extracted, without verifying that the names of the specified variables are valid. This could cause the program to overwrite unintended variables."
    },
    "622": {
      "title": "Improper Validation of Function Hook Arguments",
      "description": "The product adds hooks to user-accessible API functions, but it does not properly validate the arguments. This could lead to resultant vulnerabilities."
    },
    "623": {
      "title": "Unsafe ActiveX Control Marked Safe For Scripting",
      "description": "An ActiveX control is intended for restricted use, but it has been marked as safe-for-scripting."
    },
    "624": {
      "title": "Executable Regular Expression Error",
      "description": "The product uses a regular expression that either (1) contains an executable component with user-controlled inputs, or (2) allows a user to enable execution by inserting pattern modifiers."
    },
    "625": {
      "title": "Permissive Regular Expression",
      "description": "The product uses a regular expression that does not sufficiently restrict the set of allowed values."
    },
    "626": {
      "title": "Null Byte Interaction Error (Poison Null Byte)",
      "description": "The product does not properly handle null bytes or NUL characters when passing data between different representations or components."
    },
    "627": {
      "title": "Dynamic Variable Evaluation",
      "description": "In a language where the user can influence the name of a variable at runtime, if the variable names are not controlled, an attacker can read or write to arbitrary variables, or access arbitrary functions."
    },
    "628": {
      "title": "Function Call with Incorrectly Specified Arguments",
      "description": "The product calls a function, procedure, or routine with arguments that are not correctly specified, leading to always-incorrect behavior and resultant weaknesses."
    },
    "636": {
      "title": "Not Failing Securely ('Failing Open')",
      "description": "When the product encounters an error condition or failure, its design requires it to fall back to a state that is less secure than other options that are available, such as selecting the weakest encryption algorithm or using the most permissive access control restrictions."
    },
    "637": {
      "title": "Unnecessary Complexity in Protection Mechanism (Not Using 'Economy of Mechanism')",
      "description": "The product uses a more complex mechanism than necessary, which could lead to resultant weaknesses when the mechanism is not correctly understood, modeled, configured, implemented, or used."
    },
    "638": {
      "title": "Not Using Complete Mediation",
      "description": "The product does not perform access checks on a resource every time the resource is accessed by an entity, which can create resultant weaknesses if that entity's rights or privileges change over time."
    },
    "639": {
      "title": "Authorization Bypass Through User-Controlled Key",
      "description": "The system's authorization functionality does not prevent one user from gaining access to another user's data or record by modifying the key value identifying the data."
    },
    "640": {
      "title": "Weak Password Recovery Mechanism for Forgotten Password",
      "description": "The product contains a mechanism for users to recover or change their passwords without knowing the original password, but the mechanism is weak."
    },
    "641": {
      "title": "Improper Restriction of Names for Files and Other Resources",
      "description": "The product constructs the name of a file or other resource using input from an upstream component, but it does not restrict or incorrectly restricts the resulting name."
    },
    "642": {
      "title": "External Control of Critical State Data",
      "description": "The product stores security-critical state information about its users, or the product itself, in a location that is accessible to unauthorized actors."
    },
    "643": {
      "title": "Improper Neutralization of Data within XPath Expressions ('XPath Injection')",
      "description": "The product uses external input to dynamically construct an XPath expression used to retrieve data from an XML database, but it does not neutralize or incorrectly neutralizes that input. This allows an attacker to control the structure of the query."
    },
    "644": {
      "title": "Improper Neutralization of HTTP Headers for Scripting Syntax",
      "description": "The product does not neutralize or incorrectly neutralizes web scripting syntax in HTTP headers that can be used by web browser components that can process raw headers, such as Flash."
    },
    "645": {
      "title": "Overly Restrictive Account Lockout Mechanism",
      "description": "The product contains an account lockout protection mechanism, but the mechanism is too restrictive and can be triggered too easily, which allows attackers to deny service to legitimate users by causing their accounts to be locked out."
    },
    "646": {
      "title": "Reliance on File Name or Extension of Externally-Supplied File",
      "description": "The product allows a file to be uploaded, but it relies on the file name or extension of the file to determine the appropriate behaviors. This could be used by attackers to cause the file to be misclassified and processed in a dangerous fashion."
    },
    "647": {
      "title": "Use of Non-Canonical URL Paths for Authorization Decisions",
      "description": "The product defines policy namespaces and makes authorization decisions based on the assumption that a URL is canonical. This can allow a non-canonical URL to bypass the authorization."
    },
    "648": {
      "title": "Incorrect Use of Privileged APIs",
      "description": "The product does not conform to the API requirements for a function call that requires extra privileges. This could allow attackers to gain privileges by causing the function to be called incorrectly."
    },
    "649": {
      "title": "Reliance on Obfuscation or Encryption of Security-Relevant Inputs without Integrity Checking",
      "description": "The product uses obfuscation or encryption of inputs that should not be mutable by an external actor, but the product does not use integrity checks to detect if those inputs have been modified."
    },
    "650": {
      "title": "Trusting HTTP Permission Methods on the Server Side",
      "description": "The server contains a protection mechanism that assumes that any URI that is accessed using HTTP GET will not cause a state change to the associated resource. This might allow attackers to bypass intended access restrictions and conduct resource modification and deletion attacks, since some applications allow GET to modify state."
    },
    "651": {
      "title": "Exposure of WSDL File Containing Sensitive Information",
      "description": "The Web services architecture may require exposing a Web Service Definition Language (WSDL) file that contains information on the publicly accessible services and how callers of these services should interact with them (e.g. what parameters they expect and what types they return)."
    },
    "652": {
      "title": "Improper Neutralization of Data within XQuery Expressions ('XQuery Injection')",
      "description": "The product uses external input to dynamically construct an XQuery expression used to retrieve data from an XML database, but it does not neutralize or incorrectly neutralizes that input. This allows an attacker to control the structure of the query."
    },
    "653": {
      "title": "Improper Isolation or Compartmentalization",
      "description": "The product does not properly compartmentalize or isolate functionality, processes, or resources that require different privilege levels, rights, or permissions."
    },
    "654": {
      "title": "Reliance on a Single Factor in a Security Decision",
      "description": "A protection mechanism relies exclusively, or to a large extent, on the evaluation of a single condition or the integrity of a single object or entity in order to make a decision about granting access to restricted resources or functionality."
    },
    "655": {
      "title": "Insufficient Psychological Acceptability",
      "description": "The product has a protection mechanism that is too difficult or inconvenient to use, encouraging non-malicious users to disable or bypass the mechanism, whether by accident or on purpose."
    },
    "656": {
      "title": "Reliance on Security Through Obscurity",
      "description": "The product uses a protection mechanism whose strength depends heavily on its obscurity, such that knowledge of its algorithms or key data is sufficient to defeat the mechanism."
    },
    "657": {
      "title": "Violation of Secure Design Principles",
      "description": "The product violates well-established principles for secure design."
    },
    "662": {
      "title": "Improper Synchronization",
      "description": "The product utilizes multiple threads or processes to allow temporary access to a shared resource that can only be exclusive to one process at a time, but it does not properly synchronize these actions, which might cause simultaneous accesses of this resource by multiple threads or processes."
    },
    "663": {
      "title": "Use of a Non-reentrant Function in a Concurrent Context",
      "description": "The product calls a non-reentrant function in a concurrent context in which a competing code sequence (e.g. thread or signal handler) may have an opportunity to call the same function or otherwise influence its state."
    },
    "664": {
      "title": "Improper Control of a Resource Through its Lifetime",
      "description": "The product does not maintain or incorrectly maintains control over a resource throughout its lifetime of creation, use, and release."
    },
    "665": {
      "title": "Improper Initialization",
      "description": "The product does not initialize or incorrectly initializes a resource, which might leave the resource in an unexpected state when it is accessed or used."
    },
    "666": {
      "title": "Operation on Resource in Wrong Phase of Lifetime",
      "description": "The product performs an operation on a resource at the wrong phase of the resource's lifecycle, which can lead to unexpected behaviors."
    },
    "667": {
      "title": "Improper Locking",
      "description": "The product does not properly acquire or release a lock on a resource, leading to unexpected resource state changes and behaviors."
    },
    "668": {
      "title": "Exposure of Resource to Wrong Sphere",
      "description": "The product exposes a resource to the wrong control sphere, providing unintended actors with inappropriate access to the resource."
    },
    "669": {
      "title": "Incorrect Resource Transfer Between Spheres",
      "description": "The product does not properly transfer a resource/behavior to another sphere, or improperly imports a resource/behavior from another sphere, in a manner that provides unintended control over that resource."
    },
    "670": {
      "title": "Always-Incorrect Control Flow Implementation",
      "description": "The code contains a control flow path that does not reflect the algorithm that the path is intended to implement, leading to incorrect behavior any time this path is navigated."
    },
    "671": {
      "title": "Lack of Administrator Control over Security",
      "description": "The product uses security features in a way that prevents the product's administrator from tailoring security settings to reflect the environment in which the product is being used. This introduces resultant weaknesses or prevents it from operating at a level of security that is desired by the administrator."
    },
    "672": {
      "title": "Operation on a Resource after Expiration or Release",
      "description": "The product uses, accesses, or otherwise operates on a resource after that resource has been expired, released, or revoked."
    },
    "673": {
      "title": "External Influence of Sphere Definition",
      "description": "The product does not prevent the definition of control spheres from external actors."
    },
    "674": {
      "title": "Uncontrolled Recursion",
      "description": "The product does not properly control the amount of recursion that takes place,  consuming excessive resources, such as allocated memory or the program stack."
    },
    "675": {
      "title": "Multiple Operations on Resource in Single-Operation Context",
      "description": "The product performs the same operation on a resource two or more times, when the operation should only be applied once."
    },
    "676": {
      "title": "Use of Potentially Dangerous Function",
      "description": "The product invokes a potentially dangerous function that could introduce a vulnerability if it is used incorrectly, but the function can also be used safely."
    },
    "680": {
      "title": "Integer Overflow to Buffer Overflow",
      "description": "The product performs a calculation to determine how much memory to allocate, but an integer overflow can occur that causes less memory to be allocated than expected, leading to a buffer overflow."
    },
    "681": {
      "title": "Incorrect Conversion between Numeric Types",
      "description": "When converting from one data type to another, such as long to integer, data can be omitted or translated in a way that produces unexpected values. If the resulting values are used in a sensitive context, then dangerous behaviors may occur."
    },
    "682": {
      "title": "Incorrect Calculation",
      "description": "The product performs a calculation that generates incorrect or unintended results that are later used in security-critical decisions or resource management."
    },
    "683": {
      "title": "Function Call With Incorrect Order of Arguments",
      "description": "The product calls a function, procedure, or routine, but the caller specifies the arguments in an incorrect order, leading to resultant weaknesses."
    },
    "684": {
      "title": "Incorrect Provision of Specified Functionality",
      "description": "The code does not function according to its published specifications, potentially leading to incorrect usage."
    },
    "685": {
      "title": "Function Call With Incorrect Number of Arguments",
      "description": "The product calls a function, procedure, or routine, but the caller specifies too many arguments, or too few arguments, which may lead to undefined behavior and resultant weaknesses."
    },
    "686": {
      "title": "Function Call With Incorrect Argument Type",
      "description": "The product calls a function, procedure, or routine, but the caller specifies an argument that is the wrong data type, which may lead to resultant weaknesses."
    },
    "687": {
      "title": "Function Call With Incorrectly Specified Argument Value",
      "description": "The product calls a function, procedure, or routine, but the caller specifies an argument that contains the wrong value, which may lead to resultant weaknesses."
    },
    "688": {
      "title": "Function Call With Incorrect Variable or Reference as Argument",
      "description": "The product calls a function, procedure, or routine, but the caller specifies the wrong variable or reference as one of the arguments, which may lead to undefined behavior and resultant weaknesses."
    },
    "689": {
      "title": "Permission Race Condition During Resource Copy",
      "description": "The product, while copying or cloning a resource, does not set the resource's permissions or access control until the copy is complete, leaving the resource exposed to other spheres while the copy is taking place."
    },
    "690": {
      "title": "Unchecked Return Value to NULL Pointer Dereference",
      "description": "The product does not check for an error after calling a function that can return with a NULL pointer if the function fails, which leads to a resultant NULL pointer dereference."
    },
    "691": {
      "title": "Insufficient Control Flow Management",
      "description": "The code does not sufficiently manage its control flow during execution, creating conditions in which the control flow can be modified in unexpected ways."
    },
    "692": {
      "title": "Incomplete Denylist to Cross-Site Scripting",
      "description": "The product uses a denylist-based protection mechanism to defend against XSS attacks, but the denylist is incomplete, allowing XSS variants to succeed."
    },
    "693": {
      "title": "Protection Mechanism Failure",
      "description": "The product does not use or incorrectly uses a protection mechanism that provides sufficient defense against directed attacks against the product."
    },
    "694": {
      "title": "Use of Multiple Resources with Duplicate Identifier",
      "description": "The product uses multiple resources that can have the same identifier, in a context in which unique identifiers are required."
    },
    "695": {
      "title": "Use of Low-Level Functionality",
      "description": "The product uses low-level functionality that is explicitly prohibited by the framework or specification under which the product is supposed to operate."
    },
    "696": {
      "title": "Incorrect Behavior Order",
      "description": "The product performs multiple related behaviors, but the behaviors are performed in the wrong order in ways which may produce resultant weaknesses."
    },
    "697": {
      "title": "Incorrect Comparison",
      "description": "The product compares two entities in a security-relevant context, but the comparison is incorrect, which may lead to resultant weaknesses."
    },
    "698": {
      "title": "Execution After Redirect (EAR)",
      "description": "The web application sends a redirect to another location, but instead of exiting, it executes additional code."
    },
    "703": {
      "title": "Improper Check or Handling of Exceptional Conditions",
      "description": "The product does not properly anticipate or handle exceptional conditions that rarely occur during normal operation of the product."
    },
    "704": {
      "title": "Incorrect Type Conversion or Cast",
      "description": "The product does not correctly convert an object, resource, or structure from one type to a different type."
    },
    "705": {
      "title": "Incorrect Control Flow Scoping",
      "description": "The product does not properly return control flow to the proper location after it has completed a task or detected an unusual condition."
    },
    "706": {
      "title": "Use of Incorrectly-Resolved Name or Reference",
      "description": "The product uses a name or reference to access a resource, but the name/reference resolves to a resource that is outside of the intended control sphere."
    },
    "707": {
      "title": "Improper Neutralization",
      "description": "The product does not ensure or incorrectly ensures that structured messages or data are well-formed and that certain security properties are met before being read from an upstream component or sent to a downstream component."
    },
    "708": {
      "title": "Incorrect Ownership Assignment",
      "description": "The product assigns an owner to a resource, but the owner is outside of the intended control sphere."
    },
    "710": {
      "title": "Improper Adherence to Coding Standards",
      "description": "The product does not follow certain coding rules for development, which can lead to resultant weaknesses or increase the severity of the associated vulnerabilities."
    },
    "732": {
      "title": "Incorrect Permission Assignment for Critical Resource",
      "description": "The product specifies permissions for a security-critical resource in a way that allows that resource to be read or modified by unintended actors."
    },
    "733": {
      "title": "Compiler Optimization Removal or Modification of Security-critical Code",
      "description": "The developer builds a security-critical protection mechanism into the software, but the compiler optimizes the program such that the mechanism is removed or modified."
    },
    "749": {
      "title": "Exposed Dangerous Method or Function",
      "description": "The product provides an Applications Programming Interface (API) or similar interface for interaction with external actors, but the interface includes a dangerous method or function that is not properly restricted."
    },
    "754": {
      "title": "Improper Check for Unusual or Exceptional Conditions",
      "description": "The product does not check or incorrectly checks for unusual or exceptional conditions that are not expected to occur frequently during day to day operation of the product."
    },
    "755": {
      "title": "Improper Handling of Exceptional Conditions",
      "description": "The product does not handle or incorrectly handles an exceptional condition."
    },
    "756": {
      "title": "Missing Custom Error Page",
      "description": "The product does not return custom error pages to the user, possibly exposing sensitive information."
    },
    "757": {
      "title": "Selection of Less-Secure Algorithm During Negotiation ('Algorithm Downgrade')",
      "description": "A protocol or its implementation supports interaction between multiple actors and allows those actors to negotiate which algorithm should be used as a protection mechanism such as encryption or authentication, but it does not select the strongest algorithm that is available to both parties."
    },
    "758": {
      "title": "Reliance on Undefined, Unspecified, or Implementation-Defined Behavior",
      "description": "The product uses an API function, data structure, or other entity in a way that relies on properties that are not always guaranteed to hold for that entity."
    },
    "759": {
      "title": "Use of a One-Way Hash without a Salt",
      "description": "The product uses a one-way cryptographic hash against an input that should not be reversible, such as a password, but the product does not also use a salt as part of the input."
    },
    "760": {
      "title": "Use of a One-Way Hash with a Predictable Salt",
      "description": "The product uses a one-way cryptographic hash against an input that should not be reversible, such as a password, but the product uses a predictable salt as part of the input."
    },
    "761": {
      "title": "Free of Pointer not at Start of Buffer",
      "description": "The product calls free() on a pointer to a memory resource that was allocated on the heap, but the pointer is not at the start of the buffer."
    },
    "762": {
      "title": "Mismatched Memory Management Routines",
      "description": "The product attempts to return a memory resource to the system, but it calls a release function that is not compatible with the function that was originally used to allocate that resource."
    },
    "763": {
      "title": "Release of Invalid Pointer or Reference",
      "description": "The product attempts to return a memory resource to the system, but it calls the wrong release function or calls the appropriate release function incorrectly."
    },
    "764": {
      "title": "Multiple Locks of a Critical Resource",
      "description": "The product locks a critical resource more times than intended, leading to an unexpected state in the system."
    },
    "765": {
      "title": "Multiple Unlocks of a Critical Resource",
      "description": "The product unlocks a critical resource more times than intended, leading to an unexpected state in the system."
    },
    "766": {
      "title": "Critical Data Element Declared Public",
      "description": "The product declares a critical variable, field, or member to be public when intended security policy requires it to be private."
    },
    "767": {
      "title": "Access to Critical Private Variable via Public Method",
      "description": "The product defines a public method that reads or modifies a private variable."
    },
    "768": {
      "title": "Incorrect Short Circuit Evaluation",
      "description": "The product contains a conditional statement with multiple logical expressions in which one of the non-leading expressions may produce side effects. This may lead to an unexpected state in the program after the execution of the conditional, because short-circuiting logic may prevent the side effects from occurring."
    },
    "769": {
      "title": "DEPRECATED: Uncontrolled File Descriptor Consumption",
      "description": "This entry has been deprecated because it was a duplicate of CWE-774. All content has been transferred to CWE-774."
    },
    "770": {
      "title": "Allocation of Resources Without Limits or Throttling",
      "description": "The product allocates a reusable resource or group of resources on behalf of an actor without imposing any restrictions on the size or number of resources that can be allocated, in violation of the intended security policy for that actor."
    },
    "771": {
      "title": "Missing Reference to Active Allocated Resource",
      "description": "The product does not properly maintain a reference to a resource that has been allocated, which prevents the resource from being reclaimed."
    },
    "772": {
      "title": "Missing Release of Resource after Effective Lifetime",
      "description": "The product does not release a resource after its effective lifetime has ended, i.e., after the resource is no longer needed."
    },
    "773": {
      "title": "Missing Reference to Active File Descriptor or Handle",
      "description": "The product does not properly maintain references to a file descriptor or handle, which prevents that file descriptor/handle from being reclaimed."
    },
    "774": {
      "title": "Allocation of File Descriptors or Handles Without Limits or Throttling",
      "description": "The product allocates file descriptors or handles on behalf of an actor without imposing any restrictions on how many descriptors can be allocated, in violation of the intended security policy for that actor."
    },
    "775": {
      "title": "Missing Release of File Descriptor or Handle after Effective Lifetime",
      "description": "The product does not release a file descriptor or handle after its effective lifetime has ended, i.e., after the file descriptor/handle is no longer needed."
    },
    "776": {
      "title": "Improper Restriction of Recursive Entity References in DTDs ('XML Entity Expansion')",
      "description": "The product uses XML documents and allows their structure to be defined with a Document Type Definition (DTD), but it does not properly control the number of recursive definitions of entities."
    },
    "777": {
      "title": "Regular Expression without Anchors",
      "description": "The product uses a regular expression to perform neutralization, but the regular expression is not anchored and may allow malicious or malformed data to slip through."
    },
    "778": {
      "title": "Insufficient Logging",
      "description": "When a security-critical event occurs, the product either does not record the event or omits important details about the event when logging it."
    },
    "779": {
      "title": "Logging of Excessive Data",
      "description": "The product logs too much information, making log files hard to process and possibly hindering recovery efforts or forensic analysis after an attack."
    },
    "780": {
      "title": "Use of RSA Algorithm without OAEP",
      "description": "The product uses the RSA algorithm but does not incorporate Optimal Asymmetric Encryption Padding (OAEP), which might weaken the encryption."
    },
    "781": {
      "title": "Improper Address Validation in IOCTL with METHOD_NEITHER I/O Control Code",
      "description": "The product defines an IOCTL that uses METHOD_NEITHER for I/O, but it does not validate or incorrectly validates the addresses that are provided."
    },
    "782": {
      "title": "Exposed IOCTL with Insufficient Access Control",
      "description": "The product implements an IOCTL with functionality that should be restricted, but it does not properly enforce access control for the IOCTL."
    },
    "783": {
      "title": "Operator Precedence Logic Error",
      "description": "The product uses an expression in which operator precedence causes incorrect logic to be used."
    },
    "784": {
      "title": "Reliance on Cookies without Validation and Integrity Checking in a Security Decision",
      "description": "The product uses a protection mechanism that relies on the existence or values of a cookie, but it does not properly ensure that the cookie is valid for the associated user."
    },
    "785": {
      "title": "Use of Path Manipulation Function without Maximum-sized Buffer",
      "description": "The product invokes a function for normalizing paths or file names, but it provides an output buffer that is smaller than the maximum possible size, such as PATH_MAX."
    },
    "786": {
      "title": "Access of Memory Location Before Start of Buffer",
      "description": "The product reads or writes to a buffer using an index or pointer that references a memory location prior to the beginning of the buffer."
    },
    "787": {
      "title": "Out-of-bounds Write",
      "description": "The product writes data past the end, or before the beginning, of the intended buffer."
    },
    "788": {
      "title": "Access of Memory Location After End of Buffer",
      "description": "The product reads or writes to a buffer using an index or pointer that references a memory location after the end of the buffer."
    },
    "789": {
      "title": "Memory Allocation with Excessive Size Value",
      "description": "The product allocates memory based on an untrusted, large size value, but it does not ensure that the size is within expected limits, allowing arbitrary amounts of memory to be allocated."
    },
    "790": {
      "title": "Improper Filtering of Special Elements",
      "description": "The product receives data from an upstream component, but does not filter or incorrectly filters special elements before sending it to a downstream component."
    },
    "791": {
      "title": "Incomplete Filtering of Special Elements",
      "description": "The product receives data from an upstream component, but does not completely filter special elements before sending it to a downstream component."
    },
    "792": {
      "title": "Incomplete Filtering of One or More Instances of Special Elements",
      "description": "The product receives data from an upstream component, but does not completely filter one or more instances of special elements before sending it to a downstream component."
    },
    "793": {
      "title": "Only Filtering One Instance of a Special Element",
      "description": "The product receives data from an upstream component, but only filters a single instance of a special element before sending it to a downstream component."
    },
    "794": {
      "title": "Incomplete Filtering of Multiple Instances of Special Elements",
      "description": "The product receives data from an upstream component, but does not filter all instances of a special element before sending it to a downstream component."
    },
    "795": {
      "title": "Only Filtering Special Elements at a Specified Location",
      "description": "The product receives data from an upstream component, but only accounts for special elements at a specified location, thereby missing remaining special elements that may exist before sending it to a downstream component."
    },
    "796": {
      "title": "Only Filtering Special Elements Relative to a Marker",
      "description": "The product receives data from an upstream component, but only accounts for special elements positioned relative to a marker (e.g. \"at the beginning/end of a string; the second argument\"), thereby missing remaining special elements that may exist before sending it to a downstream component."
    },
    "797": {
      "title": "Only Filtering Special Elements at an Absolute Position",
      "description": "The product receives data from an upstream component, but only accounts for special elements at an absolute position (e.g. \"byte number 10\"), thereby missing remaining special elements that may exist before sending it to a downstream component."
    },
    "798": {
      "title": "Use of Hard-coded Credentials",
      "description": "The product contains hard-coded credentials, such as a password or cryptographic key, which it uses for its own inbound authentication, outbound communication to external components, or encryption of internal data."
    },
    "799": {
      "title": "Improper Control of Interaction Frequency",
      "description": "The product does not properly limit the number or frequency of interactions that it has with an actor, such as the number of incoming requests."
    },
    "804": {
      "title": "Guessable CAPTCHA",
      "description": "The product uses a CAPTCHA challenge, but the challenge can be guessed or automatically recognized by a non-human actor."
    },
    "805": {
      "title": "Buffer Access with Incorrect Length Value",
      "description": "The product uses a sequential operation to read or write a buffer, but it uses an incorrect length value that causes it to access memory that is outside of the bounds of the buffer."
    },
    "806": {
      "title": "Buffer Access Using Size of Source Buffer",
      "description": "The product uses the size of a source buffer when reading from or writing to a destination buffer, which may cause it to access memory that is outside of the bounds of the buffer."
    },
    "807": {
      "title": "Reliance on Untrusted Inputs in a Security Decision",
      "description": "The product uses a protection mechanism that relies on the existence or values of an input, but the input can be modified by an untrusted actor in a way that bypasses the protection mechanism."
    },
    "820": {
      "title": "Missing Synchronization",
      "description": "The product utilizes a shared resource in a concurrent manner but does not attempt to synchronize access to the resource."
    },
    "821": {
      "title": "Incorrect Synchronization",
      "description": "The product utilizes a shared resource in a concurrent manner, but it does not correctly synchronize access to the resource."
    },
    "822": {
      "title": "Untrusted Pointer Dereference",
      "description": "The product obtains a value from an untrusted source, converts this value to a pointer, and dereferences the resulting pointer."
    },
    "823": {
      "title": "Use of Out-of-range Pointer Offset",
      "description": "The product performs pointer arithmetic on a valid pointer, but it uses an offset that can point outside of the intended range of valid memory locations for the resulting pointer."
    },
    "824": {
      "title": "Access of Uninitialized Pointer",
      "description": "The product accesses or uses a pointer that has not been initialized."
    },
    "825": {
      "title": "Expired Pointer Dereference",
      "description": "The product dereferences a pointer that contains a location for memory that was previously valid, but is no longer valid."
    },
    "826": {
      "title": "Premature Release of Resource During Expected Lifetime",
      "description": "The product releases a resource that is still intended to be used by itself or another actor."
    },
    "827": {
      "title": "Improper Control of Document Type Definition",
      "description": "The product does not restrict a reference to a Document Type Definition (DTD) to the intended control sphere. This might allow attackers to reference arbitrary DTDs, possibly causing the product to expose files, consume excessive system resources, or execute arbitrary http requests on behalf of the attacker."
    },
    "828": {
      "title": "Signal Handler with Functionality that is not Asynchronous-Safe",
      "description": "The product defines a signal handler that contains code sequences that are not asynchronous-safe, i.e., the functionality is not reentrant, or it can be interrupted."
    },
    "829": {
      "title": "Inclusion of Functionality from Untrusted Control Sphere",
      "description": "The product imports, requires, or includes executable functionality (such as a library) from a source that is outside of the intended control sphere."
    },
    "830": {
      "title": "Inclusion of Web Functionality from an Untrusted Source",
      "description": "The product includes web functionality (such as a web widget) from another domain, which causes it to operate within the domain of the product, potentially granting total access and control of the product to the untrusted source."
    },
    "831": {
      "title": "Signal Handler Function Associated with Multiple Signals",
      "description": "The product defines a function that is used as a handler for more than one signal."
    },
    "832": {
      "title": "Unlock of a Resource that is not Locked",
      "description": "The product attempts to unlock a resource that is not locked."
    },
    "833": {
      "title": "Deadlock",
      "description": "The product contains multiple threads or executable segments that are waiting for each other to release a necessary lock, resulting in deadlock."
    },
    "834": {
      "title": "Excessive Iteration",
      "description": "The product performs an iteration or loop without sufficiently limiting the number of times that the loop is executed."
    },
    "835": {
      "title": "Loop with Unreachable Exit Condition ('Infinite Loop')",
      "description": "The product contains an iteration or loop with an exit condition that cannot be reached, i.e., an infinite loop."
    },
    "836": {
      "title": "Use of Password Hash Instead of Password for Authentication",
      "description": "The product records password hashes in a data store, receives a hash of a password from a client, and compares the supplied hash to the hash obtained from the data store."
    },
    "837": {
      "title": "Improper Enforcement of a Single, Unique Action",
      "description": "The product requires that an actor should only be able to perform an action once, or to have only one unique action, but the product does not enforce or improperly enforces this restriction."
    },
    "838": {
      "title": "Inappropriate Encoding for Output Context",
      "description": "The product uses or specifies an encoding when generating output to a downstream component, but the specified encoding is not the same as the encoding that is expected by the downstream component."
    },
    "839": {
      "title": "Numeric Range Comparison Without Minimum Check",
      "description": "The product checks a value to ensure that it is less than or equal to a maximum, but it does not also verify that the value is greater than or equal to the minimum."
    },
    "841": {
      "title": "Improper Enforcement of Behavioral Workflow",
      "description": "The product supports a session in which more than one behavior must be performed by an actor, but it does not properly ensure that the actor performs the behaviors in the required sequence."
    },
    "842": {
      "title": "Placement of User into Incorrect Group",
      "description": "The product or the administrator places a user into an incorrect group."
    },
    "843": {
      "title": "Access of Resource Using Incompatible Type ('Type Confusion')",
      "description": "The product allocates or initializes a resource such as a pointer, object, or variable using one type, but it later accesses that resource using a type that is incompatible with the original type."
    },
    "862": {
      "title": "Missing Authorization",
      "description": "The product does not perform an authorization check when an actor attempts to access a resource or perform an action."
    },
    "863": {
      "title": "Incorrect Authorization",
      "description": "The product performs an authorization check when an actor attempts to access a resource or perform an action, but it does not correctly perform the check. This allows attackers to bypass intended access restrictions."
    },
    "908": {
      "title": "Use of Uninitialized Resource",
      "description": "The product uses or accesses a resource that has not been initialized."
    },
    "909": {
      "title": "Missing Initialization of Resource",
      "description": "The product does not initialize a critical resource."
    },
    "910": {
      "title": "Use of Expired File Descriptor",
      "description": "The product uses or accesses a file descriptor after it has been closed."
    },
    "911": {
      "title": "Improper Update of Reference Count",
      "description": "The product uses a reference count to manage a resource, but it does not update or incorrectly updates the reference count."
    },
    "912": {
      "title": "Hidden Functionality",
      "description": "The product contains functionality that is not documented, not part of the specification, and not accessible through an interface or command sequence that is obvious to the product's users or administrators."
    },
    "913": {
      "title": "Improper Control of Dynamically-Managed Code Resources",
      "description": "The product does not properly restrict reading from or writing to dynamically-managed code resources such as variables, objects, classes, attributes, functions, or executable instructions or statements."
    },
    "914": {
      "title": "Improper Control of Dynamically-Identified Variables",
      "description": "The product does not properly restrict reading from or writing to dynamically-identified variables."
    },
    "915": {
      "title": "Improperly Controlled Modification of Dynamically-Determined Object Attributes",
      "description": "The product receives input from an upstream component that specifies multiple attributes, properties, or fields that are to be initialized or updated in an object, but it does not properly control which attributes can be modified."
    },
    "916": {
      "title": "Use of Password Hash With Insufficient Computational Effort",
      "description": "The product generates a hash for a password, but it uses a scheme that does not provide a sufficient level of computational effort that would make password cracking attacks infeasible or expensive."
    },
    "917": {
      "title": "Improper Neutralization of Special Elements used in an Expression Language Statement ('Expression Language Injection')",
      "description": "The product constructs all or part of an expression language (EL) statement in a framework such as a Java Server Page (JSP) using externally-influenced input from an upstream component, but it does not neutralize or incorrectly neutralizes special elements that could modify the intended EL statement before it is executed."
    },
    "918": {
      "title": "Server-Side Request Forgery (SSRF)",
      "description": "The web server receives a URL or similar request from an upstream component and retrieves the contents of this URL, but it does not sufficiently ensure that the request is being sent to the expected destination."
    },
    "920": {
      "title": "Improper Restriction of Power Consumption",
      "description": "The product operates in an environment in which power is a limited resource that cannot be automatically replenished, but the product does not properly restrict the amount of power that its operation consumes."
    },
    "921": {
      "title": "Storage of Sensitive Data in a Mechanism without Access Control",
      "description": "The product stores sensitive information in a file system or device that does not have built-in access control."
    },
    "922": {
      "title": "Insecure Storage of Sensitive Information",
      "description": "The product stores sensitive information without properly limiting read or write access by unauthorized actors."
    },
    "923": {
      "title": "Improper Restriction of Communication Channel to Intended Endpoints",
      "description": "The product establishes a communication channel to (or from) an endpoint for privileged or protected operations, but it does not properly ensure that it is communicating with the correct endpoint."
    },
    "924": {
      "title": "Improper Enforcement of Message Integrity During Transmission in a Communication Channel",
      "description": "The product establishes a communication channel with an endpoint and receives a message from that endpoint, but it does not sufficiently ensure that the message was not modified during transmission."
    },
    "925": {
      "title": "Improper Verification of Intent by Broadcast Receiver",
      "description": "The Android application uses a Broadcast Receiver that receives an Intent but does not properly verify that the Intent came from an authorized source."
    },
    "926": {
      "title": "Improper Export of Android Application Components",
      "description": "The Android application exports a component for use by other applications, but does not properly restrict which applications can launch the component or access the data it contains."
    },
    "927": {
      "title": "Use of Implicit Intent for Sensitive Communication",
      "description": "The Android application uses an implicit intent for transmitting sensitive data to other applications."
    },
    "939": {
      "title": "Improper Authorization in Handler for Custom URL Scheme",
      "description": "The product uses a handler for a custom URL scheme, but it does not properly restrict which actors can invoke the handler using the scheme."
    },
    "940": {
      "title": "Improper Verification of Source of a Communication Channel",
      "description": "The product establishes a communication channel to handle an incoming request that has been initiated by an actor, but it does not properly verify that the request is coming from the expected origin."
    },
    "941": {
      "title": "Incorrectly Specified Destination in a Communication Channel",
      "description": "The product creates a communication channel to initiate an outgoing request to an actor, but it does not correctly specify the intended destination for that actor."
    },
    "942": {
      "title": "Permissive Cross-domain Policy with Untrusted Domains",
      "description": "The product uses a cross-domain policy file that includes domains that should not be trusted."
    },
    "943": {
      "title": "Improper Neutralization of Special Elements in Data Query Logic",
      "description": "The product generates a query intended to access or manipulate data in a data store such as a database, but it does not neutralize or incorrectly neutralizes special elements that can modify the intended logic of the query."
    },
    "1004": {
      "title": "Sensitive Cookie Without 'HttpOnly' Flag",
      "description": "The product uses a cookie to store sensitive information, but the cookie is not marked with the HttpOnly flag."
    },
    "1007": {
      "title": "Insufficient Visual Distinction of Homoglyphs Presented to User",
      "description": "The product displays information or identifiers to a user, but the display mechanism does not make it easy for the user to distinguish between visually similar or identical glyphs (homoglyphs), which may cause the user to misinterpret a glyph and perform an unintended, insecure action."
    },
    "1021": {
      "title": "Improper Restriction of Rendered UI Layers or Frames",
      "description": "The web application does not restrict or incorrectly restricts frame objects or UI layers that belong to another application or domain, which can lead to user confusion about which interface the user is interacting with."
    },
    "1022": {
      "title": "Use of Web Link to Untrusted Target with window.opener Access",
      "description": "The web application produces links to untrusted external sites outside of its sphere of control, but it does not properly prevent the external site from modifying  security-critical properties of the window.opener object, such as the location property."
    },
    "1023": {
      "title": "Incomplete Comparison with Missing Factors",
      "description": "The product performs a comparison between entities that must consider multiple factors or characteristics of each entity, but the comparison does not include one or more of these factors."
    },
    "1024": {
      "title": "Comparison of Incompatible Types",
      "description": "The product performs a comparison between two entities, but the entities are of different, incompatible types that cannot be guaranteed to provide correct results when they are directly compared."
    },
    "1025": {
      "title": "Comparison Using Wrong Factors",
      "description": "The code performs a comparison between two entities, but the comparison examines the wrong factors or characteristics of the entities, which can lead to incorrect results and resultant weaknesses."
    },
    "1037": {
      "title": "Processor Optimization Removal or Modification of Security-critical Code",
      "description": "The developer builds a security-critical protection mechanism into the software, but the processor optimizes the execution of the program such that the mechanism is removed or modified."
    },
    "1038": {
      "title": "Insecure Automated Optimizations",
      "description": "The product uses a mechanism that automatically optimizes code, e.g. to improve a characteristic such as performance, but the optimizations can have an unintended side effect that might violate an intended security assumption."
    },
    "1039": {
      "title": "Automated Recognition Mechanism with Inadequate Detection or Handling of Adversarial Input Perturbations",
      "description": "The product uses an automated mechanism such as machine learning to recognize complex data inputs (e.g. image or audio) as a particular concept or category, but it does not properly detect or handle inputs that have been modified or constructed in a way that causes the mechanism to detect a different, incorrect concept."
    },
    "1041": {
      "title": "Use of Redundant Code",
      "description": "The product has multiple functions, methods, procedures, macros, etc. that\n\t\t\t\t\tcontain the same code."
    },
    "1042": {
      "title": "Static Member Data Element outside of a Singleton Class Element",
      "description": "The code contains a member element that is declared as static (but not final), in which\n\t\t\t\t\tits parent class element \n\t\t\t\t\tis not a singleton class - that is, a class element that can be used only once in\n\t\t\t\t\tthe 'to' association of a Create action."
    },
    "1043": {
      "title": "Data Element Aggregating an Excessively Large Number of Non-Primitive Elements",
      "description": "The product uses a data element that has an excessively large\n\t\t\t\t\tnumber of sub-elements with non-primitive data types such as structures or aggregated objects."
    },
    "1044": {
      "title": "Architecture with Number of Horizontal Layers Outside of Expected Range",
      "description": "The product's architecture contains too many - or too few -\n\t\t\t\t\thorizontal layers."
    },
    "1045": {
      "title": "Parent Class with a Virtual Destructor and a Child Class without a Virtual Destructor",
      "description": "A parent class has a virtual destructor method, but the parent has a child class that does not have a virtual destructor."
    },
    "1046": {
      "title": "Creation of Immutable Text Using String Concatenation",
      "description": "The product creates an immutable text string using string concatenation operations."
    },
    "1047": {
      "title": "Modules with Circular Dependencies",
      "description": "The product contains modules in which one module has references that cycle back to itself, i.e., there are circular dependencies."
    },
    "1048": {
      "title": "Invokable Control Element with Large Number of Outward Calls",
      "description": "The code contains callable control elements that\n         contain an excessively large number of references to other\n         application objects external to the context of the callable,\n         i.e. a Fan-Out value that is excessively large."
    },
    "1049": {
      "title": "Excessive Data Query Operations in a Large Data Table",
      "description": "The product performs a data query with a large number of joins\n\t\t\t\t\tand sub-queries on a large data table."
    },
    "1050": {
      "title": "Excessive Platform Resource Consumption within a Loop",
      "description": "The product has a loop body or loop condition that contains a control element that directly or\n\t\t\t\t\tindirectly consumes platform resources, e.g. messaging, sessions, locks, or file\n\t\t\t\t\tdescriptors."
    },
    "1051": {
      "title": "Initialization with Hard-Coded Network Resource Configuration Data",
      "description": "The product initializes data using hard-coded values that act as network resource identifiers."
    },
    "1052": {
      "title": "Excessive Use of Hard-Coded Literals in Initialization",
      "description": "The product initializes a data element using a hard-coded\n\t\t\t\t\tliteral that is not a simple integer or static constant element."
    },
    "1053": {
      "title": "Missing Documentation for Design",
      "description": "The product does not have documentation that represents how it is designed."
    },
    "1054": {
      "title": "Invocation of a Control Element at an Unnecessarily Deep Horizontal Layer",
      "description": "The code at one architectural layer invokes code that resides\n\t\t\t\t\tat a deeper layer than the adjacent layer, i.e., the invocation skips at least one\n\t\t\t\t\tlayer, and the invoked code is not part of a vertical utility layer that can be referenced from any horizontal layer."
    },
    "1055": {
      "title": "Multiple Inheritance from Concrete Classes",
      "description": "The product contains a class with inheritance from more than\n\t\t\t\t\tone concrete class."
    },
    "1056": {
      "title": "Invokable Control Element with Variadic Parameters",
      "description": "A named-callable or method control element has a signature that\n\t\t\t\t\tsupports a variable (variadic) number of parameters or arguments."
    },
    "1057": {
      "title": "Data Access Operations Outside of Expected Data Manager Component",
      "description": "The product uses a dedicated, central data manager component as required by design, but it contains code that performs data-access operations that do not use this data manager."
    },
    "1058": {
      "title": "Invokable Control Element in Multi-Thread Context with non-Final Static Storable or Member Element",
      "description": "The code contains a function or method that\n\t\t operates in a multi-threaded environment but owns an unsafe non-final\n\t\t                     static storable or member data element."
    },
    "1059": {
      "title": "Insufficient Technical Documentation",
      "description": "The product does not contain sufficient\n         technical or engineering documentation (whether on paper or\n         in electronic form) that contains descriptions of all the\n         relevant software/hardware elements of the product, such as\n         its usage, structure, architectural components, interfaces, design, implementation,\n         configuration, operation, etc."
    },
    "1060": {
      "title": "Excessive Number of Inefficient Server-Side Data Accesses",
      "description": "The product performs too many data queries without using efficient data processing functionality such as stored procedures."
    },
    "1061": {
      "title": "Insufficient Encapsulation",
      "description": "The product does not sufficiently hide the internal representation and implementation details of data or methods, which might allow external components or modules to modify data unexpectedly, invoke unexpected functionality, or introduce dependencies that the programmer did not intend."
    },
    "1062": {
      "title": "Parent Class with References to Child Class",
      "description": "The code has a parent class that contains references to a child class, its methods, or its members."
    },
    "1063": {
      "title": "Creation of Class Instance within a Static Code Block",
      "description": "A static code block creates an instance of a class."
    },
    "1064": {
      "title": "Invokable Control Element with Signature Containing an Excessive Number of Parameters",
      "description": "The product contains a function, subroutine, or method whose signature has an unnecessarily large number of\n\t\t\t\t\tparameters/arguments."
    },
    "1065": {
      "title": "Runtime Resource Management Control Element in a Component Built to Run on Application Servers",
      "description": "The product uses deployed components from application servers, but it also uses low-level functions/methods for management of resources, instead of the API provided by the application server."
    },
    "1066": {
      "title": "Missing Serialization Control Element",
      "description": "The product contains a serializable data element that does not\n\t\t\t\t\thave an associated serialization method."
    },
    "1067": {
      "title": "Excessive Execution of Sequential Searches of Data Resource",
      "description": "The product contains a data query against an SQL table or view\n\t\t\t\t\tthat is configured in a way that does not utilize an index and may cause\n\t\t\t\t\tsequential searches to be performed."
    },
    "1068": {
      "title": "Inconsistency Between Implementation and Documented Design",
      "description": "The implementation of the product is not consistent with the\n\t\t\t\t\tdesign as described within the relevant documentation."
    },
    "1069": {
      "title": "Empty Exception Block",
      "description": "An invokable code block contains an exception handling block that does not contain any code, i.e. is empty."
    },
    "1070": {
      "title": "Serializable Data Element Containing non-Serializable Item Elements",
      "description": "The product contains a serializable, storable data element such as a field or member,\n\t\t\t\t\tbut the data element contains member elements that are not\n\t\t\t\t\tserializable."
    },
    "1071": {
      "title": "Empty Code Block",
      "description": "The source code contains a block that does not contain any code, i.e., the block is empty."
    },
    "1072": {
      "title": "Data Resource Access without Use of Connection Pooling",
      "description": "The product accesses a data resource through a database without using a\n\t\t\t\t\tconnection pooling capability."
    },
    "1073": {
      "title": "Non-SQL Invokable Control Element with Excessive Number of Data Resource Accesses",
      "description": "The product contains a client with a function or method that contains a large number of data accesses/queries that are sent through a data manager, i.e., does not use efficient database capabilities."
    },
    "1074": {
      "title": "Class with Excessively Deep Inheritance",
      "description": "A class has an inheritance level that is too high, i.e., it\n\t\t\t\t\thas a large number of parent classes."
    },
    "1075": {
      "title": "Unconditional Control Flow Transfer outside of Switch Block",
      "description": "The product performs unconditional control transfer (such as a\n\t\t\t\t\t\"goto\") in code outside of a branching structure such as a switch\n\t\t\t\t\tblock."
    },
    "1076": {
      "title": "Insufficient Adherence to Expected Conventions",
      "description": "The product's architecture, source code, design, documentation,\n\t\t\t\t\tor other artifact does not follow required conventions."
    },
    "1077": {
      "title": "Floating Point Comparison with Incorrect Operator",
      "description": "The code performs a comparison such as an\n        equality test between two float (floating point) values, but\n        it uses comparison operators that do not account for the\n        possibility of loss of precision."
    },
    "1078": {
      "title": "Inappropriate Source Code Style or Formatting",
      "description": "The source code does not follow\n\t\t\t\tdesired style or formatting for indentation, white\n\t\t\t\tspace, comments, etc."
    },
    "1079": {
      "title": "Parent Class without Virtual Destructor Method",
      "description": "A parent class contains one or more child classes, but the parent class does not have a virtual destructor method."
    },
    "1080": {
      "title": "Source Code File with Excessive Number of Lines of Code",
      "description": "A source code file has too many lines of\n\t\t\t\t\tcode."
    },
    "1082": {
      "title": "Class Instance Self Destruction Control Element",
      "description": "The code contains a class instance that calls the method or function to delete or destroy itself."
    },
    "1083": {
      "title": "Data Access from Outside Expected Data Manager Component",
      "description": "The product is intended to manage data access through a particular data manager component such as a relational or non-SQL database, but it contains code that performs data access operations without using that component."
    },
    "1084": {
      "title": "Invokable Control Element with Excessive File or Data Access Operations",
      "description": "A function or method contains too many\n\t\t\t\t\toperations that utilize a data manager or file resource."
    },
    "1085": {
      "title": "Invokable Control Element with Excessive Volume of Commented-out Code",
      "description": "A function, method, procedure, etc. contains an excessive amount of code that has been\n\t\t\t\t\tcommented out within its body."
    },
    "1086": {
      "title": "Class with Excessive Number of Child Classes",
      "description": "A class contains an unnecessarily large number of\n\t\t\t\t\tchildren."
    },
    "1087": {
      "title": "Class with Virtual Method without a Virtual Destructor",
      "description": "A class contains a virtual method, but the method does not have an associated virtual destructor."
    },
    "1088": {
      "title": "Synchronous Access of Remote Resource without Timeout",
      "description": "The code has a synchronous call to a remote resource, but there is no timeout for the call, or the timeout is set to infinite."
    },
    "1089": {
      "title": "Large Data Table with Excessive Number of Indices",
      "description": "The product uses a large data table that contains an excessively large number of\n\t\t\t\t\tindices."
    },
    "1090": {
      "title": "Method Containing Access of a Member Element from Another Class",
      "description": "A method for a class performs an operation that directly\n\t\t\t\t\taccesses a member element from another class."
    },
    "1091": {
      "title": "Use of Object without Invoking Destructor Method",
      "description": "The product contains a method that accesses an object but does not later invoke\n\t\t\t\t\tthe element's associated finalize/destructor method."
    },
    "1092": {
      "title": "Use of Same Invokable Control Element in Multiple Architectural Layers",
      "description": "The product uses the same control element across multiple\n\t\t\t\t\tarchitectural layers."
    },
    "1093": {
      "title": "Excessively Complex Data Representation",
      "description": "The product uses an unnecessarily complex internal representation for its data structures or interrelationships between those structures."
    },
    "1094": {
      "title": "Excessive Index Range Scan for a Data Resource",
      "description": "The product contains an index range scan for a large data table,\n\t\t\t\t\tbut the scan can cover a large number of rows."
    },
    "1095": {
      "title": "Loop Condition Value Update within the Loop",
      "description": "The product uses a loop with a control flow condition based on\n\t\t\t\t\ta value that is updated within the body of the loop."
    },
    "1096": {
      "title": "Singleton Class Instance Creation without Proper Locking or Synchronization",
      "description": "The product implements a Singleton design pattern but does not use appropriate locking or other synchronization mechanism to ensure that the singleton class is only instantiated once."
    },
    "1097": {
      "title": "Persistent Storable Data Element without Associated Comparison Control Element",
      "description": "The product uses a storable data element that does not have\n\t\t\t\t\tall of the associated functions or methods that are necessary to support\n\t\t\t\t\tcomparison."
    },
    "1098": {
      "title": "Data Element containing Pointer Item without Proper Copy Control Element",
      "description": "The code contains a data element with a pointer that does not have an associated copy or constructor method."
    },
    "1099": {
      "title": "Inconsistent Naming Conventions for Identifiers",
      "description": "The product's code, documentation, or other artifacts do not\n\t\t\t\t\tconsistently use the same naming conventions for variables, callables, groups of\n\t\t\t\t\trelated callables, I/O capabilities, data types, file names, or similar types of\n\t\t\t\t\telements."
    },
    "1100": {
      "title": "Insufficient Isolation of System-Dependent Functions",
      "description": "The product or code does not isolate system-dependent\n\t\t\t\t\tfunctionality into separate standalone modules."
    },
    "1101": {
      "title": "Reliance on Runtime Component in Generated Code",
      "description": "The product uses automatically-generated code that cannot be\n\t\t\t\t\texecuted without a specific runtime support component."
    },
    "1102": {
      "title": "Reliance on Machine-Dependent Data Representation",
      "description": "The code uses a data representation that relies on low-level\n\t\t\t\t\tdata representation or constructs that may vary across different processors,\n\t\t\t\t\tphysical machines, OSes, or other physical components."
    },
    "1103": {
      "title": "Use of Platform-Dependent Third Party Components",
      "description": "The product relies on third-party components that do\n\t\t\t\t\tnot provide equivalent functionality across all desirable\n\t\t\t\t\tplatforms."
    },
    "1104": {
      "title": "Use of Unmaintained Third Party Components",
      "description": "The product relies on third-party components that are not\n\t\t\t\t\tactively supported or maintained by the original developer or a trusted proxy\n\t\t\t\t\tfor the original developer."
    },
    "1105": {
      "title": "Insufficient Encapsulation of Machine-Dependent Functionality",
      "description": "The product or code uses machine-dependent functionality, but\n\t\t\t\t\tit does not sufficiently encapsulate or isolate this functionality from\n\t\t\t\t\tthe rest of the code."
    },
    "1106": {
      "title": "Insufficient Use of Symbolic Constants",
      "description": "The source code uses literal constants that may need to change\n\t\t\t\t\tor evolve over time, instead of using symbolic constants."
    },
    "1107": {
      "title": "Insufficient Isolation of Symbolic Constant Definitions",
      "description": "The source code uses symbolic constants, but it does not\n\t\t\t\t\tsufficiently place the definitions of these constants into a more centralized or\n\t\t\t\t\tisolated location."
    },
    "1108": {
      "title": "Excessive Reliance on Global Variables",
      "description": "The code is structured in a way that relies too much on using\n\t\t\t\t\tor setting global variables throughout various points in the code, instead of\n\t\t\t\t\tpreserving the associated information in a narrower, more local\n\t\t\t\t\tcontext."
    },
    "1109": {
      "title": "Use of Same Variable for Multiple Purposes",
      "description": "The code contains a callable, block, or other code element in\n\t\t\t\t\twhich the same variable is used to control more than one unique task or store\n\t\t\t\t\tmore than one instance of data."
    },
    "1110": {
      "title": "Incomplete Design Documentation",
      "description": "The product's design documentation does not adequately describe\n\t\t\t\t\tcontrol flow, data flow, system initialization, relationships between tasks,\n\t\t\t\t\tcomponents, rationales, or other important aspects of the\n\t\t\t\t\tdesign."
    },
    "1111": {
      "title": "Incomplete I/O Documentation",
      "description": "The product's documentation does not adequately define inputs,\n\t\t\t\t\toutputs, or system/software interfaces."
    },
    "1112": {
      "title": "Incomplete Documentation of Program Execution",
      "description": "The document does not fully define all mechanisms that are used\n\t\t\t\t\tto control or influence how product-specific programs are\n\t\t\t\t\texecuted."
    },
    "1113": {
      "title": "Inappropriate Comment Style",
      "description": "The source code uses comment styles or formats that are\n\t\t\t\t\tinconsistent or do not follow expected standards for the\n\t\t\t\t\tproduct."
    },
    "1114": {
      "title": "Inappropriate Whitespace Style",
      "description": "The source code contains whitespace that is inconsistent across\n\t\t\t\t\tthe code or does not follow expected standards for the\n\t\t\t\t\tproduct."
    },
    "1115": {
      "title": "Source Code Element without Standard Prologue",
      "description": "The source code contains elements such as source files \n\t\t\t\t\tthat do not consistently provide a prologue or header that has been\n\t\t\t\t\tstandardized for the project."
    },
    "1116": {
      "title": "Inaccurate Comments",
      "description": "The source code contains comments that do not accurately\n\t\t\t\t\tdescribe or explain aspects of the portion of the code with which the comment is\n\t\t\t\t\tassociated."
    },
    "1117": {
      "title": "Callable with Insufficient Behavioral Summary",
      "description": "The code contains a function or method whose signature and/or associated\n\t\t\t\t\tinline documentation does not sufficiently describe the callable's inputs, outputs,\n\t\t\t\t\tside effects, assumptions, or return codes."
    },
    "1118": {
      "title": "Insufficient Documentation of Error Handling Techniques",
      "description": "The documentation does not sufficiently describe the techniques\n\t\t\t\t\tthat are used for error handling, exception processing, or similar\n\t\t\t\t\tmechanisms."
    },
    "1119": {
      "title": "Excessive Use of Unconditional Branching",
      "description": "The code uses too many unconditional branches (such as\n\t\t\t\t\t\"goto\")."
    },
    "1120": {
      "title": "Excessive Code Complexity",
      "description": "The code is too complex, as calculated using a well-defined,\n\t\t\t\t\tquantitative measure."
    },
    "1121": {
      "title": "Excessive McCabe Cyclomatic Complexity",
      "description": "The code contains McCabe cyclomatic complexity that exceeds a\n\tdesirable maximum."
    },
    "1122": {
      "title": "Excessive Halstead Complexity",
      "description": "The code is structured in a way that a Halstead complexity\n\t\t\t\t\tmeasure exceeds a desirable maximum."
    },
    "1123": {
      "title": "Excessive Use of Self-Modifying Code",
      "description": "The product uses too much self-modifying\n\t\t\t\t\tcode."
    },
    "1124": {
      "title": "Excessively Deep Nesting",
      "description": "The code contains a callable or other code grouping in which\n\t\t\t\t\tthe nesting / branching is too deep."
    },
    "1125": {
      "title": "Excessive Attack Surface",
      "description": "The product has an attack surface whose quantitative\n\t\t\t\t\tmeasurement exceeds a desirable maximum."
    },
    "1126": {
      "title": "Declaration of Variable with Unnecessarily Wide Scope",
      "description": "The source code declares a variable in one scope, but the\n\t\t\t\t\tvariable is only used within a narrower scope."
    },
    "1127": {
      "title": "Compilation with Insufficient Warnings or Errors",
      "description": "The code is compiled without sufficient warnings enabled, which\n\t\t\t\t\tmay prevent the detection of subtle bugs or quality\n\t\t\t\t\tissues."
    },
    "1164": {
      "title": "Irrelevant Code",
      "description": "The product contains code that is not essential for execution,\n\t     i.e. makes no state changes and has no side effects that alter\n\t     data or control flow, such that removal of the code would have no impact\n\t     to functionality or correctness."
    },
    "1173": {
      "title": "Improper Use of Validation Framework",
      "description": "The product does not use, or incorrectly uses, an input validation framework that is provided by the source language or an independent library."
    },
    "1174": {
      "title": "ASP.NET Misconfiguration: Improper Model Validation",
      "description": "The ASP.NET application does not use, or incorrectly uses, the model validation framework."
    },
    "1176": {
      "title": "Inefficient CPU Computation",
      "description": "The product performs CPU computations using\n         algorithms that are not as efficient as they could be for the\n         needs of the developer, i.e., the computations can be\n         optimized further."
    },
    "1177": {
      "title": "Use of Prohibited Code",
      "description": "The product uses a function, library, or third party component\n\t     that has been explicitly prohibited, whether by the developer or\n\t     the customer."
    },
    "1187": {
      "title": "DEPRECATED: Use of Uninitialized Resource",
      "description": "This entry has been deprecated because it was a duplicate of CWE-908. All content has been transferred to CWE-908."
    },
    "1188": {
      "title": "Insecure Default Initialization of Resource",
      "description": "The product initializes or sets a resource with a default that is intended to be changed by the administrator, but the default is not secure."
    },
    "1189": {
      "title": "Improper Isolation of Shared Resources on System-on-a-Chip (SoC)",
      "description": "The System-On-a-Chip (SoC) does not properly isolate shared resources between trusted and untrusted agents."
    },
    "1190": {
      "title": "DMA Device Enabled Too Early in Boot Phase",
      "description": "The product enables a Direct Memory Access (DMA) capable device before the security configuration settings are established, which allows an attacker to extract data from or gain privileges on the product."
    },
    "1191": {
      "title": "On-Chip Debug and Test Interface With Improper Access Control",
      "description": "The chip does not implement or does not correctly perform access control to check whether users are authorized to access internal registers and test modes through the physical debug/test interface."
    },
    "1192": {
      "title": "System-on-Chip (SoC) Using Components without Unique, Immutable Identifiers",
      "description": "The System-on-Chip (SoC) does not have unique, immutable identifiers for each of its components."
    },
    "1193": {
      "title": "Power-On of Untrusted Execution Core Before Enabling Fabric Access Control",
      "description": "The product enables components that contain untrusted firmware before memory and fabric access controls have been enabled."
    },
    "1204": {
      "title": "Generation of Weak Initialization Vector (IV)",
      "description": "The product uses a cryptographic primitive that uses an Initialization\n\t\t\tVector (IV), but the product does not generate IVs that are\n\t\t\tsufficiently unpredictable or unique according to the expected\n\t\t\tcryptographic requirements for that primitive."
    },
    "1209": {
      "title": "Failure to Disable Reserved Bits",
      "description": "The reserved bits in a hardware design are not disabled prior to production. Typically, reserved bits are used for future capabilities and should not support any functional logic in the design.   However, designers might covertly use these bits to debug or further develop new capabilities in production hardware. Adversaries with access to these bits will write to them in hopes of compromising hardware state."
    },
    "1220": {
      "title": "Insufficient Granularity of Access Control",
      "description": "The product implements access controls via a policy or other feature with the intention to disable or restrict accesses (reads and/or writes) to assets in a system from untrusted agents. However, implemented access controls lack required granularity, which renders the control policy too broad because it allows accesses from unauthorized agents to the security-sensitive assets."
    },
    "1221": {
      "title": "Incorrect Register Defaults or Module Parameters",
      "description": "Hardware description language code incorrectly defines register defaults or hardware IP parameters to insecure values."
    },
    "1222": {
      "title": "Insufficient Granularity of Address Regions Protected by Register Locks",
      "description": "The product defines a large address region protected from modification by the same register lock control bit. This results in a conflict between the functional requirement that some addresses need to be writable by software during operation and the security requirement that the system configuration lock bit must be set during the boot process."
    },
    "1223": {
      "title": "Race Condition for Write-Once Attributes",
      "description": "A write-once register in hardware design is programmable by an untrusted software component earlier than the trusted software component, resulting in a race condition issue."
    },
    "1224": {
      "title": "Improper Restriction of Write-Once Bit Fields",
      "description": "The hardware design control register \"sticky bits\" or write-once bit fields are improperly implemented, such that they can be reprogrammed by software."
    },
    "1229": {
      "title": "Creation of Emergent Resource",
      "description": "The product manages resources or behaves in a way that indirectly creates a new, distinct resource that can be used by attackers in violation of the intended policy."
    },
    "1230": {
      "title": "Exposure of Sensitive Information Through Metadata",
      "description": "The product prevents direct access to a resource containing sensitive information, but it does not sufficiently limit access to metadata that is derived from the original, sensitive information."
    },
    "1231": {
      "title": "Improper Prevention of Lock Bit Modification",
      "description": "The product uses a trusted lock bit for restricting access to registers, address regions, or other resources, but the product does not prevent the value of the lock bit from being modified after it has been set."
    },
    "1232": {
      "title": "Improper Lock Behavior After Power State Transition",
      "description": "Register lock bit protection disables changes to system configuration once the bit is set. Some of the protected registers or lock bits become programmable after power state transitions (e.g., Entry and wake from low power sleep modes) causing the system configuration to be changeable."
    },
    "1233": {
      "title": "Security-Sensitive Hardware Controls with Missing Lock Bit Protection",
      "description": "The product uses a register lock bit protection mechanism, but it does not ensure that the lock bit prevents modification of system registers or controls that perform changes to important hardware system configuration."
    },
    "1234": {
      "title": "Hardware Internal or Debug Modes Allow Override of Locks",
      "description": "System configuration protection may be bypassed during debug mode."
    },
    "1235": {
      "title": "Incorrect Use of Autoboxing and Unboxing for Performance Critical Operations",
      "description": "The code uses boxed primitives, which may introduce inefficiencies into performance-critical operations."
    },
    "1236": {
      "title": "Improper Neutralization of Formula Elements in a CSV File",
      "description": "The product saves user-provided information into a Comma-Separated Value (CSV) file, but it does not neutralize or incorrectly neutralizes special elements that could be interpreted as a command when the file is opened by a spreadsheet product."
    },
    "1239": {
      "title": "Improper Zeroization of Hardware Register",
      "description": "The hardware product does not properly clear sensitive information from built-in registers when the user of the hardware block changes."
    },
    "1240": {
      "title": "Use of a Cryptographic Primitive with a Risky Implementation",
      "description": "To fulfill the need for a cryptographic primitive, the product implements a cryptographic algorithm using a non-standard, unproven, or disallowed/non-compliant cryptographic implementation."
    },
    "1241": {
      "title": "Use of Predictable Algorithm in Random Number Generator",
      "description": "The device uses an algorithm that is predictable and generates a pseudo-random number."
    },
    "1242": {
      "title": "Inclusion of Undocumented Features or Chicken Bits",
      "description": "The device includes chicken bits or undocumented features that can create entry points for unauthorized actors."
    },
    "1243": {
      "title": "Sensitive Non-Volatile Information Not Protected During Debug",
      "description": "Access to security-sensitive information stored in fuses is not limited during debug."
    },
    "1244": {
      "title": "Internal Asset Exposed to Unsafe Debug Access Level or State",
      "description": "The product uses physical debug or test\n        interfaces with support for multiple access levels, but it\n        assigns the wrong debug access level to an internal asset,\n        providing unintended access to the asset from untrusted debug\n        agents."
    },
    "1245": {
      "title": "Improper Finite State Machines (FSMs) in Hardware Logic",
      "description": "Faulty finite state machines (FSMs) in the hardware logic allow an attacker to put the system in an undefined state, to cause a denial of service (DoS) or gain privileges on the victim's system."
    },
    "1246": {
      "title": "Improper Write Handling in Limited-write Non-Volatile Memories",
      "description": "The product does not implement or incorrectly implements wear leveling operations in limited-write non-volatile memories."
    },
    "1247": {
      "title": "Improper Protection Against Voltage and Clock Glitches",
      "description": "The device does not contain or contains incorrectly implemented circuitry or sensors to detect and mitigate voltage and clock glitches and protect sensitive information or software contained on the device."
    },
    "1248": {
      "title": "Semiconductor Defects in Hardware Logic with Security-Sensitive Implications",
      "description": "The security-sensitive hardware module contains semiconductor defects."
    },
    "1249": {
      "title": "Application-Level Admin Tool with Inconsistent View of Underlying Operating System",
      "description": "The product provides an application for administrators to manage parts of the underlying operating system, but the application does not accurately identify all of the relevant entities or resources that exist in the OS; that is, the application's model of the OS's state is inconsistent with the OS's actual state."
    },
    "1250": {
      "title": "Improper Preservation of Consistency Between Independent Representations of Shared State",
      "description": "The product has or supports multiple distributed components or sub-systems that are each required to keep their own local copy of shared data - such as state or cache - but the product does not ensure that all local copies remain consistent with each other."
    },
    "1251": {
      "title": "Mirrored Regions with Different Values",
      "description": "The product's architecture mirrors regions without ensuring that their contents always stay in sync."
    },
    "1252": {
      "title": "CPU Hardware Not Configured to Support Exclusivity of Write and Execute Operations",
      "description": "The CPU is not configured to provide hardware support for exclusivity of write and execute operations on memory. This allows an attacker to execute data from all of memory."
    },
    "1253": {
      "title": "Incorrect Selection of Fuse Values",
      "description": "The logic level used to set a system to a secure state relies on a fuse being unblown. An attacker can set the system to an insecure state merely by blowing the fuse."
    },
    "1254": {
      "title": "Incorrect Comparison Logic Granularity",
      "description": "The product's comparison logic is performed over a series of steps rather than across the entire string in one operation. If there is a comparison logic failure on one of these steps, the operation may be vulnerable to a timing attack that can result in the interception of the process for nefarious purposes."
    },
    "1255": {
      "title": "Comparison Logic is Vulnerable to Power Side-Channel Attacks",
      "description": "A device's real time power consumption may be monitored during security token evaluation and the information gleaned may be used to determine the value of the reference token."
    },
    "1256": {
      "title": "Improper Restriction of Software Interfaces to Hardware Features",
      "description": "The product provides software-controllable\n\t\t\tdevice functionality for capabilities such as power and\n\t\t\tclock management, but it does not properly limit\n\t\t\tfunctionality that can lead to modification of\n\t\t\thardware memory or register bits, or the ability to\n\t\t\tobserve physical side channels."
    },
    "1257": {
      "title": "Improper Access Control Applied to Mirrored or Aliased Memory Regions",
      "description": "Aliased or mirrored memory regions in hardware designs may have inconsistent read/write permissions enforced by the hardware. A possible result is that an untrusted agent is blocked from accessing a memory region but is not blocked from accessing the corresponding aliased memory region."
    },
    "1258": {
      "title": "Exposure of Sensitive System Information Due to Uncleared Debug Information",
      "description": "The hardware does not fully clear security-sensitive values, such as keys and intermediate values in cryptographic operations, when debug mode is entered."
    },
    "1259": {
      "title": "Improper Restriction of Security Token Assignment",
      "description": "The System-On-A-Chip (SoC) implements a Security Token mechanism to differentiate what actions are allowed or disallowed when a transaction originates from an entity. However, the Security Tokens are improperly protected."
    },
    "1260": {
      "title": "Improper Handling of Overlap Between Protected Memory Ranges",
      "description": "The product allows address regions to overlap, which can result in the bypassing of intended memory protection."
    },
    "1261": {
      "title": "Improper Handling of Single Event Upsets",
      "description": "The hardware logic does not effectively handle when single-event upsets (SEUs) occur."
    },
    "1262": {
      "title": "Improper Access Control for Register Interface",
      "description": "The product uses memory-mapped I/O registers that act as an interface to hardware functionality from software, but there is improper access control to those registers."
    },
    "1263": {
      "title": "Improper Physical Access Control",
      "description": "The product is designed with access restricted to certain information, but it does not sufficiently protect against an unauthorized actor with physical access to these areas."
    },
    "1264": {
      "title": "Hardware Logic with Insecure De-Synchronization between Control and Data Channels",
      "description": "The hardware logic for error handling and security checks can incorrectly forward data before the security check is complete."
    },
    "1265": {
      "title": "Unintended Reentrant Invocation of Non-reentrant Code Via Nested Calls",
      "description": "During execution of non-reentrant code, the product performs a call that unintentionally produces a nested invocation of the non-reentrant code."
    },
    "1266": {
      "title": "Improper Scrubbing of Sensitive Data from Decommissioned Device",
      "description": "The product does not properly provide a capability for the product administrator to remove sensitive data at the time the product is decommissioned.  A scrubbing capability could be missing, insufficient, or incorrect."
    },
    "1267": {
      "title": "Policy Uses Obsolete Encoding",
      "description": "The product uses an obsolete encoding mechanism to implement access controls."
    },
    "1268": {
      "title": "Policy Privileges are not Assigned Consistently Between Control and Data Agents",
      "description": "The product's hardware-enforced access control for a particular resource improperly accounts for privilege discrepancies between control and write policies."
    },
    "1269": {
      "title": "Product Released in Non-Release Configuration",
      "description": "The product released to market is released in pre-production or manufacturing configuration."
    },
    "1270": {
      "title": "Generation of Incorrect Security Tokens",
      "description": "The product implements a Security Token mechanism to differentiate what actions are allowed or disallowed when a transaction originates from an entity. However, the Security Tokens generated in the system are incorrect."
    },
    "1271": {
      "title": "Uninitialized Value on Reset for Registers Holding Security Settings",
      "description": "Security-critical logic is not set to a known value on reset."
    },
    "1272": {
      "title": "Sensitive Information Uncleared Before Debug/Power State Transition",
      "description": "The product performs a power or debug state transition, but it does not clear sensitive information that should no longer be accessible due to changes to information access restrictions."
    },
    "1273": {
      "title": "Device Unlock Credential Sharing",
      "description": "The credentials necessary for unlocking a device are shared across multiple parties and may expose sensitive information."
    },
    "1274": {
      "title": "Improper Access Control for Volatile Memory Containing Boot Code",
      "description": "The product conducts a secure-boot process that transfers bootloader code from Non-Volatile Memory (NVM) into Volatile Memory (VM), but it does not have sufficient access control or other protections for the Volatile Memory."
    },
    "1275": {
      "title": "Sensitive Cookie with Improper SameSite Attribute",
      "description": "The SameSite attribute for sensitive cookies is not set, or an insecure value is used."
    },
    "1276": {
      "title": "Hardware Child Block Incorrectly Connected to Parent System",
      "description": "Signals between a hardware IP and the parent system design are incorrectly connected causing security risks."
    },
    "1277": {
      "title": "Firmware Not Updateable",
      "description": "The product does not provide its\n\t\t\tusers with the ability to update or patch its\n\t\t\tfirmware to address any vulnerabilities or\n\t\t\tweaknesses that may be present."
    },
    "1278": {
      "title": "Missing Protection Against Hardware Reverse Engineering Using Integrated Circuit (IC) Imaging Techniques",
      "description": "Information stored in hardware may be recovered by an attacker with the capability to capture and analyze images of the integrated circuit using techniques such as scanning electron microscopy."
    },
    "1279": {
      "title": "Cryptographic Operations are run Before Supporting Units are Ready",
      "description": "Performing cryptographic operations without ensuring that the supporting inputs are ready to supply valid data may compromise the cryptographic result."
    },
    "1280": {
      "title": "Access Control Check Implemented After Asset is Accessed",
      "description": "A product's hardware-based access control check occurs after the asset has been accessed."
    },
    "1281": {
      "title": "Sequence of Processor Instructions Leads to Unexpected Behavior",
      "description": "Specific combinations of processor instructions lead to undesirable behavior such as locking the processor until a hard reset performed."
    },
    "1282": {
      "title": "Assumed-Immutable Data is Stored in Writable Memory",
      "description": "Immutable data, such as a first-stage bootloader, device identifiers, and \"write-once\" configuration settings are stored in writable memory that can be re-programmed or updated in the field."
    },
    "1283": {
      "title": "Mutable Attestation or Measurement Reporting Data",
      "description": "The register contents used for attestation or measurement reporting data to verify boot flow are modifiable by an adversary."
    },
    "1284": {
      "title": "Improper Validation of Specified Quantity in Input",
      "description": "The product receives input that is expected to specify a quantity (such as size or length), but it does not validate or incorrectly validates that the quantity has the required properties."
    },
    "1285": {
      "title": "Improper Validation of Specified Index, Position, or Offset in Input",
      "description": "The product receives input that is expected to specify an index, position, or offset into an indexable resource such as a buffer or file, but it does not validate or incorrectly validates that the specified index/position/offset has the required properties."
    },
    "1286": {
      "title": "Improper Validation of Syntactic Correctness of Input",
      "description": "The product receives input that is expected to be well-formed - i.e., to comply with a certain syntax - but it does not validate or incorrectly validates that the input complies with the syntax."
    },
    "1287": {
      "title": "Improper Validation of Specified Type of Input",
      "description": "The product receives input that is expected to be of a certain type, but it does not validate or incorrectly validates that the input is actually of the expected type."
    },
    "1288": {
      "title": "Improper Validation of Consistency within Input",
      "description": "The product receives a complex input with multiple elements or fields that must be consistent with each other, but it does not validate or incorrectly validates that the input is actually consistent."
    },
    "1289": {
      "title": "Improper Validation of Unsafe Equivalence in Input",
      "description": "The product receives an input value that is used as a resource identifier or other type of reference, but it does not validate or incorrectly validates that the input is equivalent to a potentially-unsafe value."
    },
    "1290": {
      "title": "Incorrect Decoding of Security Identifiers ",
      "description": "The product implements a decoding mechanism to decode certain bus-transaction signals to security identifiers. If the decoding is implemented incorrectly, then untrusted agents can now gain unauthorized access to the asset."
    },
    "1291": {
      "title": "Public Key Re-Use for Signing both Debug and Production Code",
      "description": "The same public key is used for signing both debug and production code."
    },
    "1292": {
      "title": "Incorrect Conversion of Security Identifiers",
      "description": "The product implements a conversion mechanism to map certain bus-transaction signals to security identifiers. However, if the conversion is incorrectly implemented, untrusted agents can gain unauthorized access to the asset."
    },
    "1293": {
      "title": "Missing Source Correlation of Multiple Independent Data",
      "description": "The product relies on one source of data, preventing the ability to detect if an adversary has compromised a data source."
    },
    "1294": {
      "title": "Insecure Security Identifier Mechanism",
      "description": "The System-on-Chip (SoC) implements a Security Identifier mechanism to differentiate what actions are allowed or disallowed when a transaction originates from an entity. However, the Security Identifiers are not correctly implemented."
    },
    "1295": {
      "title": "Debug Messages Revealing Unnecessary Information",
      "description": "The product fails to adequately prevent the revealing of unnecessary and potentially sensitive system information within debugging messages."
    },
    "1296": {
      "title": "Incorrect Chaining or Granularity of Debug Components",
      "description": "The product's debug components contain incorrect chaining or granularity of debug components."
    },
    "1297": {
      "title": "Unprotected Confidential Information on Device is Accessible by OSAT Vendors",
      "description": "The product does not adequately protect confidential information on the device from being accessed by Outsourced Semiconductor Assembly and Test (OSAT) vendors."
    },
    "1298": {
      "title": "Hardware Logic Contains Race Conditions",
      "description": "A race condition in the hardware logic results in undermining security guarantees of the system."
    },
    "1299": {
      "title": "Missing Protection Mechanism for Alternate Hardware Interface",
      "description": "The lack of protections on alternate paths to access\n                control-protected assets (such as unprotected shadow registers\n                and other external facing unguarded interfaces) allows an\n                attacker to bypass existing protections to the asset that are\n\t\tonly performed against the primary path."
    },
    "1300": {
      "title": "Improper Protection of Physical Side Channels",
      "description": "The device does not contain sufficient protection\n\tmechanisms to prevent physical side channels from exposing\n\tsensitive information due to patterns in physically observable\n\tphenomena such as variations in power consumption,\n\telectromagnetic emissions (EME), or acoustic emissions."
    },
    "1301": {
      "title": "Insufficient or Incomplete Data Removal within Hardware Component",
      "description": "The product's data removal process does not completely delete all data and potentially sensitive information within hardware components."
    },
    "1302": {
      "title": "Missing Security Identifier",
      "description": "The product implements a security identifier mechanism to differentiate what actions are allowed or disallowed when a transaction originates from an entity. A transaction is sent without a security identifier."
    },
    "1303": {
      "title": "Non-Transparent Sharing of Microarchitectural Resources",
      "description": "Hardware structures shared across execution contexts (e.g., caches and branch predictors) can violate the expected architecture isolation between contexts."
    },
    "1304": {
      "title": "Improperly Preserved Integrity of Hardware Configuration State During a Power Save/Restore Operation",
      "description": "The product performs a power save/restore\n            operation, but it does not ensure that the integrity of\n            the configuration state is maintained and/or verified between\n\t    the beginning and ending of the operation."
    },
    "1310": {
      "title": "Missing Ability to Patch ROM Code",
      "description": "Missing an ability to patch ROM code may leave a System or System-on-Chip (SoC) in a vulnerable state."
    },
    "1311": {
      "title": "Improper Translation of Security Attributes by Fabric Bridge",
      "description": "The bridge incorrectly translates security attributes from either trusted to untrusted or from untrusted to trusted when converting from one fabric protocol to another."
    },
    "1312": {
      "title": "Missing Protection for Mirrored Regions in On-Chip Fabric Firewall",
      "description": "The firewall in an on-chip fabric protects the main addressed region, but it does not protect any mirrored memory or memory-mapped-IO (MMIO) regions."
    },
    "1313": {
      "title": "Hardware Allows Activation of Test or Debug Logic at Runtime",
      "description": "During runtime, the hardware allows for test or debug logic (feature) to be activated, which allows for changing the state of the hardware. This feature can alter the intended behavior of the system and allow for alteration and leakage of sensitive data by an adversary."
    },
    "1314": {
      "title": "Missing Write Protection for Parametric Data Values",
      "description": "The device does not write-protect the parametric data values for sensors that scale the sensor value, allowing untrusted software to manipulate the apparent result and potentially damage hardware or cause operational failure."
    },
    "1315": {
      "title": "Improper Setting of Bus Controlling Capability in Fabric End-point",
      "description": "The bus controller enables bits in the fabric end-point to allow responder devices to control transactions on the fabric."
    },
    "1316": {
      "title": "Fabric-Address Map Allows Programming of Unwarranted Overlaps of Protected and Unprotected Ranges",
      "description": "The address map of the on-chip fabric has protected and unprotected regions overlapping, allowing an attacker to bypass access control to the overlapping portion of the protected region."
    },
    "1317": {
      "title": "Improper Access Control in Fabric Bridge",
      "description": "The product uses a fabric bridge for transactions between two Intellectual Property (IP) blocks, but the bridge does not properly perform the expected privilege, identity, or other access control checks between those IP blocks."
    },
    "1318": {
      "title": "Missing Support for Security Features in On-chip Fabrics or Buses",
      "description": "On-chip fabrics or buses either do not support or are not configured to support privilege separation or other security features, such as access control."
    },
    "1319": {
      "title": "Improper Protection against Electromagnetic Fault Injection (EM-FI)",
      "description": "The device is susceptible to electromagnetic fault injection attacks, causing device internal information to be compromised or security mechanisms to be bypassed."
    },
    "1320": {
      "title": "Improper Protection for Outbound Error Messages and Alert Signals",
      "description": "Untrusted agents can disable alerts about signal conditions exceeding limits or the response mechanism that handles such alerts."
    },
    "1321": {
      "title": "Improperly Controlled Modification of Object Prototype Attributes ('Prototype Pollution')",
      "description": "The product receives input from an upstream component that specifies attributes that are to be initialized or updated in an object, but it does not properly control modifications of attributes of the object prototype."
    },
    "1322": {
      "title": "Use of Blocking Code in Single-threaded, Non-blocking Context",
      "description": "The product uses a non-blocking model that relies on a single threaded process\n\t\t\tfor features such as scalability, but it contains code that can block when it is invoked."
    },
    "1323": {
      "title": "Improper Management of Sensitive Trace Data",
      "description": "Trace data collected from several sources on the\n                System-on-Chip (SoC) is stored in unprotected locations or\n                transported to untrusted agents."
    },
    "1324": {
      "title": "DEPRECATED: Sensitive Information Accessible by Physical Probing of JTAG Interface",
      "description": "This entry has been deprecated because it was at a lower level of abstraction than supported by CWE. All relevant content has been integrated into CWE-319."
    },
    "1325": {
      "title": "Improperly Controlled Sequential Memory Allocation",
      "description": "The product manages a group of objects or resources and performs a separate memory allocation for each object, but it does not properly limit the total amount of memory that is consumed by all of the combined objects."
    },
    "1326": {
      "title": "Missing Immutable Root of Trust in Hardware",
      "description": "A missing immutable root of trust in the hardware results in the ability to bypass secure boot or execute untrusted or adversarial boot code."
    },
    "1327": {
      "title": "Binding to an Unrestricted IP Address",
      "description": "The product assigns the address 0.0.0.0 for a database server, a cloud service/instance, or any computing resource that communicates remotely."
    },
    "1328": {
      "title": "Security Version Number Mutable to Older Versions",
      "description": "Security-version number in hardware is mutable, resulting in the ability to downgrade (roll-back) the boot firmware to vulnerable code versions."
    },
    "1329": {
      "title": "Reliance on Component That is Not Updateable",
      "description": "The product contains a component that cannot be updated or patched in order to remove vulnerabilities or significant bugs."
    },
    "1330": {
      "title": "Remanent Data Readable after Memory Erase",
      "description": "Confidential information stored in memory circuits is readable or recoverable after being cleared or erased."
    },
    "1331": {
      "title": "Improper Isolation of Shared Resources in Network On Chip (NoC)",
      "description": "The Network On Chip (NoC) does not isolate or incorrectly isolates its on-chip-fabric and internal resources such that they are shared between trusted and untrusted agents, creating timing channels."
    },
    "1332": {
      "title": "Improper Handling of Faults that Lead to Instruction Skips",
      "description": "The device is missing or incorrectly implements circuitry or sensors that detect and mitigate the skipping of security-critical CPU instructions when they occur."
    },
    "1333": {
      "title": "Inefficient Regular Expression Complexity",
      "description": "The product uses a regular expression with an inefficient, possibly exponential worst-case computational complexity that consumes excessive CPU cycles."
    },
    "1334": {
      "title": "Unauthorized Error Injection Can Degrade Hardware Redundancy",
      "description": "An unauthorized agent can inject errors into a redundant block to deprive the system of redundancy or put the system in a degraded operating mode."
    },
    "1335": {
      "title": "Incorrect Bitwise Shift of Integer",
      "description": "An integer value is specified to be shifted by a negative amount or an amount greater than or equal to the number of bits contained in the value causing an unexpected or indeterminate result."
    },
    "1336": {
      "title": "Improper Neutralization of Special Elements Used in a Template Engine",
      "description": "The product uses a template engine to insert or process externally-influenced input, but it does not neutralize or incorrectly neutralizes special elements or syntax that can be interpreted as template expressions or other code directives when processed by the engine."
    },
    "1338": {
      "title": "Improper Protections Against Hardware Overheating",
      "description": "A hardware device is missing or has inadequate protection features to prevent overheating."
    },
    "1339": {
      "title": "Insufficient Precision or Accuracy of a Real Number",
      "description": "The product processes a real number with an implementation in which the number's representation does not preserve required accuracy and precision in its fractional part, causing an incorrect result."
    },
    "1341": {
      "title": "Multiple Releases of Same Resource or Handle",
      "description": "The product attempts to close or release a resource or handle more than once, without any successful open between the close operations."
    },
    "1342": {
      "title": "Information Exposure through Microarchitectural State after Transient Execution",
      "description": "The processor does not properly clear microarchitectural state after incorrect microcode assists or speculative execution, resulting in transient execution."
    },
    "1351": {
      "title": "Improper Handling of Hardware Behavior in Exceptionally Cold Environments",
      "description": "A hardware device, or the firmware running on it, is\n                missing or has incorrect protection features to maintain\n                goals of security primitives when the device is cooled below\n                standard operating temperatures."
    },
    "1357": {
      "title": "Reliance on Insufficiently Trustworthy Component",
      "description": "The product is built from multiple separate components, but it uses a component that is not sufficiently trusted to meet expectations for security, reliability, updateability, and maintainability."
    },
    "1384": {
      "title": "Improper Handling of Physical or Environmental Conditions",
      "description": "The product does not properly handle unexpected physical or environmental conditions that occur naturally or are artificially induced."
    },
    "1385": {
      "title": "Missing Origin Validation in WebSockets",
      "description": "The product uses a WebSocket, but it does not properly verify that the source of data or communication is valid."
    },
    "1386": {
      "title": "Insecure Operation on Windows Junction / Mount Point",
      "description": "The product opens a file or directory, but it does not properly prevent the name from being associated with a junction or mount point to a destination that is outside of the intended control sphere."
    },
    "1389": {
      "title": "Incorrect Parsing of Numbers with Different Radices",
      "description": "The product parses numeric input assuming base 10 (decimal) values, but it does not account for inputs that use a different base number (radix)."
    },
    "1390": {
      "title": "Weak Authentication",
      "description": "The product uses an authentication mechanism to restrict access to specific users or identities, but the mechanism does not sufficiently prove that the claimed identity is correct."
    },
    "1391": {
      "title": "Use of Weak Credentials",
      "description": "The product uses weak credentials (such as a default key or hard-coded password) that can be calculated, derived, reused, or guessed by an attacker."
    },
    "1392": {
      "title": "Use of Default Credentials",
      "description": "The product uses default credentials (such as passwords or cryptographic keys) for potentially critical functionality."
    },
    "1393": {
      "title": "Use of Default Password",
      "description": "The product uses default passwords for potentially critical functionality."
    },
    "1394": {
      "title": "Use of Default Cryptographic Key",
      "description": "The product uses a default cryptographic key for potentially critical functionality."
    },
    "1395": {
      "title": "Dependency on Vulnerable Third-Party Component",
      "description": "The product has a dependency on a third-party component that contains one or more known vulnerabilities."
    },
    "unknown": {
      "title": "No CWE associated"
    }
  },
  "sonarsourceSecurity": {
    "buffer-overflow": {
      "title": "Buffer Overflow"
    },
    "sql-injection": {
      "title": "SQL Injection"
    },
    "rce": {
      "title": "Code Injection (RCE)"
    },
    "object-injection": {
      "title": "Object Injection"
    },
    "command-injection": {
      "title": "Command Injection"
    },
    "path-traversal-injection": {
      "title": "Path Traversal Injection"
    },
    "ldap-injection": {
      "title": "LDAP Injection"
    },
    "xpath-injection": {
      "title": "XPath Injection"
    },
    "expression-lang-injection": {
      "title": "Expression Language Injection"
    },
    "log-injection": {
      "title": "Log Injection"
    },
    "xxe": {
      "title": "XML External Entity (XXE)"
    },
    "xss": {
      "title": "Cross-Site Scripting (XSS)"
    },
    "dos": {
      "title": "Denial of Service (DoS)"
    },
    "ssrf": {
      "title": "Server-Side Request Forgery (SSRF)"
    },
    "csrf": {
      "title": "Cross-Site Request Forgery (CSRF)"
    },
    "http-response-splitting": {
      "title": "HTTP Response Splitting"
    },
    "open-redirect": {
      "title": "Open Redirect"
    },
    "weak-cryptography": {
      "title": "Weak Cryptography"
    },
    "auth": {
      "title": "Authentication"
    },
    "insecure-conf": {
      "title": "Insecure Configuration"
    },
    "file-manipulation": {
      "title": "File Manipulation"
    },
    "encrypt-data": {
      "title": "Encryption of Sensitive Data"
    },
    "traceability": {
      "title": "Traceability"
    },
    "permission": {
      "title": "Permission"
    },
    "others": {
      "title": "Others"
    }
  },
  "pciDss-3.2": {
    "1": {
      "title": "Install and maintain a firewall configuration to protect cardholder data"
    },
    "2": {
      "title": "Do not use vendor-supplied defaults for system passwords and other security parameters"
    },
    "3": {
      "title": "Protect stored cardholder data"
    },
    "4": {
      "title": "Encrypt transmission of cardholder data across open, public networks"
    },
    "5": {
      "title": "Protect all systems against malware and regularly update anti-virus software or programs"
    },
    "6": {
      "title": "Develop and maintain secure systems and applications"
    },
    "7": {
      "title": "Restrict access to cardholder data by business need to know"
    },
    "8": {
      "title": "Identify and authenticate access to system components"
    },
    "9": {
      "title": "Restrict physical access to cardholder data"
    },
    "10": {
      "title": "Track and monitor all access to network resources and cardholder data"
    },
    "11": {
      "title": "Regularly test security systems and processes"
    },
    "12": {
      "title": "Maintain a policy that addresses information security for all personnel"
    }
  },
  "pciDss-4.0": {
    "1": {
      "title": "Install and Maintain Network Security Controls"
    },
    "2": {
      "title": "Apply Secure Configurations to All System Components"
    },
    "3": {
      "title": "Protect Stored Account Data"
    },
    "4": {
      "title": "Protect Cardholder Data with Strong Cryptography During Transmission Over Open, Public Networks"
    },
    "5": {
      "title": "Protect All Systems and Networks from Malicious Software Sections"
    },
    "6": {
      "title": "Develop and Maintain Secure Systems and Software"
    },
    "7": {
      "title": "Restrict Access to System Components and Cardholder Data by Business Need to Know"
    },
    "8": {
      "title": "Identify Users and Authenticate Access to System Components"
    },
    "9": {
      "title": "Restrict Physical Access to Cardholder Data"
    },
    "10": {
      "title": "Log and Monitor All Access to System Components and Cardholder Data"
    },
    "11": {
      "title": "Test Security of Systems and Networks Regularly"
    },
    "12": {
      "title": "Support Information Security with Organizational Policies and Programs"
    }
  },
  "owaspAsvs-4.0": {
    "1": {
      "title": "Architecture, Design And Threat Modelling"
    },
    "2": {
      "title": "Authentication"
    },
    "3": {
      "title": "Session Management"
    },
    "4": {
      "title": "Access Control"
    },
    "5": {
      "title": "Malicious Input Handling"
    },
    "6": {
      "title": "Cryptography At Rest"
    },
    "7": {
      "title": "Error Handling And Logging"
    },
    "8": {
      "title": "Data Protection"
    },
    "9": {
      "title": "Communications Security"
    },
    "10": {
      "title": "Malicious Controls"
    },
    "11": {
      "title": "Business Logic"
    },
    "12": {
      "title": "Files And Resources"
    },
    "13": {
      "title": "Web Services"
    },
    "14": {
      "title": "Configuration"
    },
    "1.1.1": {
      "title": "Verify the use of a secure software development lifecycle that addresses security in all stages of development.",
      "level": "2"
    },
    "1.1.2": {
      "title": "Verify the use of threat modeling for every design change or sprint planning to identify threats, plan for countermeasures, facilitate appropriate risk responses, and guide security testing.",
      "level": "2"
    },
    "1.1.3": {
      "title": "Verify that all user stories and features contain functional security constraints, such as \"As a user,I should be able to view and edit my profile.I should not be able to view or edit anyone else 's profile\"",
      "level": "2"
    },
    "1.1.4": {
      "title": "Verify documentation and justification of all the application's trust boundaries, components, and significant data flows.",
      "level": "2"
    },
    "1.1.5": {
      "title": "Verify definition and security analysis of the application's high-level architecture and all connected remote services.",
      "level": "2"
    },
    "1.1.6": {
      "title": "Verify implementation of centralized, simple (economy of design), vetted, secure, and reusable security controls to avoid duplicate, missing, ineffective, or insecure controls.",
      "level": "2"
    },
    "1.1.7": {
      "title": "Verify availability of a secure coding checklist, security requirements, guideline, or policy to all developers and testers.",
      "level": "2"
    },
    "1.10.1": {
      "title": "Verify that a source code control system is in use, with procedures to ensure that check-ins are accompanied by issues or change tickets. The source code control system should have access control and identifiable users to allow traceability of any changes.",
      "level": "2"
    },
    "1.11.1": {
      "title": "Verify the definition and documentation of all application components in terms of the business or security functions they provide.",
      "level": "2"
    },
    "1.11.2": {
      "title": "Verify that all high-value business logic flows, including authentication, session management and access control, do not share unsynchronized state.",
      "level": "2"
    },
    "1.11.3": {
      "title": "Verify that all high-value business logic flows, including authentication, session management and access control are thread safe and resistant to time-of-check and time-of-use race conditions.",
      "level": "3"
    },
    "1.12.1": {
      "title": "Verify that user-uploaded files are stored outside of the web root.",
      "level": "2"
    },
    "1.12.2": {
      "title": "Verify that user-uploaded files - if required to be displayed or downloaded from the application - are served by either octet stream downloads, or from an unrelated domain, such as a cloud file storage bucket. Implement a suitable content security policy to reduce the risk from XSS vectors or other attacks from the uploaded file.",
      "level": "2"
    },
    "1.14.1": {
      "title": "Verify the segregation of components of differing trust levels through well-defined security controls, firewall rules, API gateways, reverse proxies, cloud-based security groups, or similar mechanisms.",
      "level": "2"
    },
    "1.14.2": {
      "title": "Verify that if deploying binaries to untrusted devices makes use of binary signatures, trusted connections, and verified endpoints.",
      "level": "2"
    },
    "1.14.3": {
      "title": "Verify that the build pipeline warns of out-of-date or insecure components and takes appropriate actions.",
      "level": "2"
    },
    "1.14.4": {
      "title": "Verify that the build pipeline contains a build step to automatically build and verify the secure deployment of the application, particularly if the application infrastructure is software defined, such as cloud environment build scripts.",
      "level": "2"
    },
    "1.14.5": {
      "title": "Verify that application deployments adequately sandbox, containerize and/or isolate at the network level to delay and deter attackers from attacking other applications, especially when they are performing sensitive or dangerous actions such as deserialization.",
      "level": "2"
    },
    "1.14.6": {
      "title": "Verify the application does not use unsupported, insecure, or deprecated client-side technologies such as NSAPI plugins, Flash, Shockwave, ActiveX, Silverlight, NACL, or client-side Java applets.",
      "level": "2"
    },
    "1.2.1": {
      "title": "Verify the use of unique or special low-privilege operating system accounts for all application components, services, and servers.",
      "level": "2"
    },
    "1.2.2": {
      "title": "Verify that communications between application components, including APIs, middleware and data layers, are authenticated. Components should have the least necessary privileges needed.",
      "level": "2"
    },
    "1.2.3": {
      "title": "Verify that the application uses a single vetted authentication mechanism that is known to be secure, can be extended to include strong authentication, and has sufficient logging and monitoring to detect account abuse or breaches.",
      "level": "2"
    },
    "1.2.4": {
      "title": "Verify that all authentication pathways and identity management APIs implement consistent authentication security control strength, such that there are no weaker alternatives per the risk of the application.",
      "level": "2"
    },
    "1.4.1": {
      "title": "Verify that trusted enforcement points such as at access control gateways, servers, and serverless functions enforce access controls. Never enforce access controls on the client.",
      "level": "2"
    },
    "1.4.2": {
      "title": "Verify that the chosen access control solution is flexible enough to meet the application's needs.",
      "level": "2"
    },
    "1.4.3": {
      "title": "Verify enforcement of the principle of least privilege in functions, data files, URLs, controllers, services, and other resources. This implies protection against spoofing and elevation of privilege.",
      "level": "2"
    },
    "1.4.4": {
      "title": "Verify the application uses a single and well-vetted access control mechanism for accessing protected data and resources. All requests must pass through this single mechanism to avoid copy and paste or insecure alternative paths.",
      "level": "2"
    },
    "1.4.5": {
      "title": "Verify that attribute or feature-based access control is used whereby the code checks the user's authorization for a feature/data item rather than just their role. Permissions should still be allocated using roles.",
      "level": "2"
    },
    "1.5.1": {
      "title": "Verify that input and output requirements clearly define how to handle and process data based on type, content, and applicable laws, regulations, and other policy compliance.",
      "level": "2"
    },
    "1.5.2": {
      "title": "Verify that serialization is not used when communicating with untrusted clients. If this is not possible, ensure that adequate integrity controls (and possibly encryption if sensitive data is sent) are enforced to prevent deserialization attacks including object injection.",
      "level": "2"
    },
    "1.5.3": {
      "title": "Verify that input validation is enforced on a trusted service layer.",
      "level": "2"
    },
    "1.5.4": {
      "title": "Verify that output encoding occurs close to or by the interpreter for which it is intended.",
      "level": "2"
    },
    "1.6.1": {
      "title": "Verify that there is an explicit policy for management of cryptographic keys and that a cryptographic key lifecycle follows a key management standard such as NIST SP 800-57.",
      "level": "2"
    },
    "1.6.2": {
      "title": "Verify that consumers of cryptographic services protect key material and other secrets by using key vaults or API based alternatives.",
      "level": "2"
    },
    "1.6.3": {
      "title": "Verify that all keys and passwords are replaceable and are part of a well-defined process to re-encrypt sensitive data.",
      "level": "2"
    },
    "1.6.4": {
      "title": "Verify that symmetric keys, passwords, or API secrets generated by or shared with clients are used only in protecting low risk secrets, such as encrypting local storage, or temporary ephemeral uses such as parameter obfuscation. Sharing secrets with clients is clear-text equivalent and architecturally should be treated as such.",
      "level": "2"
    },
    "1.7.1": {
      "title": "Verify that a common logging format and approach is used across the system. ",
      "level": "2"
    },
    "1.7.2": {
      "title": "Verify that logs are securely transmitted to a preferably remote system for analysis, detection, alerting, and escalation.",
      "level": "2"
    },
    "1.8.1": {
      "title": "Verify that all sensitive data is identified and classified into protection levels.",
      "level": "2"
    },
    "1.8.2": {
      "title": "Verify that all protection levels have an associated set of protection requirements, such as encryption requirements, integrity requirements, retention, privacy and other confidentiality requirements, and that these are applied in the architecture.",
      "level": "2"
    },
    "1.9.1": {
      "title": "Verify the application encrypts communications between components, particularly when these components are in different containers, systems, sites, or cloud providers.",
      "level": "2"
    },
    "1.9.2": {
      "title": "Verify that application components verify the authenticity of each side in a communication link to prevent person-in-the-middle attacks. For example, application components should validate TLS certificates and chains.",
      "level": "2"
    },
    "2.1.1": {
      "title": "Verify that user set passwords are at least 12 characters in length.",
      "level": "1"
    },
    "2.1.10": {
      "title": "Verify that there are no periodic credential rotation or password history requirements.",
      "level": "1"
    },
    "2.1.11": {
      "title": "Verify that \"paste\" functionality, browser password helpers, and external password managers are permitted.",
      "level": "1"
    },
    "2.1.12": {
      "title": "Verify that the user can choose to either temporarily view the entire masked password, or temporarily view the last typed character of the password on platforms that do not have this as native functionality.",
      "level": "1"
    },
    "2.1.2": {
      "title": "Verify that passwords 64 characters or longer are permitted.",
      "level": "1"
    },
    "2.1.3": {
      "title": "Verify that passwords can contain spaces and truncation is not performed. Consecutive multiple spaces MAY optionally be coalesced.",
      "level": "1"
    },
    "2.1.4": {
      "title": "Verify that Unicode characters are permitted in passwords. A single Unicode code point is considered a character, so 12 emoji or 64 kanji characters should be valid and permitted.",
      "level": "1"
    },
    "2.1.5": {
      "title": "Verify users can change their password.",
      "level": "1"
    },
    "2.1.6": {
      "title": "Verify that password change functionality requires the user's current and new password.",
      "level": "1"
    },
    "2.1.7": {
      "title": "Verify that passwords submitted during account registration, login, and password change are checked against a set of breached passwords either locally (such as the top 1,000 or 10,000 most common passwords which match the system's password policy) or using an external API. If using an API a zero knowledge proof or other mechanism should be used to ensure that the plain text password is not sent or used in verifying the breach status of the password. If the password is breached, the application must require the user to set a new non-breached password.",
      "level": "1"
    },
    "2.1.8": {
      "title": "Verify that a password strength meter is provided to help users set a stronger password.",
      "level": "1"
    },
    "2.1.9": {
      "title": "Verify that there are no password composition rules limiting the type of characters permitted. There should be no requirement for upper or lower case or numbers or special characters.",
      "level": "1"
    },
    "2.10.1": {
      "title": "Verify that integration secrets do not rely on unchanging passwords, such as API keys or shared privileged accounts.",
      "level": "1"
    },
    "2.10.2": {
      "title": "Verify that if passwords are required, the credentials are not a default account.",
      "level": "1"
    },
    "2.10.3": {
      "title": "Verify that passwords are stored with sufficient protection to prevent offline recovery attacks, including local system access.",
      "level": "1"
    },
    "2.10.4": {
      "title": "Verify passwords, integrations with databases and third-party systems, seeds and internal secrets, and API keys are managed securely and not included in the source code or stored within source code repositories. Such storage SHOULD resist offline attacks. The use of a secure software key store (L1), hardware trusted platform module (TPM), or a hardware security module (L3) is recommended for password storage.",
      "level": "1"
    },
    "2.2.1": {
      "title": "Verify that anti-automation controls are effective at mitigating breached credential testing, brute force, and account lockout attacks. Such controls include blocking the most common breached passwords, soft lockouts, rate limiting, CAPTCHA, ever increasing delays between attempts, IP address restrictions, or risk-based restrictions such as location, first login on a device, recent attempts to unlock the account, or similar. Verify that no more than 100 failed attempts per hour is possible on a single account.",
      "level": "1"
    },
    "2.2.2": {
      "title": "Verify that the use of weak authenticators (such as SMS and email) is limited to secondary verification and transaction approval and not as a replacement for more secure authentication methods. Verify that stronger methods are offered before weak methods, users are aware of the risks, or that proper measures are in place to limit the risks of account compromise.",
      "level": "1"
    },
    "2.2.3": {
      "title": "Verify that secure notifications are sent to users after updates to authentication details, such as credential resets, email or address changes, logging in from unknown or risky locations. The use of push notifications - rather than SMS or email - is preferred, but in the absence of push notifications, SMS or email is acceptable as long as no sensitive information is disclosed in the notification.",
      "level": "1"
    },
    "2.2.4": {
      "title": "Verify impersonation resistance against phishing, such as the use of multi-factor authentication, cryptographic devices with intent (such as connected keys with a push to authenticate), or at higher AAL levels, client-side certificates.",
      "level": "3"
    },
    "2.2.5": {
      "title": "Verify that where a credential service provider (CSP) and the application verifying authentication are separated, mutually authenticated TLS is in place between the two endpoints.",
      "level": "3"
    },
    "2.2.6": {
      "title": "Verify replay resistance through the mandated use of OTP devices, cryptographic authenticators, or lookup codes.",
      "level": "3"
    },
    "2.2.7": {
      "title": "Verify intent to authenticate by requiring the entry of an OTP token or user-initiated action such as a button press on a FIDO hardware key.",
      "level": "3"
    },
    "2.3.1": {
      "title": "Verify system generated initial passwords or activation codes SHOULD be securely randomly generated, SHOULD be at least 6 characters long, and MAY contain letters and numbers, and expire after a short period of time. These initial secrets must not be permitted to become the long term password.",
      "level": "1"
    },
    "2.3.2": {
      "title": "Verify that enrollment and use of subscriber-provided authentication devices are supported, such as a U2F or FIDO tokens.",
      "level": "2"
    },
    "2.3.3": {
      "title": "Verify that renewal instructions are sent with sufficient time to renew time bound authenticators.",
      "level": "2"
    },
    "2.4.1": {
      "title": "Verify that passwords are stored in a form that is resistant to offline attacks. Passwords SHALL be salted and hashed using an approved one-way key derivation or password hashing function. Key derivation and password hashing functions take a password, a salt, and a cost factor as inputs when generating a password hash.",
      "level": "2"
    },
    "2.4.2": {
      "title": "Verify that the salt is at least 32 bits in length and be chosen arbitrarily to minimize salt value collisions among stored hashes. For each credential, a unique salt value and the resulting hash SHALL be stored.",
      "level": "2"
    },
    "2.4.3": {
      "title": "Verify that if PBKDF2 is used, the iteration count SHOULD be as large as verification server performance will allow, typically at least 100,000 iterations.",
      "level": "2"
    },
    "2.4.4": {
      "title": "Verify that if bcrypt is used, the work factor SHOULD be as large as verification server performance will allow, typically at least 13.",
      "level": "2"
    },
    "2.4.5": {
      "title": "Verify that an additional iteration of a key derivation function is performed, using a salt value that is secret and known only to the verifier. Generate the salt value using an approved random bit generator [SP 800-90Ar1] and provide at least the minimum security strength specified in the latest revision of SP 800-131A. The secret salt value SHALL be stored separately from the hashed passwords (e.g., in a specialized device like a hardware security module).",
      "level": "2"
    },
    "2.5.1": {
      "title": "Verify that a system generated initial activation or recovery secret is not sent in clear text to the user.",
      "level": "1"
    },
    "2.5.2": {
      "title": "Verify password hints or knowledge-based authentication (so-called \"secret questions\") are not present.",
      "level": "1"
    },
    "2.5.3": {
      "title": "Verify password credential recovery does not reveal the current password in any way.",
      "level": "1"
    },
    "2.5.4": {
      "title": "Verify shared or default accounts are not present (e.g. \"root\", \"admin\", or \"sa\").",
      "level": "1"
    },
    "2.5.5": {
      "title": "Verify that if an authentication factor is changed or replaced, that the user is notified of this event.",
      "level": "1"
    },
    "2.5.6": {
      "title": "Verify forgotten password, and other recovery paths use a secure recovery mechanism, such as TOTP or other soft token, mobile push, or another offline recovery mechanism.",
      "level": "1"
    },
    "2.5.7": {
      "title": "Verify that if OTP or multi-factor authentication factors are lost, that evidence of identity proofing is performed at the same level as during enrollment.",
      "level": "2"
    },
    "2.6.1": {
      "title": "Verify that lookup secrets can be used only once.",
      "level": "2"
    },
    "2.6.2": {
      "title": "Verify that lookup secrets have sufficient randomness (112 bits of entropy), or if less than 112 bits of entropy, salted with a unique and random 32-bit salt and hashed with an approved one-way hash.",
      "level": "2"
    },
    "2.6.3": {
      "title": "Verify that lookup secrets are resistant to offline attacks, such as predictable values.",
      "level": "2"
    },
    "2.7.1": {
      "title": "Verify that clear text out of band (NIST \"restricted\") authenticators, such as SMS or PSTN, are not offered by default, and stronger alternatives such as push notifications are offered first.",
      "level": "1"
    },
    "2.7.2": {
      "title": "Verify that the out of band verifier expires out of band authentication requests, codes, or tokens after 10 minutes.",
      "level": "1"
    },
    "2.7.3": {
      "title": "Verify that the out of band verifier authentication requests, codes, or tokens are only usable once, and only for the original authentication request.",
      "level": "1"
    },
    "2.7.4": {
      "title": "Verify that the out of band authenticator and verifier communicates over a secure independent channel.",
      "level": "1"
    },
    "2.7.5": {
      "title": "Verify that the out of band verifier retains only a hashed version of the authentication code.",
      "level": "2"
    },
    "2.7.6": {
      "title": "Verify that the initial authentication code is generated by a secure random number generator, containing at least 20 bits of entropy (typically a six digital random number is sufficient).",
      "level": "2"
    },
    "2.8.1": {
      "title": "Verify that time-based OTPs have a defined lifetime before expiring.",
      "level": "1"
    },
    "2.8.2": {
      "title": "Verify that symmetric keys used to verify submitted OTPs are highly protected, such as by using a hardware security module or secure operating system based key storage.",
      "level": "2"
    },
    "2.8.3": {
      "title": "Verify that approved cryptographic algorithms are used in the generation, seeding, and verification.",
      "level": "2"
    },
    "2.8.4": {
      "title": "Verify that time-based OTP can be used only once within the validity period.",
      "level": "2"
    },
    "2.8.5": {
      "title": "Verify that if a time-based multi factor OTP token is re-used during the validity period, it is logged and rejected with secure notifications being sent to the holder of the device.",
      "level": "2"
    },
    "2.8.6": {
      "title": "Verify physical single factor OTP generator can be revoked in case of theft or other loss. Ensure that revocation is immediately effective across logged in sessions, regardless of location.",
      "level": "2"
    },
    "2.8.7": {
      "title": "Verify that biometric authenticators are limited to use only as secondary factors in conjunction with either something you have and something you know.",
      "level": "3"
    },
    "2.9.1": {
      "title": "Verify that cryptographic keys used in verification are stored securely and protected against disclosure, such as using a TPM or HSM, or an OS service that can use this secure storage.",
      "level": "2"
    },
    "2.9.2": {
      "title": "Verify that the challenge nonce is at least 64 bits in length, and statistically unique or unique over the lifetime of the cryptographic device.",
      "level": "2"
    },
    "2.9.3": {
      "title": "Verify that approved cryptographic algorithms are used in the generation, seeding, and verification.",
      "level": "2"
    },
    "3.1.1": {
      "title": "Verify the application never reveals session tokens in URL parameters or error messages.",
      "level": "1"
    },
    "3.2.1": {
      "title": "Verify the application generates a new session token on user authentication.",
      "level": "1"
    },
    "3.2.2": {
      "title": "Verify that session tokens possess at least 64 bits of entropy.",
      "level": "1"
    },
    "3.2.3": {
      "title": "Verify the application only stores session tokens in the browser using secure methods such as appropriately secured cookies (see section 3.4) or HTML 5 session storage.",
      "level": "1"
    },
    "3.2.4": {
      "title": "Verify that session token are generated using approved cryptographic algorithms.",
      "level": "2"
    },
    "3.3.1": {
      "title": "Verify that logout and expiration invalidate the session token, such that the back button or a downstream relying party does not resume an authenticated session, including across relying parties.",
      "level": "1"
    },
    "3.3.2": {
      "title": "If authenticators permit users to remain logged in, verify that re-authentication occurs periodically both when actively used or after an idle period.",
      "level": "1"
    },
    "3.3.3": {
      "title": "Verify that the application terminates all other active sessions after a successful password change, and that this is effective across the application, federated login (if present), and any relying parties.",
      "level": "2"
    },
    "3.3.4": {
      "title": "Verify that users are able to view and log out of any or all currently active sessions and devices.",
      "level": "2"
    },
    "3.4.1": {
      "title": "Verify that cookie-based session tokens have the 'Secure' attribute set.",
      "level": "1"
    },
    "3.4.2": {
      "title": "Verify that cookie-based session tokens have the 'HttpOnly' attribute set.",
      "level": "1"
    },
    "3.4.3": {
      "title": "Verify that cookie-based session tokens utilize the 'SameSite' attribute to limit exposure to cross-site request forgery attacks.",
      "level": "1"
    },
    "3.4.4": {
      "title": "Verify that cookie-based session tokens use \"__Host-\" prefix (see references) to provide session cookie confidentiality.",
      "level": "1"
    },
    "3.4.5": {
      "title": "Verify that if the application is published under a domain name with other applications that set or use session cookies that might override or disclose the session cookies, set the path attribute in cookie-based session tokens using the most precise path possible.",
      "level": "1"
    },
    "3.5.1": {
      "title": "Verify the application does not treat OAuth and refresh tokens &mdash; on their own &mdash; as the presence of the subscriber and allows users to terminate trust relationships with linked applications.",
      "level": "2"
    },
    "3.5.2": {
      "title": "Verify the application uses session tokens rather than static API secrets and keys, except with legacy implementations.",
      "level": "2"
    },
    "3.5.3": {
      "title": "Verify that stateless session tokens use digital signatures, encryption, and other countermeasures to protect against tampering, enveloping, replay, null cipher, and key substitution attacks.",
      "level": "2"
    },
    "3.6.1": {
      "title": "Verify that relying parties specify the maximum authentication time to CSPs and that CSPs re-authenticate the subscriber if they haven't used a session within that period.",
      "level": "3"
    },
    "3.6.2": {
      "title": "Verify that CSPs inform relying parties of the last authentication event, to allow RPs to determine if they need to re-authenticate the user.",
      "level": "3"
    },
    "3.7.1": {
      "title": "Verify the application ensures a valid login session or requires re-authentication or secondary verification before allowing any sensitive transactions or account modifications.",
      "level": "1"
    },
    "4.1.1": {
      "title": "Verify that the application enforces access control rules on a trusted service layer, especially if client-side access control is present and could be bypassed.",
      "level": "1"
    },
    "4.1.2": {
      "title": "Verify that all user and data attributes and policy information used by access controls cannot be manipulated by end users unless specifically authorized.",
      "level": "1"
    },
    "4.1.3": {
      "title": "Verify that the principle of least privilege exists - users should only be able to access functions, data files, URLs, controllers, services, and other resources, for which they possess specific authorization. This implies protection against spoofing and elevation of privilege.",
      "level": "1"
    },
    "4.1.4": {
      "title": "Verify that the principle of deny by default exists whereby new users/roles start with minimal or no permissions and users/roles do not receive access to new features until access is explicitly assigned. ",
      "level": "1"
    },
    "4.1.5": {
      "title": "Verify that access controls fail securely including when an exception occurs.",
      "level": "1"
    },
    "4.2.1": {
      "title": "Verify that sensitive data and APIs are protected against direct object attacks targeting creation, reading, updating and deletion of records, such as creating or updating someone else's record, viewing everyone's records, or deleting all records.",
      "level": "1"
    },
    "4.2.2": {
      "title": "Verify that the application or framework enforces a strong anti-CSRF mechanism to protect authenticated functionality, and effective anti-automation or anti-CSRF protects unauthenticated functionality.",
      "level": "1"
    },
    "4.3.1": {
      "title": "Verify administrative interfaces use appropriate multi-factor authentication to prevent unauthorized use.",
      "level": "1"
    },
    "4.3.2": {
      "title": "Verify that directory browsing is disabled unless deliberately desired. Additionally, applications should not allow discovery or disclosure of file or directory metadata, such as Thumbs.db, .DS_Store, .git or .svn folders.",
      "level": "1"
    },
    "4.3.3": {
      "title": "Verify the application has additional authorization (such as step up or adaptive authentication) for lower value systems, and / or segregation of duties for high value applications to enforce anti-fraud controls as per the risk of application and past fraud.",
      "level": "2"
    },
    "5.1.1": {
      "title": "Verify that the application has defenses against HTTP parameter pollution attacks, particularly if the application framework makes no distinction about the source of request parameters (GET, POST, cookies, headers, or environment variables).",
      "level": "1"
    },
    "5.1.2": {
      "title": "Verify that frameworks protect against mass parameter assignment attacks, or that the application has countermeasures to protect against unsafe parameter assignment, such as marking fields private or similar.",
      "level": "1"
    },
    "5.1.3": {
      "title": "Verify that all input (HTML form fields, REST requests, URL parameters, HTTP headers, cookies, batch files, RSS feeds, etc) is validated using positive validation (whitelisting).",
      "level": "1"
    },
    "5.1.4": {
      "title": "Verify that structured data is strongly typed and validated against a defined schema including allowed characters, length and pattern (e.g. credit card numbers or telephone, or validating that two related fields are reasonable, such as checking that suburb and zip/postcode match).",
      "level": "1"
    },
    "5.1.5": {
      "title": "Verify that URL redirects and forwards only allow whitelisted destinations, or show a warning when redirecting to potentially untrusted content.",
      "level": "1"
    },
    "5.2.1": {
      "title": "Verify that all untrusted HTML input from WYSIWYG editors or similar is properly sanitized with an HTML sanitizer library or framework feature.",
      "level": "1"
    },
    "5.2.2": {
      "title": "Verify that unstructured data is sanitized to enforce safety measures such as allowed characters and length.",
      "level": "1"
    },
    "5.2.3": {
      "title": "Verify that the application sanitizes user input before passing to mail systems to protect against SMTP or IMAP injection.",
      "level": "1"
    },
    "5.2.4": {
      "title": "Verify that the application avoids the use of eval() or other dynamic code execution features. Where there is no alternative, any user input being included must be sanitized or sandboxed before being executed.",
      "level": "1"
    },
    "5.2.5": {
      "title": "Verify that the application protects against template injection attacks by ensuring that any user input being included is sanitized or sandboxed.",
      "level": "1"
    },
    "5.2.6": {
      "title": "Verify that the application protects against SSRF attacks, by validating or sanitizing untrusted data or HTTP file metadata, such as filenames and URL input fields, use whitelisting of protocols, domains, paths and ports.",
      "level": "1"
    },
    "5.2.7": {
      "title": "Verify that the application sanitizes, disables, or sandboxes user-supplied SVG scriptable content, especially as they relate to XSS resulting from inline scripts, and foreignObject.",
      "level": "1"
    },
    "5.2.8": {
      "title": "Verify that the application sanitizes, disables, or sandboxes user-supplied scriptable or expression template language content, such as Markdown, CSS or XSL stylesheets, BBCode, or similar.",
      "level": "1"
    },
    "5.3.1": {
      "title": "Verify that output encoding is relevant for the interpreter and context required. For example, use encoders specifically for HTML values, HTML attributes, JavaScript, URL Parameters, HTTP headers, SMTP, and others as the context requires, especially from untrusted inputs (e.g. names with Unicode or apostrophes, such as ねこ or O'Hara).",
      "level": "1"
    },
    "5.3.10": {
      "title": "Verify that the application protects against XPath injection or XML injection attacks.",
      "level": "1"
    },
    "5.3.2": {
      "title": "Verify that output encoding preserves the user's chosen character set and locale, such that any Unicode character point is valid and safely handled.",
      "level": "1"
    },
    "5.3.3": {
      "title": "Verify that context-aware, preferably automated - or at worst, manual - output escaping protects against reflected, stored, and DOM based XSS.",
      "level": "1"
    },
    "5.3.4": {
      "title": "Verify that data selection or database queries (e.g. SQL, HQL, ORM, NoSQL) use parameterized queries, ORMs, entity frameworks, or are otherwise protected from database injection attacks.",
      "level": "1"
    },
    "5.3.5": {
      "title": "Verify that where parameterized or safer mechanisms are not present, context-specific output encoding is used to protect against injection attacks, such as the use of SQL escaping to protect against SQL injection.",
      "level": "1"
    },
    "5.3.6": {
      "title": "Verify that the application projects against JavaScript or JSON injection attacks, including for eval attacks, remote JavaScript includes, CSP bypasses, DOM XSS, and JavaScript expression evaluation.",
      "level": "1"
    },
    "5.3.7": {
      "title": "Verify that the application protects against LDAP Injection vulnerabilities, or that specific security controls to prevent LDAP Injection have been implemented.",
      "level": "1"
    },
    "5.3.8": {
      "title": "Verify that the application protects against OS command injection and that operating system calls use parameterized OS queries or use contextual command line output encoding.",
      "level": "1"
    },
    "5.3.9": {
      "title": "Verify that the application protects against Local File Inclusion (LFI) or Remote File Inclusion (RFI) attacks.",
      "level": "1"
    },
    "5.4.1": {
      "title": "Verify that the application uses memory-safe string, safer memory copy and pointer arithmetic to detect or prevent stack, buffer, or heap overflows.",
      "level": "2"
    },
    "5.4.2": {
      "title": "Verify that format strings do not take potentially hostile input, and are constant.",
      "level": "2"
    },
    "5.4.3": {
      "title": "Verify that sign, range, and input validation techniques are used to prevent integer overflows.",
      "level": "2"
    },
    "5.5.1": {
      "title": "Verify that serialized objects use integrity checks or are encrypted to prevent hostile object creation or data tampering.",
      "level": "1"
    },
    "5.5.2": {
      "title": "Verify that the application correctly restricts XML parsers to only use the most restrictive configuration possible and to ensure that unsafe features such as resolving external entities are disabled to prevent XXE.",
      "level": "1"
    },
    "5.5.3": {
      "title": "Verify that deserialization of untrusted data is avoided or is protected in both custom code and third-party libraries (such as JSON, XML and YAML parsers).",
      "level": "1"
    },
    "5.5.4": {
      "title": "Verify that when parsing JSON in browsers or JavaScript-based backends, JSON.parse is used to parse the JSON document. Do not use eval() to parse JSON.",
      "level": "1"
    },
    "6.1.1": {
      "title": "Verify that regulated private data is stored encrypted while at rest, such as personally identifiable information (PII), sensitive personal information, or data assessed likely to be subject to EU's GDPR.",
      "level": "2"
    },
    "6.1.2": {
      "title": "Verify that regulated health data is stored encrypted while at rest, such as medical records, medical device details, or de-anonymized research records.",
      "level": "2"
    },
    "6.1.3": {
      "title": "Verify that regulated financial data is stored encrypted while at rest, such as financial accounts, defaults or credit history, tax records, pay history, beneficiaries, or de-anonymized market or research records.",
      "level": "2"
    },
    "6.2.1": {
      "title": "Verify that all cryptographic modules fail securely, and errors are handled in a way that does not enable Padding Oracle attacks.",
      "level": "1"
    },
    "6.2.2": {
      "title": "Verify that industry proven or government approved cryptographic algorithms, modes, and libraries are used, instead of custom coded cryptography.",
      "level": "2"
    },
    "6.2.3": {
      "title": "Verify that encryption initialization vector, cipher configuration, and block modes are configured securely using the latest advice.",
      "level": "2"
    },
    "6.2.4": {
      "title": "Verify that random number, encryption or hashing algorithms, key lengths, rounds, ciphers or modes, can be reconfigured, upgraded, or swapped at any time, to protect against cryptographic breaks.",
      "level": "2"
    },
    "6.2.5": {
      "title": "Verify that known insecure block modes (i.e. ECB, etc.), padding modes (i.e. PKCS#1 v1.5, etc.), ciphers with small block sizes (i.e. Triple-DES, Blowfish, etc.), and weak hashing algorithms (i.e. MD5, SHA1, etc.) are not used unless required for backwards compatibility.",
      "level": "2"
    },
    "6.2.6": {
      "title": "Verify that nonces, initialization vectors, and other single use numbers must not be used more than once with a given encryption key. The method of generation must be appropriate for the algorithm being used.",
      "level": "2"
    },
    "6.2.7": {
      "title": "Verify that encrypted data is authenticated via signatures, authenticated cipher modes, or HMAC to ensure that ciphertext is not altered by an unauthorized party.",
      "level": "3"
    },
    "6.2.8": {
      "title": "Verify that all cryptographic operations are constant-time, with no 'short-circuit' operations in comparisons, calculations, or returns, to avoid leaking information.",
      "level": "3"
    },
    "6.3.1": {
      "title": "Verify that all random numbers, random file names, random GUIDs, and random strings are generated using the cryptographic module's approved cryptographically secure random number generator when these random values are intended to be not guessable by an attacker.",
      "level": "2"
    },
    "6.3.2": {
      "title": "Verify that random GUIDs are created using the GUID v4 algorithm, and a cryptographically-secure pseudo-random number generator (CSPRNG). GUIDs created using other pseudo-random number generators may be predictable.",
      "level": "2"
    },
    "6.3.3": {
      "title": "Verify that random numbers are created with proper entropy even when the application is under heavy load, or that the application degrades gracefully in such circumstances.",
      "level": "3"
    },
    "6.4.1": {
      "title": "Verify that a secrets management solution such as a key vault is used to securely create, store, control access to and destroy secrets.",
      "level": "2"
    },
    "6.4.2": {
      "title": "Verify that key material is not exposed to the application but instead uses an isolated security module like a vault for cryptographic operations.",
      "level": "2"
    },
    "7.1.1": {
      "title": "Verify that the application does not log credentials or payment details. Session tokens should only be stored in logs in an irreversible, hashed form.",
      "level": "1"
    },
    "7.1.2": {
      "title": "Verify that the application does not log other sensitive data as defined under local privacy laws or relevant security policy.",
      "level": "1"
    },
    "7.1.3": {
      "title": "Verify that the application logs security relevant events including successful and failed authentication events, access control failures, deserialization failures and input validation failures.",
      "level": "2"
    },
    "7.1.4": {
      "title": "Verify that each log event includes necessary information that would allow for a detailed investigation of the timeline when an event happens.",
      "level": "2"
    },
    "7.2.1": {
      "title": "Verify that all authentication decisions are logged, without storing sensitive session identifiers or passwords. This should include requests with relevant metadata needed for security investigations.",
      "level": "2"
    },
    "7.2.2": {
      "title": "Verify that all access control decisions can be logged and all failed decisions are logged. This should include requests with relevant metadata needed for security investigations.",
      "level": "2"
    },
    "7.3.1": {
      "title": "Verify that the application appropriately encodes user-supplied data to prevent log injection.",
      "level": "2"
    },
    "7.3.2": {
      "title": "Verify that all events are protected from injection when viewed in log viewing software.",
      "level": "2"
    },
    "7.3.3": {
      "title": "Verify that security logs are protected from unauthorized access and modification.",
      "level": "2"
    },
    "7.3.4": {
      "title": "Verify that time sources are synchronized to the correct time and time zone. Strongly consider logging only in UTC if systems are global to assist with post-incident forensic analysis.",
      "level": "2"
    },
    "7.4.1": {
      "title": "Verify that a generic message is shown when an unexpected or security sensitive error occurs, potentially with a unique ID which support personnel can use to investigate.",
      "level": "1"
    },
    "7.4.2": {
      "title": "Verify that exception handling (or a functional equivalent) is used across the codebase to account for expected and unexpected error conditions.",
      "level": "2"
    },
    "7.4.3": {
      "title": "Verify that a \"last resort\" error handler is defined which will catch all unhandled exceptions.",
      "level": "2"
    },
    "8.1.1": {
      "title": "Verify the application protects sensitive data from being cached in server components such as load balancers and application caches.",
      "level": "2"
    },
    "8.1.2": {
      "title": "Verify that all cached or temporary copies of sensitive data stored on the server are protected from unauthorized access or purged/invalidated after the authorized user accesses the sensitive data.",
      "level": "2"
    },
    "8.1.3": {
      "title": "Verify the application minimizes the number of parameters in a request, such as hidden fields, Ajax variables, cookies and header values.",
      "level": "2"
    },
    "8.1.4": {
      "title": "Verify the application can detect and alert on abnormal numbers of requests, such as by IP, user, total per hour or day, or whatever makes sense for the application.",
      "level": "2"
    },
    "8.1.5": {
      "title": "Verify that regular backups of important data are performed and that test restoration of data is performed.",
      "level": "3"
    },
    "8.1.6": {
      "title": "Verify that backups are stored securely to prevent data from being stolen or corrupted.",
      "level": "3"
    },
    "8.2.1": {
      "title": "Verify the application sets sufficient anti-caching headers so that sensitive data is not cached in modern browsers.",
      "level": "1"
    },
    "8.2.2": {
      "title": "Verify that data stored in client side storage (such as HTML5 local storage, session storage, IndexedDB, regular cookies or Flash cookies) does not contain sensitive data or PII.",
      "level": "1"
    },
    "8.2.3": {
      "title": "Verify that authenticated data is cleared from client storage, such as the browser DOM, after the client or session is terminated.",
      "level": "1"
    },
    "8.3.1": {
      "title": "Verify that sensitive data is sent to the server in the HTTP message body or headers, and that query string parameters from any HTTP verb do not contain sensitive data.",
      "level": "1"
    },
    "8.3.2": {
      "title": "Verify that users have a method to remove or export their data on demand.",
      "level": "1"
    },
    "8.3.3": {
      "title": "Verify that users are provided clear language regarding collection and use of supplied personal information and that users have provided opt-in consent for the use of that data before it is used in any way.",
      "level": "1"
    },
    "8.3.4": {
      "title": "Verify that all sensitive data created and processed by the application has been identified, and ensure that a policy is in place on how to deal with sensitive data.",
      "level": "1"
    },
    "8.3.5": {
      "title": "Verify accessing sensitive data is audited (without logging the sensitive data itself), if the data is collected under relevant data protection directives or where logging of access is required.",
      "level": "2"
    },
    "8.3.6": {
      "title": "Verify that sensitive information contained in memory is overwritten as soon as it is no longer required to mitigate memory dumping attacks, using zeroes or random data.",
      "level": "2"
    },
    "8.3.7": {
      "title": "Verify that sensitive or private information that is required to be encrypted, is encrypted using approved algorithms that provide both confidentiality and integrity.",
      "level": "2"
    },
    "8.3.8": {
      "title": "Verify that sensitive personal information is subject to data retention classification, such that old or out of date data is deleted automatically, on a schedule, or as the situation requires.",
      "level": "2"
    },
    "9.1.1": {
      "title": "Verify that secured TLS is used for all client connectivity, and does not fall back to insecure or unencrypted protocols.",
      "level": "1"
    },
    "9.1.2": {
      "title": "Verify using online or up to date TLS testing tools that only strong algorithms, ciphers, and protocols are enabled, with the strongest algorithms and ciphers set as preferred.",
      "level": "1"
    },
    "9.1.3": {
      "title": "Verify that old versions of SSL and TLS protocols, algorithms, ciphers, and configuration are disabled, such as SSLv2, SSLv3, or TLS 1.0 and TLS 1.1. The latest version of TLS should be the preferred cipher suite.",
      "level": "1"
    },
    "9.2.1": {
      "title": "Verify that connections to and from the server use trusted TLS certificates. Where internally generated or self-signed certificates are used, the server must be configured to only trust specific internal CAs and specific self-signed certificates. All others should be rejected.",
      "level": "2"
    },
    "9.2.2": {
      "title": "Verify that encrypted communications such as TLS is used for all inbound and outbound connections, including for management ports, monitoring, authentication, API, or web service calls, database, cloud, serverless, mainframe, external, and partner connections. The server must not fall back to insecure or unencrypted protocols.",
      "level": "2"
    },
    "9.2.3": {
      "title": "Verify that all encrypted connections to external systems that involve sensitive information or functions are authenticated.",
      "level": "2"
    },
    "9.2.4": {
      "title": "Verify that proper certification revocation, such as Online Certificate Status Protocol (OCSP) Stapling, is enabled and configured.",
      "level": "2"
    },
    "9.2.5": {
      "title": "Verify that backend TLS connection failures are logged.",
      "level": "3"
    },
    "10.1.1": {
      "title": "Verify that a code analysis tool is in use that can detect potentially malicious code, such as time functions, unsafe file operations and network connections.",
      "level": "3"
    },
    "10.2.1": {
      "title": "Verify that the application source code and third party libraries do not contain unauthorized phone home or data collection capabilities. Where such functionality exists, obtain the user's permission for it to operate  before collecting any data.",
      "level": "2"
    },
    "10.2.2": {
      "title": "Verify that the application does not ask for unnecessary or excessive permissions to privacy related features or sensors, such as contacts, cameras, microphones, or location.",
      "level": "2"
    },
    "10.2.3": {
      "title": "Verify that the application source code and third party libraries do not contain back doors, such as hard-coded or additional undocumented accounts or keys, code obfuscation, undocumented binary blobs, rootkits, or anti-debugging, insecure debugging features, or otherwise out of date, insecure, or hidden functionality that could be used maliciously if discovered.",
      "level": "3"
    },
    "10.2.4": {
      "title": "Verify that the application source code and third party libraries does not contain time bombs by searching for date and time related functions.",
      "level": "3"
    },
    "10.2.5": {
      "title": "Verify that the application source code and third party libraries does not contain malicious code, such as salami attacks, logic bypasses, or logic bombs.",
      "level": "3"
    },
    "10.2.6": {
      "title": "Verify that the application source code and third party libraries do not contain Easter eggs or any other potentially unwanted functionality.",
      "level": "3"
    },
    "10.3.1": {
      "title": "Verify that if the application has a client or server auto-update feature, updates should be obtained over secure channels and digitally signed. The update code must validate the digital signature of the update before installing or executing the update.",
      "level": "1"
    },
    "10.3.2": {
      "title": "Verify that the application employs integrity protections, such as code signing or sub-resource integrity. The application must not load or execute code from untrusted sources, such as loading includes, modules, plugins, code, or libraries from untrusted sources or the Internet.",
      "level": "1"
    },
    "10.3.3": {
      "title": "Verify that the application has protection from sub-domain takeovers if the application relies upon DNS entries or DNS sub-domains, such as expired domain names, out of date DNS pointers or CNAMEs, expired projects at public source code repos, or transient cloud APIs, serverless functions, or storage buckets (autogen-bucket-id.cloud.example.com) or similar. Protections can include ensuring that DNS names used by applications are regularly checked for expiry or change.",
      "level": "1"
    },
    "11.1.1": {
      "title": "Verify the application will only process business logic flows for the same user in sequential step order and without skipping steps.",
      "level": "1"
    },
    "11.1.2": {
      "title": "Verify the application will only process business logic flows with all steps being processed in realistic human time, i.e. transactions are not submitted too quickly.",
      "level": "1"
    },
    "11.1.3": {
      "title": "Verify the application has appropriate limits for specific business actions or transactions which are correctly enforced on a per user basis.",
      "level": "1"
    },
    "11.1.4": {
      "title": "Verify the application has sufficient anti-automation controls to detect and protect against data exfiltration, excessive business logic requests, excessive file uploads or denial of service attacks.",
      "level": "1"
    },
    "11.1.5": {
      "title": "Verify the application has business logic limits or validation to protect against likely business risks or threats, identified using threat modelling or similar methodologies.",
      "level": "1"
    },
    "11.1.6": {
      "title": "Verify the application does not suffer from \"time of check to time of use\" (TOCTOU) issues or other race conditions for sensitive operations.",
      "level": "2"
    },
    "11.1.7": {
      "title": "Verify the application monitors for unusual events or activity from a business logic perspective. For example, attempts to perform actions out of order or actions which a normal user would never attempt.",
      "level": "2"
    },
    "11.1.8": {
      "title": "Verify the application has configurable alerting when automated attacks or unusual activity is detected.",
      "level": "2"
    },
    "12.1.1": {
      "title": "Verify that the application will not accept large files that could fill up storage or cause a denial of service attack.",
      "level": "1"
    },
    "12.1.2": {
      "title": "Verify that compressed files are checked for \"zip bombs\" - small input files that will decompress into huge files thus exhausting file storage limits.",
      "level": "2"
    },
    "12.1.3": {
      "title": "Verify that a file size quota and maximum number of files per user is enforced to ensure that a single user cannot fill up the storage with too many files, or excessively large files.",
      "level": "2"
    },
    "12.2.1": {
      "title": "Verify that files obtained from untrusted sources are validated to be of expected type based on the file's content.",
      "level": "2"
    },
    "12.3.1": {
      "title": "Verify that user-submitted filename metadata is not used directly with system or framework file and URL API to protect against path traversal.",
      "level": "1"
    },
    "12.3.2": {
      "title": "Verify that user-submitted filename metadata is validated or ignored to prevent the disclosure, creation, updating or removal of local files (LFI).",
      "level": "1"
    },
    "12.3.3": {
      "title": "Verify that user-submitted filename metadata is validated or ignored to prevent the disclosure or execution of remote files (RFI), which may also lead to SSRF.",
      "level": "1"
    },
    "12.3.4": {
      "title": "Verify that the application protects against reflective file download (RFD) by validating or ignoring user-submitted filenames in a JSON, JSONP, or URL parameter, the response Content-Type header should be set to text/plain, and the Content-Disposition header should have a fixed filename.",
      "level": "1"
    },
    "12.3.5": {
      "title": "Verify that untrusted file metadata is not used directly with system API or libraries, to protect against OS command injection.",
      "level": "1"
    },
    "12.3.6": {
      "title": "Verify that the application does not include and execute functionality from untrusted sources, such as unverified content distribution networks, JavaScript libraries, node npm libraries, or server-side DLLs.",
      "level": "2"
    },
    "12.4.1": {
      "title": "Verify that files obtained from untrusted sources are stored outside the web root, with limited permissions, preferably with strong validation.",
      "level": "1"
    },
    "12.4.2": {
      "title": "Verify that files obtained from untrusted sources are scanned by antivirus scanners to prevent upload of known malicious content.",
      "level": "1"
    },
    "12.5.1": {
      "title": "Verify that the web tier is configured to serve only files with specific file extensions to prevent unintentional information and source code leakage. For example, backup files (e.g. .bak), temporary working files (e.g. .swp), compressed files (.zip, .tar.gz, etc) and other extensions commonly used by editors should be blocked unless required.",
      "level": "1"
    },
    "12.5.2": {
      "title": "Verify that direct requests to uploaded files will never be executed as HTML/JavaScript content.",
      "level": "1"
    },
    "12.6.1": {
      "title": "Verify that the web or application server is configured with a whitelist of resources or systems to which the server can send requests or load data/files from.",
      "level": "1"
    },
    "13.1.1": {
      "title": "Verify that all application components use the same encodings and parsers to avoid parsing attacks that exploit different URI or file parsing behavior that could be used in SSRF and RFI attacks.",
      "level": "1"
    },
    "13.1.2": {
      "title": "Verify that access to administration and management functions is limited to authorized administrators.",
      "level": "1"
    },
    "13.1.3": {
      "title": "Verify API URLs do not expose sensitive information, such as the API key, session tokens etc.",
      "level": "1"
    },
    "13.1.4": {
      "title": "Verify that authorization decisions are made at both the URI, enforced by programmatic or declarative security at the controller or router, and at the resource level, enforced by model-based permissions.",
      "level": "2"
    },
    "13.1.5": {
      "title": "Verify that requests containing unexpected or missing content types are rejected with appropriate headers (HTTP response status 406 Unacceptable or 415 Unsupported Media Type).",
      "level": "2"
    },
    "13.2.1": {
      "title": "Verify that enabled RESTful HTTP methods are a valid choice for the user or action, such as preventing normal users using DELETE or PUT on protected API or resources.",
      "level": "1"
    },
    "13.2.2": {
      "title": "Verify that JSON schema validation is in place and verified before accepting input.",
      "level": "1"
    },
    "13.2.3": {
      "title": "Verify that RESTful web services that utilize cookies are protected from Cross-Site Request Forgery via the use of at least one or more of the following: triple or double submit cookie pattern",
      "level": "1"
    },
    "13.2.4": {
      "title": "Verify that REST services have anti-automation controls to protect against excessive calls, especially if the API is unauthenticated.",
      "level": "2"
    },
    "13.2.5": {
      "title": "Verify that REST services explicitly check the incoming Content-Type to be the expected one, such as application/xml or application/JSON.",
      "level": "2"
    },
    "13.2.6": {
      "title": "Verify that the message headers and payload are trustworthy and not modified in transit. Requiring strong encryption for transport (TLS only) may be sufficient in many cases as it provides both confidentiality and integrity protection. Per-message digital signatures can provide additional assurance on top of the transport protections for high-security applications but bring with them additional complexity and risks to weigh against the benefits.",
      "level": "2"
    },
    "13.3.1": {
      "title": "Verify that XSD schema validation takes place to ensure a properly formed XML document, followed by validation of each input field before any processing of that data takes place.",
      "level": "1"
    },
    "13.3.2": {
      "title": "Verify that the message payload is signed using WS-Security to ensure reliable transport between client and service.",
      "level": "2"
    },
    "13.4.1": {
      "title": "Verify that query whitelisting or a combination of depth limiting and amount limiting should be used to prevent GraphQL or data layer expression denial of service (DoS) as a result of expensive, nested queries. For more advanced scenarios, query cost analysis should be used.",
      "level": "2"
    },
    "13.4.2": {
      "title": "Verify that GraphQL or other data layer authorization logic should be implemented at the business logic layer instead of the GraphQL layer.",
      "level": "2"
    },
    "14.1.1": {
      "title": "Verify that the application build and deployment processes are performed in a secure and repeatable way, such as CI / CD automation, automated configuration management, and automated deployment scripts.",
      "level": "2"
    },
    "14.1.2": {
      "title": "Verify that compiler flags are configured to enable all available buffer overflow protections and warnings, including stack randomization, data execution prevention, and to break the build if an unsafe pointer, memory, format string, integer, or string operations are found.",
      "level": "2"
    },
    "14.1.3": {
      "title": "Verify that server configuration is hardened as per the recommendations of the application server and frameworks in use.",
      "level": "2"
    },
    "14.1.4": {
      "title": "Verify that the application, configuration, and all dependencies can be re-deployed using automated deployment scripts, built from a documented and tested runbook in a reasonable time, or restored from backups in a timely fashion.",
      "level": "2"
    },
    "14.1.5": {
      "title": "Verify that authorized administrators can verify the integrity of all security-relevant configurations to detect tampering.",
      "level": "3"
    },
    "14.2.1": {
      "title": "Verify that all components are up to date, preferably using a dependency checker during build or compile time.",
      "level": "1"
    },
    "14.2.2": {
      "title": "Verify that all unneeded features, documentation, samples, configurations are removed, such as sample applications, platform documentation, and default or example users.",
      "level": "1"
    },
    "14.2.3": {
      "title": "Verify that if application assets, such as JavaScript libraries, CSS stylesheets or web fonts, are hosted externally on a content delivery network (CDN) or external provider, Subresource Integrity (SRI) is used to validate the integrity of the asset.",
      "level": "1"
    },
    "14.2.4": {
      "title": "Verify that third party components come from pre-defined, trusted and continually maintained repositories.",
      "level": "2"
    },
    "14.2.5": {
      "title": "Verify that an inventory catalog is maintained of all third party libraries in use.",
      "level": "2"
    },
    "14.2.6": {
      "title": "Verify that the attack surface is reduced by sandboxing or encapsulating third party libraries to expose only the required behaviour into the application.",
      "level": "2"
    },
    "14.3.1": {
      "title": "Verify that web or application server and framework error messages are configured to deliver user actionable, customized responses to eliminate any unintended security disclosures.",
      "level": "1"
    },
    "14.3.2": {
      "title": "Verify that web or application server and application framework debug modes are disabled in production to eliminate debug features, developer consoles, and unintended security disclosures.",
      "level": "1"
    },
    "14.3.3": {
      "title": "Verify that the HTTP headers or any part of the HTTP response do not expose detailed version information of system components.",
      "level": "1"
    },
    "14.4.1": {
      "title": "Verify that every HTTP response contains a content type header specifying a safe character set (e.g., UTF-8, ISO 8859-1).",
      "level": "1"
    },
    "14.4.2": {
      "title": "Verify that all API responses contain Content-Disposition: attachment; filename=\"api.json\" (or other appropriate filename for the content type).",
      "level": "1"
    },
    "14.4.3": {
      "title": "Verify that a content security policy (CSPv2) is in place that helps mitigate impact for XSS attacks like HTML, DOM, JSON, and JavaScript injection vulnerabilities.",
      "level": "1"
    },
    "14.4.4": {
      "title": "Verify that all responses contain X-Content-Type-Options: nosniff.",
      "level": "1"
    },
    "14.4.5": {
      "title": "Verify that HTTP Strict Transport Security headers are included on all responses and for all subdomains, such as Strict-Transport-Security: max-age=15724800; includeSubdomains.",
      "level": "1"
    },
    "14.4.6": {
      "title": "Verify that a suitable \"Referrer-Policy\" header is included, such as \"no-referrer\" or \"same-origin\".",
      "level": "1"
    },
    "14.4.7": {
      "title": "Verify that a suitable X-Frame-Options or Content-Security-Policy: frame-ancestors header is in use for sites where content should not be embedded in a third-party site.",
      "level": "1"
    },
    "14.5.1": {
      "title": "Verify that the application server only accepts the HTTP methods in use by the application or API, including pre-flight OPTIONS.",
      "level": "1"
    },
    "14.5.2": {
      "title": "Verify that the supplied Origin header is not used for authentication or access control decisions, as the Origin header can easily be changed by an attacker.",
      "level": "1"
    },
    "14.5.3": {
      "title": "Verify that the cross-domain resource sharing (CORS) Access-Control-Allow-Origin header uses a strict white-list of trusted domains to match against and does not support the \"null\" origin.",
      "level": "1"
    },
    "14.5.4": {
      "title": "Verify that HTTP headers added by a trusted proxy or SSO devices, such as a bearer token, are authenticated by the application.",
      "level": "2"
    }
  },
  "stig-ASD_V5R3": {
    "V-222387": {
        "title": "The application must provide a capability to limit the number of logon sessions per user."
    },
    "V-222388": {
        "title": "The application must clear temporary storage and cookies when the session is terminated."
    },
    "V-222389": {
        "title": "The application must automatically terminate the non-privileged user session and log off non-privileged users after a 15 minute idle time period has elapsed."
    },
    "V-222390": {
        "title": "The application must automatically terminate the admin user session and log off admin users after a 10 minute idle time period is exceeded."
    },
    "V-222391": {
        "title": "Applications requiring user access authentication must provide a logoff capability for user initiated communication session."
    },
    "V-222392": {
        "title": "The application must display an explicit logoff message to users indicating the reliable termination of authenticated communications sessions."
    },
    "V-222393": {
        "title": "The application must associate organization-defined types of security attributes having organization-defined security attribute values with information in storage."
    },
    "V-222394": {
        "title": "The application must associate organization-defined types of security attributes having organization-defined security attribute values with information in process."
    },
    "V-222395": {
        "title": "The application must associate organization-defined types of security attributes having organization-defined security attribute values with information in transmission."
    },
    "V-222396": {
        "title": "The application must implement DoD-approved encryption to protect the confidentiality of remote access sessions."
    },
    "V-222397": {
        "title": "The application must implement cryptographic mechanisms to protect the integrity of remote access sessions."
    },
    "V-222398": {
        "title": "Applications with SOAP messages requiring integrity must include the following message elements:-Message ID-Service Request-Timestamp-SAML Assertion (optionally included in messages) and all elements of the message must be digitally signed."
    },
    "V-222399": {
        "title": "Messages protected with WS_Security must use time stamps with creation and expiration times."
    },
    "V-222400": {
        "title": "Validity periods must be verified on all application messages using WS-Security or SAML assertions."
    },
    "V-222401": {
        "title": "The application must ensure each unique asserting party provides unique assertion ID references for each SAML assertion."
    },
    "V-222402": {
        "title": "The application must ensure encrypted assertions, or equivalent confidentiality protections are used when assertion data is passed through an intermediary, and confidentiality of the assertion data is required when passing through the intermediary."
    },
    "V-222403": {
        "title": "The application must use the NotOnOrAfter condition when using the SubjectConfirmation element in a SAML assertion."
    },
    "V-222404": {
        "title": "The application must use both the NotBefore and NotOnOrAfter elements or OneTimeUse element when using the Conditions element in a SAML assertion."
    },
    "V-222405": {
        "title": "The application must ensure if a OneTimeUse element is used in an assertion, there is only one of the same used in the Conditions element portion of an assertion."
    },
    "V-222406": {
        "title": "The application must ensure messages are encrypted when the SessionIndex is tied to privacy data."
    },
    "V-222407": {
        "title": "The application must provide automated mechanisms for supporting account management functions."
    },
    "V-222408": {
        "title": "Shared/group account credentials must be terminated when members leave the group."
    },
    "V-222409": {
        "title": "The application must automatically remove or disable temporary user accounts 72 hours after account creation."
    },
    "V-222410": {
        "title": "The application must have a process, feature or function that prevents removal or disabling of emergency accounts. "
    },
    "V-222411": {
        "title": "The application must automatically disable accounts after a 35 day period of account inactivity."
    },
    "V-222412": {
        "title": "Unnecessary application accounts must be disabled, or deleted."
    },
    "V-222413": {
        "title": "The application must automatically audit account creation."
    },
    "V-222414": {
        "title": "The application must automatically audit account modification."
    },
    "V-222415": {
        "title": "The application must automatically audit account disabling actions."
    },
    "V-222416": {
        "title": "The application must automatically audit account removal actions."
    },
    "V-222417": {
        "title": "The application must notify System Administrators and Information System Security Officers when accounts are created."
    },
    "V-222418": {
        "title": "The application must notify System Administrators and Information System Security Officers when accounts are modified."
    },
    "V-222419": {
        "title": "The application must notify System Administrators and Information System Security Officers of account disabling actions."
    },
    "V-222420": {
        "title": "The application must notify System Administrators and Information System Security Officers of account removal actions."
    },
    "V-222421": {
        "title": "The application must automatically audit account enabling actions."
    },
    "V-222422": {
        "title": "The application must notify System Administrators and Information System Security Officers of account enabling actions."
    },
    "V-222423": {
        "title": "Application data protection requirements must be identified and documented."
    },
    "V-222424": {
        "title": "The application must utilize organization-defined data mining detection techniques for organization-defined data storage objects to adequately detect data mining attempts."
    },
    "V-222425": {
        "title": "The application must enforce approved authorizations for logical access to information and system resources in accordance with applicable access control policies."
    },
    "V-222426": {
        "title": "The application must enforce organization-defined discretionary access control policies over defined subjects and objects."
    },
    "V-222427": {
        "title": "The application must enforce approved authorizations for controlling the flow of information within the system based on organization-defined information flow control policies."
    },
    "V-222428": {
        "title": "The application must enforce approved authorizations for controlling the flow of information between interconnected systems based on organization-defined information flow control policies."
    },
    "V-222429": {
        "title": "The application must prevent non-privileged users from executing privileged functions to include disabling, circumventing, or altering implemented security safeguards/countermeasures."
    },
    "V-222430": {
        "title": "The application must execute without excessive account permissions."
    },
    "V-222431": {
        "title": "The application must audit the execution of privileged functions."
    },
    "V-222432": {
        "title": "The application must enforce the limit of three consecutive invalid logon attempts by a user during a 15 minute time period."
    },
    "V-222433": {
        "title": "The application administrator must follow an approved process to unlock locked user accounts."
    },
    "V-222434": {
        "title": "The application must display the Standard Mandatory DoD Notice and Consent Banner before granting access to the application."
    },
    "V-222435": {
        "title": "The application must retain the Standard Mandatory DoD Notice and Consent Banner on the screen until users acknowledge the usage conditions and take explicit actions to log on for further access."
    },
    "V-222436": {
        "title": "The publicly accessible application must display the Standard Mandatory DoD Notice and Consent Banner before granting access to the application."
    },
    "V-222437": {
        "title": "The application must display the time and date of the users last successful logon."
    },
    "V-222438": {
        "title": "The application must protect against an individual (or process acting on behalf of an individual) falsely denying having performed organization-defined actions to be covered by non-repudiation."
    },
    "V-222439": {
        "title": "For applications providing audit record aggregation, the application must compile audit records from organization-defined information system components into a system-wide audit trail that is time-correlated with an organization-defined level of tolerance for the relationship between time stamps of individual records in the audit trail."
    },
    "V-222441": {
        "title": "The application must provide audit record generation capability for the creation of session IDs."
    },
    "V-222442": {
        "title": "The application must provide audit record generation capability for the destruction of session IDs."
    },
    "V-222443": {
        "title": "The application must provide audit record generation capability for the renewal of session IDs."
    },
    "V-222444": {
        "title": "The application must not write sensitive data into the application logs."
    },
    "V-222445": {
        "title": "The application must provide audit record generation capability for session timeouts."
    },
    "V-222446": {
        "title": "The application must record a time stamp indicating when the event occurred."
    },
    "V-222447": {
        "title": "The application must provide audit record generation capability for HTTP headers including User-Agent, Referer, GET, and POST."
    },
    "V-222448": {
        "title": "The application must provide audit record generation capability for connecting system IP addresses."
    },
    "V-222449": {
        "title": "The application must record the username or user ID of the user associated with the event."
    },
    "V-222450": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to grant privileges occur."
    },
    "V-222451": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to access security objects occur."
    },
    "V-222452": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to access security levels occur."
    },
    "V-222453": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to access categories of information (e.g., classification levels) occur."
    },
    "V-222454": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to modify privileges occur."
    },
    "V-222455": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to modify security objects occur."
    },
    "V-222456": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to modify security levels occur."
    },
    "V-222457": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to modify categories of information (e.g., classification levels) occur."
    },
    "V-222458": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to delete privileges occur."
    },
    "V-222459": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to delete security levels occur."
    },
    "V-222460": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to delete application database security objects occur."
    },
    "V-222461": {
        "title": "The application must generate audit records when successful/unsuccessful attempts to delete categories of information (e.g., classification levels) occur."
    },
    "V-222462": {
        "title": "The application must generate audit records when successful/unsuccessful logon attempts occur."
    },
    "V-222463": {
        "title": "The application must generate audit records for privileged activities or other system-level access."
    },
    "V-222464": {
        "title": "The application must generate audit records showing starting and ending time for user access to the system."
    },
    "V-222465": {
        "title": "The application must generate audit records when successful/unsuccessful accesses to objects occur."
    },
    "V-222466": {
        "title": "The application must generate audit records for all direct access to the information system."
    },
    "V-222467": {
        "title": "The application must generate audit records for all account creations, modifications, disabling, and termination events."
    },
    "V-222468": {
        "title": "The application must initiate session auditing upon startup."
    },
    "V-222469": {
        "title": "The application must log application shutdown events."
    },
    "V-222470": {
        "title": "The application must log destination IP addresses."
    },
    "V-222471": {
        "title": "The application must log user actions involving access to data."
    },
    "V-222472": {
        "title": "The application must log user actions involving changes to data."
    },
    "V-222473": {
        "title": "The application must produce audit records containing information to establish when (date and time) the events occurred."
    },
    "V-222474": {
        "title": "The application must produce audit records containing enough information to establish which component, feature or function of the application triggered the audit event."
    },
    "V-222475": {
        "title": "When using centralized logging; the application must include a unique identifier in order to distinguish itself from other application logs."
    },
    "V-222476": {
        "title": "The application must produce audit records that contain information to establish the outcome of the events."
    },
    "V-222477": {
        "title": "The application must generate audit records containing information that establishes the identity of any individual or process associated with the event."
    },
    "V-222478": {
        "title": "The application must generate audit records containing the full-text recording of privileged commands or the individual identities of group account users."
    },
    "V-222479": {
        "title": "The application must implement transaction recovery logs when transaction based."
    },
    "V-222480": {
        "title": "The application must provide centralized management and configuration of the content to be captured in audit records generated by all application components."
    },
    "V-222481": {
        "title": "The application must off-load audit records onto a different system or media than the system being audited."
    },
    "V-222482": {
        "title": "The application must be configured to write application logs to a centralized log repository."
    },
    "V-222483": {
        "title": "The application must provide an immediate warning to the SA and ISSO (at a minimum) when allocated audit record storage volume reaches 75% of repository maximum audit record storage capacity."
    },
    "V-222484": {
        "title": "Applications categorized as having a moderate or high impact must provide an immediate real-time alert to the SA and ISSO (at a minimum) for all audit failure events."
    },
    "V-222485": {
        "title": "The application must alert the ISSO and SA (at a minimum) in the event of an audit processing failure."
    },
    "V-222486": {
        "title": "The application must shut down by default upon audit failure (unless availability is an overriding concern)."
    },
    "V-222487": {
        "title": "The application must provide the capability to centrally review and analyze audit records from multiple components within the system."
    },
    "V-222488": {
        "title": "The application must provide the capability to filter audit records for events of interest based upon organization-defined criteria."
    },
    "V-222489": {
        "title": "The application must provide an audit reduction capability that supports on-demand reporting requirements."
    },
    "V-222490": {
        "title": "The application must provide an audit reduction capability that supports on-demand audit review and analysis."
    },
    "V-222491": {
        "title": "The application must provide an audit reduction capability that supports after-the-fact investigations of security incidents."
    },
    "V-222492": {
        "title": "The application must provide a report generation capability that supports on-demand audit review and analysis."
    },
    "V-222493": {
        "title": "The application must provide a report generation capability that supports on-demand reporting requirements."
    },
    "V-222494": {
        "title": "The application must provide a report generation capability that supports after-the-fact investigations of security incidents."
    },
    "V-222495": {
        "title": "The application must provide an audit reduction capability that does not alter original content or time ordering of audit records."
    },
    "V-222496": {
        "title": "The application must provide a report generation capability that does not alter original content or time ordering of audit records."
    },
    "V-222497": {
        "title": "The applications must use internal system clocks to generate time stamps for audit records."
    },
    "V-222498": {
        "title": "The application must record time stamps for audit records that can be mapped to Coordinated Universal Time (UTC) or Greenwich Mean Time (GMT)."
    },
    "V-222499": {
        "title": "The application must record time stamps for audit records that meet a granularity of one second for a minimum degree of precision."
    },
    "V-222500": {
        "title": "The application must protect audit information from any type of unauthorized read access."
    },
    "V-222501": {
        "title": "The application must protect audit information from unauthorized modification."
    },
    "V-222502": {
        "title": "The application must protect audit information from unauthorized deletion."
    },
    "V-222503": {
        "title": "The application must protect audit tools from unauthorized access."
    },
    "V-222504": {
        "title": "The application must protect audit tools from unauthorized modification."
    },
    "V-222505": {
        "title": "The application must protect audit tools from unauthorized deletion."
    },
    "V-222506": {
        "title": "The application must back up audit records at least every seven days onto a different system or system component than the system or component being audited."
    },
    "V-222507": {
        "title": "The application must use cryptographic mechanisms to protect the integrity of audit information."
    },
    "V-222508": {
        "title": "Application audit tools must be cryptographically hashed."
    },
    "V-222509": {
        "title": "The integrity of the audit tools must be validated by checking the files for changes in the cryptographic hash value."
    },
    "V-222510": {
        "title": "The application must prohibit user installation of software without explicit privileged status."
    },
    "V-222511": {
        "title": "The application must enforce access restrictions associated with changes to application configuration."
    },
    "V-222512": {
        "title": "The application must audit who makes configuration changes to the application."
    },
    "V-222513": {
        "title": "The application must have the capability to prevent the installation of patches, service packs, or application components without verification the software component has been digitally signed using a certificate that is recognized and approved by the organization."
    },
    "V-222514": {
        "title": "The applications must limit privileges to change the software resident within software libraries."
    },
    "V-222515": {
        "title": "An application vulnerability assessment must be conducted."
    },
    "V-222516": {
        "title": "The application must prevent program execution in accordance with organization-defined policies regarding software program usage and restrictions, and/or rules authorizing the terms and conditions of software program usage."
    },
    "V-222517": {
        "title": "The application must employ a deny-all, permit-by-exception (whitelist) policy to allow the execution of authorized software programs."
    },
    "V-222518": {
        "title": "The application must be configured to disable non-essential capabilities."
    },
    "V-222519": {
        "title": "The application must be configured to use only functions, ports, and protocols permitted to it in the PPSM CAL."
    },
    "V-222520": {
        "title": "The application must require users to reauthenticate when organization-defined circumstances or situations require reauthentication."
    },
    "V-222521": {
        "title": "The application must require devices to reauthenticate when organization-defined circumstances or situations requiring reauthentication."
    },
    "V-222522": {
        "title": "The application must uniquely identify and authenticate organizational users (or processes acting on behalf of organizational users)."
    },
    "V-222523": {
        "title": "The application must use multifactor (Alt. Token) authentication for network access to privileged accounts."
    },
    "V-222524": {
        "title": "The application must accept Personal Identity Verification (PIV) credentials."
    },
    "V-222525": {
        "title": "The application must electronically verify Personal Identity Verification (PIV) credentials."
    },
    "V-222526": {
        "title": "The application must use multifactor (e.g., CAC, Alt. Token) authentication for network access to non-privileged accounts."
    },
    "V-222527": {
        "title": "The application must use multifactor (Alt. Token) authentication for local access to privileged accounts."
    },
    "V-222528": {
        "title": "The application must use multifactor (e.g., CAC, Alt. Token) authentication for local access to non-privileged accounts."
    },
    "V-222529": {
        "title": "The application must ensure users are authenticated with an individual authenticator prior to using a group authenticator."
    },
    "V-222530": {
        "title": "The application must implement replay-resistant authentication mechanisms for network access to privileged accounts."
    },
    "V-222531": {
        "title": "The application must implement replay-resistant authentication mechanisms for network access to non-privileged accounts."
    },
    "V-222532": {
        "title": "The application must utilize mutual authentication when endpoint device non-repudiation protections are required by DoD policy or by the data owner."
    },
    "V-222533": {
        "title": "The application must authenticate all network connected endpoint devices before establishing any connection."
    },
    "V-222534": {
        "title": "Service-Oriented Applications handling non-releasable data must authenticate endpoint devices via mutual SSL/TLS."
    },
    "V-222535": {
        "title": "The application must disable device identifiers after 35 days of inactivity unless a cryptographic certificate is used for authentication."
    },
    "V-222536": {
        "title": "The application must enforce a minimum 15-character password length."
    },
    "V-222537": {
        "title": "The application must enforce password complexity by requiring that at least one upper-case character be used."
    },
    "V-222538": {
        "title": "The application must enforce password complexity by requiring that at least one lower-case character be used."
    },
    "V-222539": {
        "title": "The application must enforce password complexity by requiring that at least one numeric character be used."
    },
    "V-222540": {
        "title": "The application must enforce password complexity by requiring that at least one special character be used."
    },
    "V-222541": {
        "title": "The application must require the change of at least 8 of the total number of characters when passwords are changed."
    },
    "V-222542": {
        "title": "The application must only store cryptographic representations of passwords."
    },
    "V-222543": {
        "title": "The application must transmit only cryptographically-protected passwords."
    },
    "V-222544": {
        "title": "The application must enforce 24 hours/1 day as the minimum password lifetime."
    },
    "V-222545": {
        "title": "The application must enforce a 60-day maximum password lifetime restriction."
    },
    "V-222546": {
        "title": "The application must prohibit password reuse for a minimum of five generations."
    },
    "V-222547": {
        "title": "The application must allow the use of a temporary password for system logons with an immediate change to a permanent password."
    },
    "V-222548": {
        "title": "The application password must not be changeable by users other than the administrator or the user with which the password is associated."
    },
    "V-222549": {
        "title": "The application must terminate existing user sessions upon account deletion."
    },
    "V-222550": {
        "title": "The application, when utilizing PKI-based authentication, must validate certificates by constructing a certification path (which includes status information) to an accepted trust anchor."
    },
    "V-222551": {
        "title": "The application, when using PKI-based authentication, must enforce authorized access to the corresponding private key."
    },
    "V-222552": {
        "title": "The application must map the authenticated identity to the individual user or group account for PKI-based authentication."
    },
    "V-222553": {
        "title": "The application, for PKI-based authentication, must implement a local cache of revocation data to support path discovery and validation in case of the inability to access revocation information via the network."
    },
    "V-222554": {
        "title": "The application must not display passwords/PINs as clear text."
    },
    "V-222555": {
        "title": "The application must use mechanisms meeting the requirements of applicable federal laws, Executive Orders, directives, policies, regulations, standards, and guidance for authentication to a cryptographic module."
    },
    "V-222556": {
        "title": "The application must uniquely identify and authenticate non-organizational users (or processes acting on behalf of non-organizational users)."
    },
    "V-222557": {
        "title": "The application must accept Personal Identity Verification (PIV) credentials from other federal agencies."
    },
    "V-222558": {
        "title": "The application must electronically verify Personal Identity Verification (PIV) credentials from other federal agencies."
    },
    "V-222559": {
        "title": "The application must accept FICAM-approved third-party credentials."
    },
    "V-222560": {
        "title": "The application must conform to FICAM-issued profiles."
    },
    "V-222561": {
        "title": "Applications used for non-local maintenance sessions must audit non-local maintenance and diagnostic sessions for organization-defined auditable events."
    },
    "V-222562": {
        "title": "Applications used for non-local maintenance sessions must implement cryptographic mechanisms to protect the integrity of non-local maintenance and diagnostic communications."
    },
    "V-222563": {
        "title": "Applications used for non-local maintenance sessions must implement cryptographic mechanisms to protect the confidentiality of non-local maintenance and diagnostic communications."
    },
    "V-222564": {
        "title": "Applications used for non-local maintenance sessions must verify remote disconnection at the termination of non-local maintenance and diagnostic sessions."
    },
    "V-222565": {
        "title": "The application must employ strong authenticators in the establishment of non-local maintenance and diagnostic sessions."
    },
    "V-222566": {
        "title": "The application must terminate all sessions and network connections when non-local maintenance is completed."
    },
    "V-222567": {
        "title": "The application must not be vulnerable to race conditions."
    },
    "V-222568": {
        "title": "The application must terminate all network connections associated with a communications session at the end of the session."
    },
    "V-222570": {
        "title": "The application must utilize FIPS-validated cryptographic modules when signing application components."
    },
    "V-222571": {
        "title": "The application must utilize FIPS-validated cryptographic modules when generating cryptographic hashes."
    },
    "V-222572": {
        "title": "The application must utilize FIPS-validated cryptographic modules when protecting unclassified information that requires cryptographic protection."
    },
    "V-222573": {
        "title": "Applications making SAML assertions must use FIPS-approved random numbers in the generation of SessionIndex in the SAML element AuthnStatement."
    },
    "V-222574": {
        "title": "The application user interface must be either physically or logically separated from data storage and management interfaces."
    },
    "V-222575": {
        "title": "The application must set the HTTPOnly flag on session cookies."
    },
    "V-222576": {
        "title": "The application must set the secure flag on session cookies."
    },
    "V-222577": {
        "title": "The application must not expose session IDs."
    },
    "V-222578": {
        "title": "The application must destroy the session ID value and/or cookie on logoff or browser close."
    },
    "V-222579": {
        "title": "Applications must use system-generated session identifiers that protect against session fixation."
    },
    "V-222580": {
        "title": "Applications must validate session identifiers."
    },
    "V-222581": {
        "title": "Applications must not use URL embedded session IDs."
    },
    "V-222582": {
        "title": "The application must not re-use or recycle session IDs."
    },
    "V-222583": {
        "title": "The application must use the Federal Information Processing Standard (FIPS) 140-2-validated cryptographic modules and random number generator if the application implements encryption, key exchange, digital signature, and hash functionality."
    },
    "V-222584": {
        "title": "The application must only allow the use of DoD-approved certificate authorities for verification of the establishment of protected sessions."
    },
    "V-222585": {
        "title": "The application must fail to a secure state if system initialization fails, shutdown fails, or aborts fail."
    },
    "V-222586": {
        "title": "In the event of a system failure, applications must preserve any information necessary to determine cause of failure and any information necessary to return to operations with least disruption to mission processes."
    },
    "V-222587": {
        "title": "The application must protect the confidentiality and integrity of stored information when required by DoD policy or the information owner."
    },
    "V-222588": {
        "title": "The application must implement approved cryptographic mechanisms to prevent unauthorized modification of organization-defined information at rest on organization-defined information system components."
    },
    "V-222589": {
        "title": "The application must use appropriate cryptography in order to protect stored DoD information when required by the information owner or DoD policy."
    },
    "V-222590": {
        "title": "The application must isolate security functions from non-security functions."
    },
    "V-222591": {
        "title": "The application must maintain a separate execution domain for each executing process."
    },
    "V-222592": {
        "title": "Applications must prevent unauthorized and unintended information transfer via shared system resources."
    },
    "V-222593": {
        "title": "XML-based applications must mitigate DoS attacks by using XML filters, parser options, or gateways."
    },
    "V-222594": {
        "title": "The application must restrict the ability to launch Denial of Service (DoS) attacks against itself or other information systems."
    },
    "V-222595": {
        "title": "The web service design must include redundancy mechanisms when used with high-availability systems."
    },
    "V-222596": {
        "title": "The application must protect the confidentiality and integrity of transmitted information."
    },
    "V-222597": {
        "title": "The application must implement cryptographic mechanisms to prevent unauthorized disclosure of information and/or detect changes to information during transmission unless otherwise protected by alternative physical safeguards, such as, at a minimum, a Protected Distribution System (PDS)."
    },
    "V-222598": {
        "title": "The application must maintain the confidentiality and integrity of information during preparation for transmission."
    },
    "V-222599": {
        "title": "The application must maintain the confidentiality and integrity of information during reception."
    },
    "V-222600": {
        "title": "The application must not disclose unnecessary information to users."
    },
    "V-222601": {
        "title": "The application must not store sensitive information in hidden fields."
    },
    "V-222602": {
        "title": "The application must protect from Cross-Site Scripting (XSS) vulnerabilities."
    },
    "V-222603": {
        "title": "The application must protect from Cross-Site Request Forgery (CSRF) vulnerabilities."
    },
    "V-222604": {
        "title": "The application must protect from command injection."
    },
    "V-222605": {
        "title": "The application must protect from canonical representation vulnerabilities."
    },
    "V-222606": {
        "title": "The application must validate all input."
    },
    "V-222607": {
        "title": "The application must not be vulnerable to SQL Injection."
    },
    "V-222608": {
        "title": "The application must not be vulnerable to XML-oriented attacks."
    },
    "V-222609": {
        "title": "The application must not be subject to input handling vulnerabilities."
    },
    "V-222610": {
        "title": "The application must generate error messages that provide information necessary for corrective actions without revealing information that could be exploited by adversaries."
    },
    "V-222611": {
        "title": "The application must reveal error messages only to the ISSO, ISSM, or SA."
    },
    "V-222612": {
        "title": "The application must not be vulnerable to overflow attacks."
    },
    "V-222613": {
        "title": "The application must remove organization-defined software components after updated versions have been installed."
    },
    "V-222614": {
        "title": "Security-relevant software updates and patches must be kept up to date."
    },
    "V-222615": {
        "title": "The application performing organization-defined security functions must verify correct operation of security functions."
    },
    "V-222616": {
        "title": "The application must perform verification of the correct operation of security functions: upon system startup and/or restart; upon command by a user with privileged access; and/or every 30 days."
    },
    "V-222617": {
        "title": "The application must notify the ISSO and ISSM of failed security verification tests."
    },
    "V-222618": {
        "title": "Unsigned Category 1A mobile code must not be used in the application in accordance with DoD policy."
    },
    "V-222619": {
        "title": "The ISSO must ensure an account management process is implemented, verifying only authorized users can gain access to the application, and individual accounts designated as inactive, suspended, or terminated are promptly removed."
    },
    "V-222620": {
        "title": "Application web servers must be on a separate network segment from the application and database servers if it is a tiered application operating in the DoD DMZ."
    },
    "V-222621": {
        "title": "The ISSO must ensure application audit trails are retained for at least 1 year for applications without SAMI data, and 5 years for applications including SAMI data."
    },
    "V-222622": {
        "title": "The ISSO must review audit trails periodically based on system documentation recommendations or immediately upon system security events."
    },
    "V-222623": {
        "title": "The ISSO must report all suspected violations of IA policies in accordance with DoD information system IA procedures."
    },
    "V-222624": {
        "title": "The ISSO must ensure active vulnerability testing is performed."
    },
    "V-222625": {
        "title": "Execution flow diagrams and design documents must be created to show how deadlock and recursion issues in web services are being mitigated."
    },
    "V-222626": {
        "title": "The designer must ensure the application does not store configuration and control files in the same directory as user data."
    },
    "V-222627": {
        "title": "The ISSO must ensure if a DoD STIG or NSA guide is not available, a third-party product will be configured by following available guidance."
    },
    "V-222628": {
        "title": "New IP addresses, data services, and associated ports used by the application must be submitted to the appropriate approving authority for the organization, which in turn will be submitted through the DoD Ports, Protocols, and Services Management (DoD PPSM)"
    },
    "V-222629": {
        "title": "The application must be registered with the DoD Ports and Protocols Database."
    },
    "V-222630": {
        "title": "The Configuration Management (CM) repository must be properly patched and STIG compliant."
    },
    "V-222631": {
        "title": "Access privileges to the Configuration Management (CM) repository must be reviewed every three months."
    },
    "V-222632": {
        "title": "A Software Configuration Management (SCM) plan describing the configuration control and change management process of application objects developed by the organization and the roles and responsibilities of the organization must be created and maintained."
    },
    "V-222633": {
        "title": "A Configuration Control Board (CCB) that meets at least every release cycle, for managing the Configuration Management (CM) process must be established."
    },
    "V-222634": {
        "title": "The application services and interfaces must be compatible with and ready for IPv6 networks."
    },
    "V-222635": {
        "title": "The application must not be hosted on a general purpose machine if the application is designated as critical or high availability by the ISSO."
    },
    "V-222636": {
        "title": "A disaster recovery/continuity plan must exist in accordance with DoD policy based on the applications availability requirements."
    },
    "V-222637": {
        "title": "Recovery procedures and technical system features must exist so recovery is performed in a secure and verifiable manner. The ISSO will document circumstances inhibiting a trusted recovery."
    },
    "V-222638": {
        "title": "Data backup must be performed at required intervals in accordance with DoD policy."
    },
    "V-222639": {
        "title": "Back-up copies of the application software or source code must be stored in a fire-rated container or stored separately (offsite)."
    },
    "V-222640": {
        "title": "Procedures must be in place to assure the appropriate physical and technical protection of the backup and restoration of the application."
    },
    "V-222641": {
        "title": "The application must use encryption to implement key exchange and authenticate endpoints prior to establishing a communication channel for key exchange."
    },
    "V-222642": {
        "title": "The application must not contain embedded authentication data."
    },
    "V-222643": {
        "title": "The application must have the capability to mark sensitive/classified output when required."
    },
    "V-222644": {
        "title": "Prior to each release of the application, updates to system, or applying patches; tests plans and procedures must be created and executed."
    },
    "V-222645": {
        "title": "Application files must be cryptographically hashed prior to deploying to DoD operational networks."
    },
    "V-222646": {
        "title": "At least one tester must be designated to test for security flaws in addition to functional testing."
    },
    "V-222647": {
        "title": "Test procedures must be created and at least annually executed to ensure system initialization, shutdown, and aborts are configured to verify the system remains in a secure state."
    },
    "V-222648": {
        "title": "An application code review must be performed on the application."
    },
    "V-222649": {
        "title": "Code coverage statistics must be maintained for each release of the application."
    },
    "V-222650": {
        "title": "Flaws found during a code review must be tracked in a defect tracking system."
    },
    "V-222651": {
        "title": "The changes to the application must be assessed for IA and accreditation impact prior to implementation."
    },
    "V-222652": {
        "title": "Security flaws must be fixed or addressed in the project plan."
    },
    "V-222653": {
        "title": "The application development team must follow a set of coding standards."
    },
    "V-222654": {
        "title": "The designer must create and update the Design Document for each release of the application."
    },
    "V-222655": {
        "title": "Threat models must be documented and reviewed for each application release and updated as required by design and functionality changes or when new threats are discovered."
    },
    "V-222656": {
        "title": "The application must not be subject to error handling vulnerabilities."
    },
    "V-222657": {
        "title": "The application development team must provide an application incident response plan."
    },
    "V-222658": {
        "title": "All products must be supported by the vendor or the development team."
    },
    "V-222659": {
        "title": "The application must be decommissioned when maintenance or support is no longer available."
    },
    "V-222660": {
        "title": "Procedures must be in place to notify users when an application is decommissioned."
    },
    "V-222661": {
        "title": "Unnecessary built-in application accounts must be disabled."
    },
    "V-222662": {
        "title": "Default passwords must be changed."
    },
    "V-222663": {
        "title": "An Application Configuration Guide must be created and included with the application."
    },
    "V-222664": {
        "title": "If the application contains classified data, a Security Classification Guide must exist containing data elements and their classification."
    },
    "V-222665": {
        "title": "The designer must ensure uncategorized or emerging mobile code is not used in applications."
    },
    "V-222666": {
        "title": "Production database exports must have database administration credentials and sensitive data removed before releasing the export."
    },
    "V-222667": {
        "title": "Protections against DoS attacks must be implemented."
    },
    "V-222668": {
        "title": "The system must alert an administrator when low resource conditions are encountered."
    },
    "V-222669": {
        "title": "At least one application administrator must be registered to receive update notifications, or security alerts, when automated alerts are available."
    },
    "V-222670": {
        "title": "The application must provide notifications or alerts when product update and security related patches are available."
    },
    "V-222671": {
        "title": "Connections between the DoD enclave and the Internet or other public or commercial wide area networks must require a DMZ."
    },
    "V-222672": {
        "title": "The application must generate audit records when concurrent logons from different workstations occur."
    },
    "V-222673": {
        "title": "The Program Manager must verify all levels of program management, designers, developers, and testers receive annual security training pertaining to their job function."
    },
    "V-254803": {
        "title": "The application must implement NSA-approved cryptography to protect classified information in accordance with applicable federal laws, Executive Orders, directives, policies, regulations, and standards."
    }
  },
  "casa": {
    "1": {
      "title": "Architecture, Design And Threat Modelling"
    },
    "2": {
      "title": "Authentication"
    },
    "3": {
      "title": "Session Management"
    },
    "4": {
      "title": "Access Control"
    },
    "5": {
      "title": "Malicious Input Handling"
    },
    "6": {
      "title": "Cryptography At Rest"
    },
    "7": {
      "title": "Error Handling And Logging"
    },
    "8": {
      "title": "Data Protection"
    },
    "9": {
      "title": "Communications Security"
    },
    "10": {
      "title": "Malicious Controls"
    },
    "11": {
      "title": "Business Logic"
    },
    "12": {
      "title": "Files And Resources"
    },
    "13": {
      "title": "Web Services"
    },
    "14": {
      "title": "Configuration"
    },
    "1.1.1": {
      "title": "Verify the use of a secure software development lifecycle that addresses security in all stages of development.",
      "level": "2"
    },
    "1.1.2": {
      "title": "Verify the use of threat modeling for every design change or sprint planning to identify threats, plan for countermeasures, facilitate appropriate risk responses, and guide security testing.",
      "level": "2"
    },
    "1.1.3": {
      "title": "Verify that all user stories and features contain functional security constraints, such as \"As a user,I should be able to view and edit my profile.I should not be able to view or edit anyone else 's profile\"",
      "level": "2"
    },
    "1.1.4": {
      "title": "Verify documentation and justification of all the application's trust boundaries, components, and significant data flows.",
      "level": "2"
    },
    "1.1.5": {
      "title": "Verify definition and security analysis of the application's high-level architecture and all connected remote services.",
      "level": "2"
    },
    "1.1.6": {
      "title": "Verify implementation of centralized, simple (economy of design), vetted, secure, and reusable security controls to avoid duplicate, missing, ineffective, or insecure controls.",
      "level": "2"
    },
    "1.1.7": {
      "title": "Verify availability of a secure coding checklist, security requirements, guideline, or policy to all developers and testers.",
      "level": "2"
    },
    "1.10.1": {
      "title": "Verify that a source code control system is in use, with procedures to ensure that check-ins are accompanied by issues or change tickets. The source code control system should have access control and identifiable users to allow traceability of any changes.",
      "level": "2"
    },
    "1.11.1": {
      "title": "Verify the definition and documentation of all application components in terms of the business or security functions they provide.",
      "level": "2"
    },
    "1.11.2": {
      "title": "Verify that all high-value business logic flows, including authentication, session management and access control, do not share unsynchronized state.",
      "level": "2"
    },
    "1.11.3": {
      "title": "Verify that all high-value business logic flows, including authentication, session management and access control are thread safe and resistant to time-of-check and time-of-use race conditions.",
      "level": "3"
    },
    "1.12.1": {
      "title": "Verify that user-uploaded files are stored outside of the web root.",
      "level": "2"
    },
    "1.12.2": {
      "title": "Verify that user-uploaded files - if required to be displayed or downloaded from the application - are served by either octet stream downloads, or from an unrelated domain, such as a cloud file storage bucket. Implement a suitable content security policy to reduce the risk from XSS vectors or other attacks from the uploaded file.",
      "level": "2"
    },
    "1.14.1": {
      "title": "Verify the segregation of components of differing trust levels through well-defined security controls, firewall rules, API gateways, reverse proxies, cloud-based security groups, or similar mechanisms.",
      "level": "2"
    },
    "1.14.2": {
      "title": "Verify that if deploying binaries to untrusted devices makes use of binary signatures, trusted connections, and verified endpoints.",
      "level": "2"
    },
    "1.14.3": {
      "title": "Verify that the build pipeline warns of out-of-date or insecure components and takes appropriate actions.",
      "level": "2"
    },
    "1.14.4": {
      "title": "Verify that the build pipeline contains a build step to automatically build and verify the secure deployment of the application, particularly if the application infrastructure is software defined, such as cloud environment build scripts.",
      "level": "2"
    },
    "1.14.5": {
      "title": "Verify that application deployments adequately sandbox, containerize and/or isolate at the network level to delay and deter attackers from attacking other applications, especially when they are performing sensitive or dangerous actions such as deserialization.",
      "level": "2"
    },
    "1.14.6": {
      "title": "Verify the application does not use unsupported, insecure, or deprecated client-side technologies such as NSAPI plugins, Flash, Shockwave, ActiveX, Silverlight, NACL, or client-side Java applets.",
      "level": "2"
    },
    "1.2.1": {
      "title": "Verify the use of unique or special low-privilege operating system accounts for all application components, services, and servers.",
      "level": "2"
    },
    "1.2.2": {
      "title": "Verify that communications between application components, including APIs, middleware and data layers, are authenticated. Components should have the least necessary privileges needed.",
      "level": "2"
    },
    "1.2.3": {
      "title": "Verify that the application uses a single vetted authentication mechanism that is known to be secure, can be extended to include strong authentication, and has sufficient logging and monitoring to detect account abuse or breaches.",
      "level": "2"
    },
    "1.2.4": {
      "title": "Verify that all authentication pathways and identity management APIs implement consistent authentication security control strength, such that there are no weaker alternatives per the risk of the application.",
      "level": "2"
    },
    "1.4.1": {
      "title": "Verify that trusted enforcement points such as at access control gateways, servers, and serverless functions enforce access controls. Never enforce access controls on the client.",
      "level": "2"
    },
    "1.4.2": {
      "title": "Verify that the chosen access control solution is flexible enough to meet the application's needs.",
      "level": "2"
    },
    "1.4.3": {
      "title": "Verify enforcement of the principle of least privilege in functions, data files, URLs, controllers, services, and other resources. This implies protection against spoofing and elevation of privilege.",
      "level": "2"
    },
    "1.4.4": {
      "title": "Verify the application uses a single and well-vetted access control mechanism for accessing protected data and resources. All requests must pass through this single mechanism to avoid copy and paste or insecure alternative paths.",
      "level": "2"
    },
    "1.4.5": {
      "title": "Verify that attribute or feature-based access control is used whereby the code checks the user's authorization for a feature/data item rather than just their role. Permissions should still be allocated using roles.",
      "level": "2"
    },
    "1.5.1": {
      "title": "Verify that input and output requirements clearly define how to handle and process data based on type, content, and applicable laws, regulations, and other policy compliance.",
      "level": "2"
    },
    "1.5.2": {
      "title": "Verify that serialization is not used when communicating with untrusted clients. If this is not possible, ensure that adequate integrity controls (and possibly encryption if sensitive data is sent) are enforced to prevent deserialization attacks including object injection.",
      "level": "2"
    },
    "1.5.3": {
      "title": "Verify that input validation is enforced on a trusted service layer.",
      "level": "2"
    },
    "1.5.4": {
      "title": "Verify that output encoding occurs close to or by the interpreter for which it is intended.",
      "level": "2"
    },
    "1.6.1": {
      "title": "Verify that there is an explicit policy for management of cryptographic keys and that a cryptographic key lifecycle follows a key management standard such as NIST SP 800-57.",
      "level": "2"
    },
    "1.6.2": {
      "title": "Verify that consumers of cryptographic services protect key material and other secrets by using key vaults or API based alternatives.",
      "level": "2"
    },
    "1.6.3": {
      "title": "Verify that all keys and passwords are replaceable and are part of a well-defined process to re-encrypt sensitive data.",
      "level": "2"
    },
    "1.6.4": {
      "title": "Verify that symmetric keys, passwords, or API secrets generated by or shared with clients are used only in protecting low risk secrets, such as encrypting local storage, or temporary ephemeral uses such as parameter obfuscation. Sharing secrets with clients is clear-text equivalent and architecturally should be treated as such.",
      "level": "2"
    },
    "1.7.1": {
      "title": "Verify that a common logging format and approach is used across the system. ",
      "level": "2"
    },
    "1.7.2": {
      "title": "Verify that logs are securely transmitted to a preferably remote system for analysis, detection, alerting, and escalation.",
      "level": "2"
    },
    "1.8.1": {
      "title": "Verify that all sensitive data is identified and classified into protection levels.",
      "level": "2"
    },
    "1.8.2": {
      "title": "Verify that all protection levels have an associated set of protection requirements, such as encryption requirements, integrity requirements, retention, privacy and other confidentiality requirements, and that these are applied in the architecture.",
      "level": "2"
    },
    "1.9.1": {
      "title": "Verify the application encrypts communications between components, particularly when these components are in different containers, systems, sites, or cloud providers.",
      "level": "2"
    },
    "1.9.2": {
      "title": "Verify that application components verify the authenticity of each side in a communication link to prevent person-in-the-middle attacks. For example, application components should validate TLS certificates and chains.",
      "level": "2"
    },
    "2.1.1": {
      "title": "Verify that user set passwords are at least 12 characters in length.",
      "level": "1"
    },
    "2.1.10": {
      "title": "Verify that there are no periodic credential rotation or password history requirements.",
      "level": "1"
    },
    "2.1.11": {
      "title": "Verify that \"paste\" functionality, browser password helpers, and external password managers are permitted.",
      "level": "1"
    },
    "2.1.12": {
      "title": "Verify that the user can choose to either temporarily view the entire masked password, or temporarily view the last typed character of the password on platforms that do not have this as native functionality.",
      "level": "1"
    },
    "2.1.2": {
      "title": "Verify that passwords 64 characters or longer are permitted.",
      "level": "1"
    },
    "2.1.3": {
      "title": "Verify that passwords can contain spaces and truncation is not performed. Consecutive multiple spaces MAY optionally be coalesced.",
      "level": "1"
    },
    "2.1.4": {
      "title": "Verify that Unicode characters are permitted in passwords. A single Unicode code point is considered a character, so 12 emoji or 64 kanji characters should be valid and permitted.",
      "level": "1"
    },
    "2.1.5": {
      "title": "Verify users can change their password.",
      "level": "1"
    },
    "2.1.6": {
      "title": "Verify that password change functionality requires the user's current and new password.",
      "level": "1"
    },
    "2.1.7": {
      "title": "Verify that passwords submitted during account registration, login, and password change are checked against a set of breached passwords either locally (such as the top 1,000 or 10,000 most common passwords which match the system's password policy) or using an external API. If using an API a zero knowledge proof or other mechanism should be used to ensure that the plain text password is not sent or used in verifying the breach status of the password. If the password is breached, the application must require the user to set a new non-breached password.",
      "level": "1"
    },
    "2.1.8": {
      "title": "Verify that a password strength meter is provided to help users set a stronger password.",
      "level": "1"
    },
    "2.1.9": {
      "title": "Verify that there are no password composition rules limiting the type of characters permitted. There should be no requirement for upper or lower case or numbers or special characters.",
      "level": "1"
    },
    "2.10.1": {
      "title": "Verify that integration secrets do not rely on unchanging passwords, such as API keys or shared privileged accounts.",
      "level": "1"
    },
    "2.10.2": {
      "title": "Verify that if passwords are required, the credentials are not a default account.",
      "level": "1"
    },
    "2.10.3": {
      "title": "Verify that passwords are stored with sufficient protection to prevent offline recovery attacks, including local system access.",
      "level": "1"
    },
    "2.10.4": {
      "title": "Verify passwords, integrations with databases and third-party systems, seeds and internal secrets, and API keys are managed securely and not included in the source code or stored within source code repositories. Such storage SHOULD resist offline attacks. The use of a secure software key store (L1), hardware trusted platform module (TPM), or a hardware security module (L3) is recommended for password storage.",
      "level": "1"
    },
    "2.2.1": {
      "title": "Verify that anti-automation controls are effective at mitigating breached credential testing, brute force, and account lockout attacks. Such controls include blocking the most common breached passwords, soft lockouts, rate limiting, CAPTCHA, ever increasing delays between attempts, IP address restrictions, or risk-based restrictions such as location, first login on a device, recent attempts to unlock the account, or similar. Verify that no more than 100 failed attempts per hour is possible on a single account.",
      "level": "1"
    },
    "2.2.2": {
      "title": "Verify that the use of weak authenticators (such as SMS and email) is limited to secondary verification and transaction approval and not as a replacement for more secure authentication methods. Verify that stronger methods are offered before weak methods, users are aware of the risks, or that proper measures are in place to limit the risks of account compromise.",
      "level": "1"
    },
    "2.2.3": {
      "title": "Verify that secure notifications are sent to users after updates to authentication details, such as credential resets, email or address changes, logging in from unknown or risky locations. The use of push notifications - rather than SMS or email - is preferred, but in the absence of push notifications, SMS or email is acceptable as long as no sensitive information is disclosed in the notification.",
      "level": "1"
    },
    "2.2.4": {
      "title": "Verify impersonation resistance against phishing, such as the use of multi-factor authentication, cryptographic devices with intent (such as connected keys with a push to authenticate), or at higher AAL levels, client-side certificates.",
      "level": "3"
    },
    "2.2.5": {
      "title": "Verify that where a credential service provider (CSP) and the application verifying authentication are separated, mutually authenticated TLS is in place between the two endpoints.",
      "level": "3"
    },
    "2.2.6": {
      "title": "Verify replay resistance through the mandated use of OTP devices, cryptographic authenticators, or lookup codes.",
      "level": "3"
    },
    "2.2.7": {
      "title": "Verify intent to authenticate by requiring the entry of an OTP token or user-initiated action such as a button press on a FIDO hardware key.",
      "level": "3"
    },
    "2.3.1": {
      "title": "Verify system generated initial passwords or activation codes SHOULD be securely randomly generated, SHOULD be at least 6 characters long, and MAY contain letters and numbers, and expire after a short period of time. These initial secrets must not be permitted to become the long term password.",
      "level": "1"
    },
    "2.3.2": {
      "title": "Verify that enrollment and use of subscriber-provided authentication devices are supported, such as a U2F or FIDO tokens.",
      "level": "2"
    },
    "2.3.3": {
      "title": "Verify that renewal instructions are sent with sufficient time to renew time bound authenticators.",
      "level": "2"
    },
    "2.4.1": {
      "title": "Verify that passwords are stored in a form that is resistant to offline attacks. Passwords SHALL be salted and hashed using an approved one-way key derivation or password hashing function. Key derivation and password hashing functions take a password, a salt, and a cost factor as inputs when generating a password hash.",
      "level": "2"
    },
    "2.4.2": {
      "title": "Verify that the salt is at least 32 bits in length and be chosen arbitrarily to minimize salt value collisions among stored hashes. For each credential, a unique salt value and the resulting hash SHALL be stored.",
      "level": "2"
    },
    "2.4.3": {
      "title": "Verify that if PBKDF2 is used, the iteration count SHOULD be as large as verification server performance will allow, typically at least 100,000 iterations.",
      "level": "2"
    },
    "2.4.4": {
      "title": "Verify that if bcrypt is used, the work factor SHOULD be as large as verification server performance will allow, typically at least 13.",
      "level": "2"
    },
    "2.4.5": {
      "title": "Verify that an additional iteration of a key derivation function is performed, using a salt value that is secret and known only to the verifier. Generate the salt value using an approved random bit generator [SP 800-90Ar1] and provide at least the minimum security strength specified in the latest revision of SP 800-131A. The secret salt value SHALL be stored separately from the hashed passwords (e.g., in a specialized device like a hardware security module).",
      "level": "2"
    },
    "2.5.1": {
      "title": "Verify that a system generated initial activation or recovery secret is not sent in clear text to the user.",
      "level": "1"
    },
    "2.5.2": {
      "title": "Verify password hints or knowledge-based authentication (so-called \"secret questions\") are not present.",
      "level": "1"
    },
    "2.5.3": {
      "title": "Verify password credential recovery does not reveal the current password in any way.",
      "level": "1"
    },
    "2.5.4": {
      "title": "Verify shared or default accounts are not present (e.g. \"root\", \"admin\", or \"sa\").",
      "level": "1"
    },
    "2.5.5": {
      "title": "Verify that if an authentication factor is changed or replaced, that the user is notified of this event.",
      "level": "1"
    },
    "2.5.6": {
      "title": "Verify forgotten password, and other recovery paths use a secure recovery mechanism, such as TOTP or other soft token, mobile push, or another offline recovery mechanism.",
      "level": "1"
    },
    "2.5.7": {
      "title": "Verify that if OTP or multi-factor authentication factors are lost, that evidence of identity proofing is performed at the same level as during enrollment.",
      "level": "2"
    },
    "2.6.1": {
      "title": "Verify that lookup secrets can be used only once.",
      "level": "2"
    },
    "2.6.2": {
      "title": "Verify that lookup secrets have sufficient randomness (112 bits of entropy), or if less than 112 bits of entropy, salted with a unique and random 32-bit salt and hashed with an approved one-way hash.",
      "level": "2"
    },
    "2.6.3": {
      "title": "Verify that lookup secrets are resistant to offline attacks, such as predictable values.",
      "level": "2"
    },
    "2.7.1": {
      "title": "Verify that clear text out of band (NIST \"restricted\") authenticators, such as SMS or PSTN, are not offered by default, and stronger alternatives such as push notifications are offered first.",
      "level": "1"
    },
    "2.7.2": {
      "title": "Verify that the out of band verifier expires out of band authentication requests, codes, or tokens after 10 minutes.",
      "level": "1"
    },
    "2.7.3": {
      "title": "Verify that the out of band verifier authentication requests, codes, or tokens are only usable once, and only for the original authentication request.",
      "level": "1"
    },
    "2.7.4": {
      "title": "Verify that the out of band authenticator and verifier communicates over a secure independent channel.",
      "level": "1"
    },
    "2.7.5": {
      "title": "Verify that the out of band verifier retains only a hashed version of the authentication code.",
      "level": "2"
    },
    "2.7.6": {
      "title": "Verify that the initial authentication code is generated by a secure random number generator, containing at least 20 bits of entropy (typically a six digital random number is sufficient).",
      "level": "2"
    },
    "2.8.1": {
      "title": "Verify that time-based OTPs have a defined lifetime before expiring.",
      "level": "1"
    },
    "2.8.2": {
      "title": "Verify that symmetric keys used to verify submitted OTPs are highly protected, such as by using a hardware security module or secure operating system based key storage.",
      "level": "2"
    },
    "2.8.3": {
      "title": "Verify that approved cryptographic algorithms are used in the generation, seeding, and verification.",
      "level": "2"
    },
    "2.8.4": {
      "title": "Verify that time-based OTP can be used only once within the validity period.",
      "level": "2"
    },
    "2.8.5": {
      "title": "Verify that if a time-based multi factor OTP token is re-used during the validity period, it is logged and rejected with secure notifications being sent to the holder of the device.",
      "level": "2"
    },
    "2.8.6": {
      "title": "Verify physical single factor OTP generator can be revoked in case of theft or other loss. Ensure that revocation is immediately effective across logged in sessions, regardless of location.",
      "level": "2"
    },
    "2.8.7": {
      "title": "Verify that biometric authenticators are limited to use only as secondary factors in conjunction with either something you have and something you know.",
      "level": "3"
    },
    "2.9.1": {
      "title": "Verify that cryptographic keys used in verification are stored securely and protected against disclosure, such as using a TPM or HSM, or an OS service that can use this secure storage.",
      "level": "2"
    },
    "2.9.2": {
      "title": "Verify that the challenge nonce is at least 64 bits in length, and statistically unique or unique over the lifetime of the cryptographic device.",
      "level": "2"
    },
    "2.9.3": {
      "title": "Verify that approved cryptographic algorithms are used in the generation, seeding, and verification.",
      "level": "2"
    },
    "3.1.1": {
      "title": "Verify the application never reveals session tokens in URL parameters or error messages.",
      "level": "1"
    },
    "3.2.1": {
      "title": "Verify the application generates a new session token on user authentication.",
      "level": "1"
    },
    "3.2.2": {
      "title": "Verify that session tokens possess at least 64 bits of entropy.",
      "level": "1"
    },
    "3.2.3": {
      "title": "Verify the application only stores session tokens in the browser using secure methods such as appropriately secured cookies (see section 3.4) or HTML 5 session storage.",
      "level": "1"
    },
    "3.2.4": {
      "title": "Verify that session token are generated using approved cryptographic algorithms.",
      "level": "2"
    },
    "3.3.1": {
      "title": "Verify that logout and expiration invalidate the session token, such that the back button or a downstream relying party does not resume an authenticated session, including across relying parties.",
      "level": "1"
    },
    "3.3.2": {
      "title": "If authenticators permit users to remain logged in, verify that re-authentication occurs periodically both when actively used or after an idle period.",
      "level": "1"
    },
    "3.3.3": {
      "title": "Verify that the application terminates all other active sessions after a successful password change, and that this is effective across the application, federated login (if present), and any relying parties.",
      "level": "2"
    },
    "3.3.4": {
      "title": "Verify that users are able to view and log out of any or all currently active sessions and devices.",
      "level": "2"
    },
    "3.4.1": {
      "title": "Verify that cookie-based session tokens have the 'Secure' attribute set.",
      "level": "1"
    },
    "3.4.2": {
      "title": "Verify that cookie-based session tokens have the 'HttpOnly' attribute set.",
      "level": "1"
    },
    "3.4.3": {
      "title": "Verify that cookie-based session tokens utilize the 'SameSite' attribute to limit exposure to cross-site request forgery attacks.",
      "level": "1"
    },
    "3.4.4": {
      "title": "Verify that cookie-based session tokens use \"__Host-\" prefix (see references) to provide session cookie confidentiality.",
      "level": "1"
    },
    "3.4.5": {
      "title": "Verify that if the application is published under a domain name with other applications that set or use session cookies that might override or disclose the session cookies, set the path attribute in cookie-based session tokens using the most precise path possible.",
      "level": "1"
    },
    "3.5.1": {
      "title": "Verify the application does not treat OAuth and refresh tokens &mdash; on their own &mdash; as the presence of the subscriber and allows users to terminate trust relationships with linked applications.",
      "level": "2"
    },
    "3.5.2": {
      "title": "Verify the application uses session tokens rather than static API secrets and keys, except with legacy implementations.",
      "level": "2"
    },
    "3.5.3": {
      "title": "Verify that stateless session tokens use digital signatures, encryption, and other countermeasures to protect against tampering, enveloping, replay, null cipher, and key substitution attacks.",
      "level": "2"
    },
    "3.6.1": {
      "title": "Verify that relying parties specify the maximum authentication time to CSPs and that CSPs re-authenticate the subscriber if they haven't used a session within that period.",
      "level": "3"
    },
    "3.6.2": {
      "title": "Verify that CSPs inform relying parties of the last authentication event, to allow RPs to determine if they need to re-authenticate the user.",
      "level": "3"
    },
    "3.7.1": {
      "title": "Verify the application ensures a valid login session or requires re-authentication or secondary verification before allowing any sensitive transactions or account modifications.",
      "level": "1"
    },
    "4.1.1": {
      "title": "Verify that the application enforces access control rules on a trusted service layer, especially if client-side access control is present and could be bypassed.",
      "level": "1"
    },
    "4.1.2": {
      "title": "Verify that all user and data attributes and policy information used by access controls cannot be manipulated by end users unless specifically authorized.",
      "level": "1"
    },
    "4.1.3": {
      "title": "Verify that the principle of least privilege exists - users should only be able to access functions, data files, URLs, controllers, services, and other resources, for which they possess specific authorization. This implies protection against spoofing and elevation of privilege.",
      "level": "1"
    },
    "4.1.4": {
      "title": "Verify that the principle of deny by default exists whereby new users/roles start with minimal or no permissions and users/roles do not receive access to new features until access is explicitly assigned. ",
      "level": "1"
    },
    "4.1.5": {
      "title": "Verify that access controls fail securely including when an exception occurs.",
      "level": "1"
    },
    "4.2.1": {
      "title": "Verify that sensitive data and APIs are protected against direct object attacks targeting creation, reading, updating and deletion of records, such as creating or updating someone else's record, viewing everyone's records, or deleting all records.",
      "level": "1"
    },
    "4.2.2": {
      "title": "Verify that the application or framework enforces a strong anti-CSRF mechanism to protect authenticated functionality, and effective anti-automation or anti-CSRF protects unauthenticated functionality.",
      "level": "1"
    },
    "4.3.1": {
      "title": "Verify administrative interfaces use appropriate multi-factor authentication to prevent unauthorized use.",
      "level": "1"
    },
    "4.3.2": {
      "title": "Verify that directory browsing is disabled unless deliberately desired. Additionally, applications should not allow discovery or disclosure of file or directory metadata, such as Thumbs.db, .DS_Store, .git or .svn folders.",
      "level": "1"
    },
    "4.3.3": {
      "title": "Verify the application has additional authorization (such as step up or adaptive authentication) for lower value systems, and / or segregation of duties for high value applications to enforce anti-fraud controls as per the risk of application and past fraud.",
      "level": "2"
    },
    "5.1.1": {
      "title": "Verify that the application has defenses against HTTP parameter pollution attacks, particularly if the application framework makes no distinction about the source of request parameters (GET, POST, cookies, headers, or environment variables).",
      "level": "1"
    },
    "5.1.2": {
      "title": "Verify that frameworks protect against mass parameter assignment attacks, or that the application has countermeasures to protect against unsafe parameter assignment, such as marking fields private or similar.",
      "level": "1"
    },
    "5.1.3": {
      "title": "Verify that all input (HTML form fields, REST requests, URL parameters, HTTP headers, cookies, batch files, RSS feeds, etc) is validated using positive validation (whitelisting).",
      "level": "1"
    },
    "5.1.4": {
      "title": "Verify that structured data is strongly typed and validated against a defined schema including allowed characters, length and pattern (e.g. credit card numbers or telephone, or validating that two related fields are reasonable, such as checking that suburb and zip/postcode match).",
      "level": "1"
    },
    "5.1.5": {
      "title": "Verify that URL redirects and forwards only allow whitelisted destinations, or show a warning when redirecting to potentially untrusted content.",
      "level": "1"
    },
    "5.2.1": {
      "title": "Verify that all untrusted HTML input from WYSIWYG editors or similar is properly sanitized with an HTML sanitizer library or framework feature.",
      "level": "1"
    },
    "5.2.2": {
      "title": "Verify that unstructured data is sanitized to enforce safety measures such as allowed characters and length.",
      "level": "1"
    },
    "5.2.3": {
      "title": "Verify that the application sanitizes user input before passing to mail systems to protect against SMTP or IMAP injection.",
      "level": "1"
    },
    "5.2.4": {
      "title": "Verify that the application avoids the use of eval() or other dynamic code execution features. Where there is no alternative, any user input being included must be sanitized or sandboxed before being executed.",
      "level": "1"
    },
    "5.2.5": {
      "title": "Verify that the application protects against template injection attacks by ensuring that any user input being included is sanitized or sandboxed.",
      "level": "1"
    },
    "5.2.6": {
      "title": "Verify that the application protects against SSRF attacks, by validating or sanitizing untrusted data or HTTP file metadata, such as filenames and URL input fields, use whitelisting of protocols, domains, paths and ports.",
      "level": "1"
    },
    "5.2.7": {
      "title": "Verify that the application sanitizes, disables, or sandboxes user-supplied SVG scriptable content, especially as they relate to XSS resulting from inline scripts, and foreignObject.",
      "level": "1"
    },
    "5.2.8": {
      "title": "Verify that the application sanitizes, disables, or sandboxes user-supplied scriptable or expression template language content, such as Markdown, CSS or XSL stylesheets, BBCode, or similar.",
      "level": "1"
    },
    "5.3.1": {
      "title": "Verify that output encoding is relevant for the interpreter and context required. For example, use encoders specifically for HTML values, HTML attributes, JavaScript, URL Parameters, HTTP headers, SMTP, and others as the context requires, especially from untrusted inputs (e.g. names with Unicode or apostrophes, such as ねこ or O'Hara).",
      "level": "1"
    },
    "5.3.10": {
      "title": "Verify that the application protects against XPath injection or XML injection attacks.",
      "level": "1"
    },
    "5.3.2": {
      "title": "Verify that output encoding preserves the user's chosen character set and locale, such that any Unicode character point is valid and safely handled.",
      "level": "1"
    },
    "5.3.3": {
      "title": "Verify that context-aware, preferably automated - or at worst, manual - output escaping protects against reflected, stored, and DOM based XSS.",
      "level": "1"
    },
    "5.3.4": {
      "title": "Verify that data selection or database queries (e.g. SQL, HQL, ORM, NoSQL) use parameterized queries, ORMs, entity frameworks, or are otherwise protected from database injection attacks.",
      "level": "1"
    },
    "5.3.5": {
      "title": "Verify that where parameterized or safer mechanisms are not present, context-specific output encoding is used to protect against injection attacks, such as the use of SQL escaping to protect against SQL injection.",
      "level": "1"
    },
    "5.3.6": {
      "title": "Verify that the application projects against JavaScript or JSON injection attacks, including for eval attacks, remote JavaScript includes, CSP bypasses, DOM XSS, and JavaScript expression evaluation.",
      "level": "1"
    },
    "5.3.7": {
      "title": "Verify that the application protects against LDAP Injection vulnerabilities, or that specific security controls to prevent LDAP Injection have been implemented.",
      "level": "1"
    },
    "5.3.8": {
      "title": "Verify that the application protects against OS command injection and that operating system calls use parameterized OS queries or use contextual command line output encoding.",
      "level": "1"
    },
    "5.3.9": {
      "title": "Verify that the application protects against Local File Inclusion (LFI) or Remote File Inclusion (RFI) attacks.",
      "level": "1"
    },
    "5.4.1": {
      "title": "Verify that the application uses memory-safe string, safer memory copy and pointer arithmetic to detect or prevent stack, buffer, or heap overflows.",
      "level": "2"
    },
    "5.4.2": {
      "title": "Verify that format strings do not take potentially hostile input, and are constant.",
      "level": "2"
    },
    "5.4.3": {
      "title": "Verify that sign, range, and input validation techniques are used to prevent integer overflows.",
      "level": "2"
    },
    "5.5.1": {
      "title": "Verify that serialized objects use integrity checks or are encrypted to prevent hostile object creation or data tampering.",
      "level": "1"
    },
    "5.5.2": {
      "title": "Verify that the application correctly restricts XML parsers to only use the most restrictive configuration possible and to ensure that unsafe features such as resolving external entities are disabled to prevent XXE.",
      "level": "1"
    },
    "5.5.3": {
      "title": "Verify that deserialization of untrusted data is avoided or is protected in both custom code and third-party libraries (such as JSON, XML and YAML parsers).",
      "level": "1"
    },
    "5.5.4": {
      "title": "Verify that when parsing JSON in browsers or JavaScript-based backends, JSON.parse is used to parse the JSON document. Do not use eval() to parse JSON.",
      "level": "1"
    },
    "6.1.1": {
      "title": "Verify that regulated private data is stored encrypted while at rest, such as personally identifiable information (PII), sensitive personal information, or data assessed likely to be subject to EU's GDPR.",
      "level": "2"
    },
    "6.1.2": {
      "title": "Verify that regulated health data is stored encrypted while at rest, such as medical records, medical device details, or de-anonymized research records.",
      "level": "2"
    },
    "6.1.3": {
      "title": "Verify that regulated financial data is stored encrypted while at rest, such as financial accounts, defaults or credit history, tax records, pay history, beneficiaries, or de-anonymized market or research records.",
      "level": "2"
    },
    "6.2.1": {
      "title": "Verify that all cryptographic modules fail securely, and errors are handled in a way that does not enable Padding Oracle attacks.",
      "level": "1"
    },
    "6.2.2": {
      "title": "Verify that industry proven or government approved cryptographic algorithms, modes, and libraries are used, instead of custom coded cryptography.",
      "level": "2"
    },
    "6.2.3": {
      "title": "Verify that encryption initialization vector, cipher configuration, and block modes are configured securely using the latest advice.",
      "level": "2"
    },
    "6.2.4": {
      "title": "Verify that random number, encryption or hashing algorithms, key lengths, rounds, ciphers or modes, can be reconfigured, upgraded, or swapped at any time, to protect against cryptographic breaks.",
      "level": "2"
    },
    "6.2.5": {
      "title": "Verify that known insecure block modes (i.e. ECB, etc.), padding modes (i.e. PKCS#1 v1.5, etc.), ciphers with small block sizes (i.e. Triple-DES, Blowfish, etc.), and weak hashing algorithms (i.e. MD5, SHA1, etc.) are not used unless required for backwards compatibility.",
      "level": "2"
    },
    "6.2.6": {
      "title": "Verify that nonces, initialization vectors, and other single use numbers must not be used more than once with a given encryption key. The method of generation must be appropriate for the algorithm being used.",
      "level": "2"
    },
    "6.2.7": {
      "title": "Verify that encrypted data is authenticated via signatures, authenticated cipher modes, or HMAC to ensure that ciphertext is not altered by an unauthorized party.",
      "level": "3"
    },
    "6.2.8": {
      "title": "Verify that all cryptographic operations are constant-time, with no 'short-circuit' operations in comparisons, calculations, or returns, to avoid leaking information.",
      "level": "3"
    },
    "6.3.1": {
      "title": "Verify that all random numbers, random file names, random GUIDs, and random strings are generated using the cryptographic module's approved cryptographically secure random number generator when these random values are intended to be not guessable by an attacker.",
      "level": "2"
    },
    "6.3.2": {
      "title": "Verify that random GUIDs are created using the GUID v4 algorithm, and a cryptographically-secure pseudo-random number generator (CSPRNG). GUIDs created using other pseudo-random number generators may be predictable.",
      "level": "2"
    },
    "6.3.3": {
      "title": "Verify that random numbers are created with proper entropy even when the application is under heavy load, or that the application degrades gracefully in such circumstances.",
      "level": "3"
    },
    "6.4.1": {
      "title": "Verify that a secrets management solution such as a key vault is used to securely create, store, control access to and destroy secrets.",
      "level": "2"
    },
    "6.4.2": {
      "title": "Verify that key material is not exposed to the application but instead uses an isolated security module like a vault for cryptographic operations.",
      "level": "2"
    },
    "7.1.1": {
      "title": "Verify that the application does not log credentials or payment details. Session tokens should only be stored in logs in an irreversible, hashed form.",
      "level": "1"
    },
    "7.1.2": {
      "title": "Verify that the application does not log other sensitive data as defined under local privacy laws or relevant security policy.",
      "level": "1"
    },
    "7.1.3": {
      "title": "Verify that the application logs security relevant events including successful and failed authentication events, access control failures, deserialization failures and input validation failures.",
      "level": "2"
    },
    "7.1.4": {
      "title": "Verify that each log event includes necessary information that would allow for a detailed investigation of the timeline when an event happens.",
      "level": "2"
    },
    "7.2.1": {
      "title": "Verify that all authentication decisions are logged, without storing sensitive session identifiers or passwords. This should include requests with relevant metadata needed for security investigations.",
      "level": "2"
    },
    "7.2.2": {
      "title": "Verify that all access control decisions can be logged and all failed decisions are logged. This should include requests with relevant metadata needed for security investigations.",
      "level": "2"
    },
    "7.3.1": {
      "title": "Verify that the application appropriately encodes user-supplied data to prevent log injection.",
      "level": "2"
    },
    "7.3.2": {
      "title": "Verify that all events are protected from injection when viewed in log viewing software.",
      "level": "2"
    },
    "7.3.3": {
      "title": "Verify that security logs are protected from unauthorized access and modification.",
      "level": "2"
    },
    "7.3.4": {
      "title": "Verify that time sources are synchronized to the correct time and time zone. Strongly consider logging only in UTC if systems are global to assist with post-incident forensic analysis.",
      "level": "2"
    },
    "7.4.1": {
      "title": "Verify that a generic message is shown when an unexpected or security sensitive error occurs, potentially with a unique ID which support personnel can use to investigate.",
      "level": "1"
    },
    "7.4.2": {
      "title": "Verify that exception handling (or a functional equivalent) is used across the codebase to account for expected and unexpected error conditions.",
      "level": "2"
    },
    "7.4.3": {
      "title": "Verify that a \"last resort\" error handler is defined which will catch all unhandled exceptions.",
      "level": "2"
    },
    "8.1.1": {
      "title": "Verify the application protects sensitive data from being cached in server components such as load balancers and application caches.",
      "level": "2"
    },
    "8.1.2": {
      "title": "Verify that all cached or temporary copies of sensitive data stored on the server are protected from unauthorized access or purged/invalidated after the authorized user accesses the sensitive data.",
      "level": "2"
    },
    "8.1.3": {
      "title": "Verify the application minimizes the number of parameters in a request, such as hidden fields, Ajax variables, cookies and header values.",
      "level": "2"
    },
    "8.1.4": {
      "title": "Verify the application can detect and alert on abnormal numbers of requests, such as by IP, user, total per hour or day, or whatever makes sense for the application.",
      "level": "2"
    },
    "8.1.5": {
      "title": "Verify that regular backups of important data are performed and that test restoration of data is performed.",
      "level": "3"
    },
    "8.1.6": {
      "title": "Verify that backups are stored securely to prevent data from being stolen or corrupted.",
      "level": "3"
    },
    "8.2.1": {
      "title": "Verify the application sets sufficient anti-caching headers so that sensitive data is not cached in modern browsers.",
      "level": "1"
    },
    "8.2.2": {
      "title": "Verify that data stored in client side storage (such as HTML5 local storage, session storage, IndexedDB, regular cookies or Flash cookies) does not contain sensitive data or PII.",
      "level": "1"
    },
    "8.2.3": {
      "title": "Verify that authenticated data is cleared from client storage, such as the browser DOM, after the client or session is terminated.",
      "level": "1"
    },
    "8.3.1": {
      "title": "Verify that sensitive data is sent to the server in the HTTP message body or headers, and that query string parameters from any HTTP verb do not contain sensitive data.",
      "level": "1"
    },
    "8.3.2": {
      "title": "Verify that users have a method to remove or export their data on demand.",
      "level": "1"
    },
    "8.3.3": {
      "title": "Verify that users are provided clear language regarding collection and use of supplied personal information and that users have provided opt-in consent for the use of that data before it is used in any way.",
      "level": "1"
    },
    "8.3.4": {
      "title": "Verify that all sensitive data created and processed by the application has been identified, and ensure that a policy is in place on how to deal with sensitive data.",
      "level": "1"
    },
    "8.3.5": {
      "title": "Verify accessing sensitive data is audited (without logging the sensitive data itself), if the data is collected under relevant data protection directives or where logging of access is required.",
      "level": "2"
    },
    "8.3.6": {
      "title": "Verify that sensitive information contained in memory is overwritten as soon as it is no longer required to mitigate memory dumping attacks, using zeroes or random data.",
      "level": "2"
    },
    "8.3.7": {
      "title": "Verify that sensitive or private information that is required to be encrypted, is encrypted using approved algorithms that provide both confidentiality and integrity.",
      "level": "2"
    },
    "8.3.8": {
      "title": "Verify that sensitive personal information is subject to data retention classification, such that old or out of date data is deleted automatically, on a schedule, or as the situation requires.",
      "level": "2"
    },
    "9.1.1": {
      "title": "Verify that secured TLS is used for all client connectivity, and does not fall back to insecure or unencrypted protocols.",
      "level": "1"
    },
    "9.1.2": {
      "title": "Verify using online or up to date TLS testing tools that only strong algorithms, ciphers, and protocols are enabled, with the strongest algorithms and ciphers set as preferred.",
      "level": "1"
    },
    "9.1.3": {
      "title": "Verify that old versions of SSL and TLS protocols, algorithms, ciphers, and configuration are disabled, such as SSLv2, SSLv3, or TLS 1.0 and TLS 1.1. The latest version of TLS should be the preferred cipher suite.",
      "level": "1"
    },
    "9.2.1": {
      "title": "Verify that connections to and from the server use trusted TLS certificates. Where internally generated or self-signed certificates are used, the server must be configured to only trust specific internal CAs and specific self-signed certificates. All others should be rejected.",
      "level": "2"
    },
    "9.2.2": {
      "title": "Verify that encrypted communications such as TLS is used for all inbound and outbound connections, including for management ports, monitoring, authentication, API, or web service calls, database, cloud, serverless, mainframe, external, and partner connections. The server must not fall back to insecure or unencrypted protocols.",
      "level": "2"
    },
    "9.2.3": {
      "title": "Verify that all encrypted connections to external systems that involve sensitive information or functions are authenticated.",
      "level": "2"
    },
    "9.2.4": {
      "title": "Verify that proper certification revocation, such as Online Certificate Status Protocol (OCSP) Stapling, is enabled and configured.",
      "level": "2"
    },
    "9.2.5": {
      "title": "Verify that backend TLS connection failures are logged.",
      "level": "3"
    },
    "10.1.1": {
      "title": "Verify that a code analysis tool is in use that can detect potentially malicious code, such as time functions, unsafe file operations and network connections.",
      "level": "3"
    },
    "10.2.1": {
      "title": "Verify that the application source code and third party libraries do not contain unauthorized phone home or data collection capabilities. Where such functionality exists, obtain the user's permission for it to operate  before collecting any data.",
      "level": "2"
    },
    "10.2.2": {
      "title": "Verify that the application does not ask for unnecessary or excessive permissions to privacy related features or sensors, such as contacts, cameras, microphones, or location.",
      "level": "2"
    },
    "10.2.3": {
      "title": "Verify that the application source code and third party libraries do not contain back doors, such as hard-coded or additional undocumented accounts or keys, code obfuscation, undocumented binary blobs, rootkits, or anti-debugging, insecure debugging features, or otherwise out of date, insecure, or hidden functionality that could be used maliciously if discovered.",
      "level": "3"
    },
    "10.2.4": {
      "title": "Verify that the application source code and third party libraries does not contain time bombs by searching for date and time related functions.",
      "level": "3"
    },
    "10.2.5": {
      "title": "Verify that the application source code and third party libraries does not contain malicious code, such as salami attacks, logic bypasses, or logic bombs.",
      "level": "3"
    },
    "10.2.6": {
      "title": "Verify that the application source code and third party libraries do not contain Easter eggs or any other potentially unwanted functionality.",
      "level": "3"
    },
    "10.3.1": {
      "title": "Verify that if the application has a client or server auto-update feature, updates should be obtained over secure channels and digitally signed. The update code must validate the digital signature of the update before installing or executing the update.",
      "level": "1"
    },
    "10.3.2": {
      "title": "Verify that the application employs integrity protections, such as code signing or sub-resource integrity. The application must not load or execute code from untrusted sources, such as loading includes, modules, plugins, code, or libraries from untrusted sources or the Internet.",
      "level": "1"
    },
    "10.3.3": {
      "title": "Verify that the application has protection from sub-domain takeovers if the application relies upon DNS entries or DNS sub-domains, such as expired domain names, out of date DNS pointers or CNAMEs, expired projects at public source code repos, or transient cloud APIs, serverless functions, or storage buckets (autogen-bucket-id.cloud.example.com) or similar. Protections can include ensuring that DNS names used by applications are regularly checked for expiry or change.",
      "level": "1"
    },
    "11.1.1": {
      "title": "Verify the application will only process business logic flows for the same user in sequential step order and without skipping steps.",
      "level": "1"
    },
    "11.1.2": {
      "title": "Verify the application will only process business logic flows with all steps being processed in realistic human time, i.e. transactions are not submitted too quickly.",
      "level": "1"
    },
    "11.1.3": {
      "title": "Verify the application has appropriate limits for specific business actions or transactions which are correctly enforced on a per user basis.",
      "level": "1"
    },
    "11.1.4": {
      "title": "Verify the application has sufficient anti-automation controls to detect and protect against data exfiltration, excessive business logic requests, excessive file uploads or denial of service attacks.",
      "level": "1"
    },
    "11.1.5": {
      "title": "Verify the application has business logic limits or validation to protect against likely business risks or threats, identified using threat modelling or similar methodologies.",
      "level": "1"
    },
    "11.1.6": {
      "title": "Verify the application does not suffer from \"time of check to time of use\" (TOCTOU) issues or other race conditions for sensitive operations.",
      "level": "2"
    },
    "11.1.7": {
      "title": "Verify the application monitors for unusual events or activity from a business logic perspective. For example, attempts to perform actions out of order or actions which a normal user would never attempt.",
      "level": "2"
    },
    "11.1.8": {
      "title": "Verify the application has configurable alerting when automated attacks or unusual activity is detected.",
      "level": "2"
    },
    "12.1.1": {
      "title": "Verify that the application will not accept large files that could fill up storage or cause a denial of service attack.",
      "level": "1"
    },
    "12.1.2": {
      "title": "Verify that compressed files are checked for \"zip bombs\" - small input files that will decompress into huge files thus exhausting file storage limits.",
      "level": "2"
    },
    "12.1.3": {
      "title": "Verify that a file size quota and maximum number of files per user is enforced to ensure that a single user cannot fill up the storage with too many files, or excessively large files.",
      "level": "2"
    },
    "12.2.1": {
      "title": "Verify that files obtained from untrusted sources are validated to be of expected type based on the file's content.",
      "level": "2"
    },
    "12.3.1": {
      "title": "Verify that user-submitted filename metadata is not used directly with system or framework file and URL API to protect against path traversal.",
      "level": "1"
    },
    "12.3.2": {
      "title": "Verify that user-submitted filename metadata is validated or ignored to prevent the disclosure, creation, updating or removal of local files (LFI).",
      "level": "1"
    },
    "12.3.3": {
      "title": "Verify that user-submitted filename metadata is validated or ignored to prevent the disclosure or execution of remote files (RFI), which may also lead to SSRF.",
      "level": "1"
    },
    "12.3.4": {
      "title": "Verify that the application protects against reflective file download (RFD) by validating or ignoring user-submitted filenames in a JSON, JSONP, or URL parameter, the response Content-Type header should be set to text/plain, and the Content-Disposition header should have a fixed filename.",
      "level": "1"
    },
    "12.3.5": {
      "title": "Verify that untrusted file metadata is not used directly with system API or libraries, to protect against OS command injection.",
      "level": "1"
    },
    "12.3.6": {
      "title": "Verify that the application does not include and execute functionality from untrusted sources, such as unverified content distribution networks, JavaScript libraries, node npm libraries, or server-side DLLs.",
      "level": "2"
    },
    "12.4.1": {
      "title": "Verify that files obtained from untrusted sources are stored outside the web root, with limited permissions, preferably with strong validation.",
      "level": "1"
    },
    "12.4.2": {
      "title": "Verify that files obtained from untrusted sources are scanned by antivirus scanners to prevent upload of known malicious content.",
      "level": "1"
    },
    "12.5.1": {
      "title": "Verify that the web tier is configured to serve only files with specific file extensions to prevent unintentional information and source code leakage. For example, backup files (e.g. .bak), temporary working files (e.g. .swp), compressed files (.zip, .tar.gz, etc) and other extensions commonly used by editors should be blocked unless required.",
      "level": "1"
    },
    "12.5.2": {
      "title": "Verify that direct requests to uploaded files will never be executed as HTML/JavaScript content.",
      "level": "1"
    },
    "12.6.1": {
      "title": "Verify that the web or application server is configured with a whitelist of resources or systems to which the server can send requests or load data/files from.",
      "level": "1"
    },
    "13.1.1": {
      "title": "Verify that all application components use the same encodings and parsers to avoid parsing attacks that exploit different URI or file parsing behavior that could be used in SSRF and RFI attacks.",
      "level": "1"
    },
    "13.1.2": {
      "title": "Verify that access to administration and management functions is limited to authorized administrators.",
      "level": "1"
    },
    "13.1.3": {
      "title": "Verify API URLs do not expose sensitive information, such as the API key, session tokens etc.",
      "level": "1"
    },
    "13.1.4": {
      "title": "Verify that authorization decisions are made at both the URI, enforced by programmatic or declarative security at the controller or router, and at the resource level, enforced by model-based permissions.",
      "level": "2"
    },
    "13.1.5": {
      "title": "Verify that requests containing unexpected or missing content types are rejected with appropriate headers (HTTP response status 406 Unacceptable or 415 Unsupported Media Type).",
      "level": "2"
    },
    "13.2.1": {
      "title": "Verify that enabled RESTful HTTP methods are a valid choice for the user or action, such as preventing normal users using DELETE or PUT on protected API or resources.",
      "level": "1"
    },
    "13.2.2": {
      "title": "Verify that JSON schema validation is in place and verified before accepting input.",
      "level": "1"
    },
    "13.2.3": {
      "title": "Verify that RESTful web services that utilize cookies are protected from Cross-Site Request Forgery via the use of at least one or more of the following: triple or double submit cookie pattern",
      "level": "1"
    },
    "13.2.4": {
      "title": "Verify that REST services have anti-automation controls to protect against excessive calls, especially if the API is unauthenticated.",
      "level": "2"
    },
    "13.2.5": {
      "title": "Verify that REST services explicitly check the incoming Content-Type to be the expected one, such as application/xml or application/JSON.",
      "level": "2"
    },
    "13.2.6": {
      "title": "Verify that the message headers and payload are trustworthy and not modified in transit. Requiring strong encryption for transport (TLS only) may be sufficient in many cases as it provides both confidentiality and integrity protection. Per-message digital signatures can provide additional assurance on top of the transport protections for high-security applications but bring with them additional complexity and risks to weigh against the benefits.",
      "level": "2"
    },
    "13.3.1": {
      "title": "Verify that XSD schema validation takes place to ensure a properly formed XML document, followed by validation of each input field before any processing of that data takes place.",
      "level": "1"
    },
    "13.3.2": {
      "title": "Verify that the message payload is signed using WS-Security to ensure reliable transport between client and service.",
      "level": "2"
    },
    "13.4.1": {
      "title": "Verify that query whitelisting or a combination of depth limiting and amount limiting should be used to prevent GraphQL or data layer expression denial of service (DoS) as a result of expensive, nested queries. For more advanced scenarios, query cost analysis should be used.",
      "level": "2"
    },
    "13.4.2": {
      "title": "Verify that GraphQL or other data layer authorization logic should be implemented at the business logic layer instead of the GraphQL layer.",
      "level": "2"
    },
    "14.1.1": {
      "title": "Verify that the application build and deployment processes are performed in a secure and repeatable way, such as CI / CD automation, automated configuration management, and automated deployment scripts.",
      "level": "2"
    },
    "14.1.2": {
      "title": "Verify that compiler flags are configured to enable all available buffer overflow protections and warnings, including stack randomization, data execution prevention, and to break the build if an unsafe pointer, memory, format string, integer, or string operations are found.",
      "level": "2"
    },
    "14.1.3": {
      "title": "Verify that server configuration is hardened as per the recommendations of the application server and frameworks in use.",
      "level": "2"
    },
    "14.1.4": {
      "title": "Verify that the application, configuration, and all dependencies can be re-deployed using automated deployment scripts, built from a documented and tested runbook in a reasonable time, or restored from backups in a timely fashion.",
      "level": "2"
    },
    "14.1.5": {
      "title": "Verify that authorized administrators can verify the integrity of all security-relevant configurations to detect tampering.",
      "level": "3"
    },
    "14.2.1": {
      "title": "Verify that all components are up to date, preferably using a dependency checker during build or compile time.",
      "level": "1"
    },
    "14.2.2": {
      "title": "Verify that all unneeded features, documentation, samples, configurations are removed, such as sample applications, platform documentation, and default or example users.",
      "level": "1"
    },
    "14.2.3": {
      "title": "Verify that if application assets, such as JavaScript libraries, CSS stylesheets or web fonts, are hosted externally on a content delivery network (CDN) or external provider, Subresource Integrity (SRI) is used to validate the integrity of the asset.",
      "level": "1"
    },
    "14.2.4": {
      "title": "Verify that third party components come from pre-defined, trusted and continually maintained repositories.",
      "level": "2"
    },
    "14.2.5": {
      "title": "Verify that an inventory catalog is maintained of all third party libraries in use.",
      "level": "2"
    },
    "14.2.6": {
      "title": "Verify that the attack surface is reduced by sandboxing or encapsulating third party libraries to expose only the required behaviour into the application.",
      "level": "2"
    },
    "14.3.1": {
      "title": "Verify that web or application server and framework error messages are configured to deliver user actionable, customized responses to eliminate any unintended security disclosures.",
      "level": "1"
    },
    "14.3.2": {
      "title": "Verify that web or application server and application framework debug modes are disabled in production to eliminate debug features, developer consoles, and unintended security disclosures.",
      "level": "1"
    },
    "14.3.3": {
      "title": "Verify that the HTTP headers or any part of the HTTP response do not expose detailed version information of system components.",
      "level": "1"
    },
    "14.4.1": {
      "title": "Verify that every HTTP response contains a content type header specifying a safe character set (e.g., UTF-8, ISO 8859-1).",
      "level": "1"
    },
    "14.4.2": {
      "title": "Verify that all API responses contain Content-Disposition: attachment; filename=\"api.json\" (or other appropriate filename for the content type).",
      "level": "1"
    },
    "14.4.3": {
      "title": "Verify that a content security policy (CSPv2) is in place that helps mitigate impact for XSS attacks like HTML, DOM, JSON, and JavaScript injection vulnerabilities.",
      "level": "1"
    },
    "14.4.4": {
      "title": "Verify that all responses contain X-Content-Type-Options: nosniff.",
      "level": "1"
    },
    "14.4.5": {
      "title": "Verify that HTTP Strict Transport Security headers are included on all responses and for all subdomains, such as Strict-Transport-Security: max-age=15724800; includeSubdomains.",
      "level": "1"
    },
    "14.4.6": {
      "title": "Verify that a suitable \"Referrer-Policy\" header is included, such as \"no-referrer\" or \"same-origin\".",
      "level": "1"
    },
    "14.4.7": {
      "title": "Verify that a suitable X-Frame-Options or Content-Security-Policy: frame-ancestors header is in use for sites where content should not be embedded in a third-party site.",
      "level": "1"
    },
    "14.5.1": {
      "title": "Verify that the application server only accepts the HTTP methods in use by the application or API, including pre-flight OPTIONS.",
      "level": "1"
    },
    "14.5.2": {
      "title": "Verify that the supplied Origin header is not used for authentication or access control decisions, as the Origin header can easily be changed by an attacker.",
      "level": "1"
    },
    "14.5.3": {
      "title": "Verify that the cross-domain resource sharing (CORS) Access-Control-Allow-Origin header uses a strict white-list of trusted domains to match against and does not support the \"null\" origin.",
      "level": "1"
    },
    "14.5.4": {
      "title": "Verify that HTTP headers added by a trusted proxy or SSO devices, such as a bearer token, are authenticated by the application.",
      "level": "2"
    }
  }
}
