/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.quality-gate-section {
  max-width: 1440px;
}

.quality-gate-section + .quality-gate-section {
  margin-top: 10px;
  padding-top: 20px;
}

.quality-gate-permissions .permission-list-item:hover {
  background-color: #ecf6fe;
}

.quality-gate-section tbody {
  border: 1px solid #ddd;
}

.quality-gate-section tr {
  background-color: white !important;
  border-bottom: 1px solid #ddd;
}

.quality-gate-section td {
  height: 24px;
}

.quality-gate-section tr th {
  font-weight: 400 !important;
  font-size: 11px;
}

.quality-gate-section thead tr {
  background-color: transparent !important;
  border: none;
}

.quality-gate-section thead:after {
  display: none !important;
}

.cayc-success-banner ul {
  list-style: disc;
}

.bordered-bottom-cayc {
  border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.cayc-warning-header {
  color: #6f4f17;
}

.cayc-success-header {
  color: #215821;
}

.cayc-warning-description {
  line-height: 18px;
}

.red-text {
  color: #D02F3A;
}

.green-text {
  color: #008223;
}

.strike-through {
  text-decoration: line-through;
}

#cayc-highlight.active,
[data-guiding-id='caycConditionsSimplification'].active {
  box-shadow: 0px 0px 4px 0px #5d6cd0;
}
