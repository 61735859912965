/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.settings-definitions-list > li + li {
  margin-top: 30px;
}

.settings-definition {
  margin: 0 -20px;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  display: flex;
  align-items: stretch;
}

.settings-definition-left {
  width: 330px;
  padding-right: 30px;
  box-sizing: border-box;
}

.settings-definition-right {
  position: relative;
  width: calc(100% - 330px);
  box-sizing: border-box;
}

.settings-definition-name {
  text-overflow: ellipsis;
}

.settings-sub-categories-list > li + li {
  margin: 30px -20px 0;
  padding: 30px 20px;
  border-top: 1px solid #e6e6e6;
}

.settings-sub-category-name {
  margin-bottom: 20px;
  font-size: 16px;
}

.settings-large-input {
  width: 100% !important;
  max-width: 400px;
  min-width: 200px;
}
