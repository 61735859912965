/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
import { ListItem, UnorderedList } from 'design-system';
import * as React from 'react';
import { translate } from '../../../helpers/l10n';
import { Update } from '../../../types/plugins';
import PluginUpdateItem from './PluginUpdateItem';

export interface PluginUpdatesProps {
  pluginName: string;
  updates?: Update[];
}

export default function PluginUpdates({ pluginName, updates }: Readonly<PluginUpdatesProps>) {
  if (!updates || updates.length <= 0) {
    return null;
  }
  return (
    <ListItem>
      <strong className="sw-typo-semibold">{translate('marketplace.updates')}:</strong>
      <UnorderedList className="sw-mt-2">
        {updates.map((update) =>
          update.release ? (
            <PluginUpdateItem
              key={update.release.version}
              pluginName={pluginName}
              release={update.release}
              update={update}
            />
          ) : null,
        )}
      </UnorderedList>
    </ListItem>
  );
}
