/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
import * as React from 'react';
import PageUnavailableDueToIndexation from '../../app/components/indexation/PageUnavailableDueToIndexation';

export default function withIndexationGuard<P>({
  Component,
  showIndexationMessage,
}: {
  Component: React.ComponentType<React.PropsWithChildren<P>>;
  showIndexationMessage: (props: P) => boolean;
}) {
  return function WithIndexationGuard(props: React.PropsWithChildren<P>) {
    return showIndexationMessage(props) ? (
      <PageUnavailableDueToIndexation />
    ) : (
      <Component {...props} />
    );
  };
}
