/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.a11y-skip-link {
  width: 0;
  height: 0;
  padding: 8px;
  position: absolute;
  left: -9999px;
  top: -9999px;
  border: 0;
  font-size: 1rem;
  text-align: center;
  z-index: 999;
}

.a11y-skip-link:focus {
  width: auto;
  height: auto;
  left: 6px;
  top: 6px;
  color: white;
  background-color: #262626;
  text-decoration: underline;
}
